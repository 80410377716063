import core from 'root/utils/core';
import moment from 'moment';
import commonUtil from '../../../../../../utils/common-util';
import notification from 'root/utils/notification';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { gu_getNestedValue } from 'root/utils/general-util';
import transactionHelper from 'root/utils/transaction-helper';
import { scrollToDepositStep2 } from '../../deposit-util';

const METHOD_OR_CHANNEL_CODE = 'CT';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        let defaultDob = moment(Date.now()).subtract(18, 'years').toDate();
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositAmountOptions: [],

            depositMinMaxLimit: { min: 0, max: 0 },
            selectedCryptoIndex: 0, // in html is refer deposit channel
            selectedChainIndex: disablePreselectBank ? null : 0, // in html is refer banklist
            hoveredIndex: null,
            cryptoQrLink: null,
            isLoading: false,
            compSettings: {},
            // should be can put at parent de variables
            fields: {
                amount: '',
                reference: '',
            },
            cryptoFields: {
                fullname: '',
                email: '',
                telCode: '',
                phone: '',
                dob: defaultDob,
            },
            errors: {},
            disableDepositButton: true,
            isFormValid: false,
            amountFieldNotRequired: [],
            coinAmountDisable: false,
        };
    },
    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'cryptoSettings',
            });
            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            const { filteredDepositApiJson } = controller.vm.state;
            const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

            // Step 3: Initial all into compSettings
            let compSettings = {
                showCustomArticle: tempSettings.showCustomArticle ? tempSettings.showCustomArticle : false,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                customCountdown: tempSettings.customCountdown,
            };

            controller.vm.setState({ compSettings }, () => {
                trans_handlePGDepositCountDown(controller.vm, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: false });

                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }

                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings, chainName, selectedChainMin, depositMinMaxLimit } = controller.vm.state;
        const vm = controller.vm;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'ct' : '';
        vm.setState({ customFilename: customFilename });
        transactionHelper.readCustomDepositArticle(vm, customFilename, (content) => {
            // refer: super-portal-2 src/webapp/portal/settings/deposit/crypto/crypto.controller.js line 353
            const getMinLimit =
                !chainName && depositMinMaxLimit
                    ? window.SPL_Other.formatAmount(depositMinMaxLimit?.min)
                    : `${chainName} ${window.SPL_Other.formatAmount(selectedChainMin || depositMinMaxLimit?.min)}`;
            try {
                let stringified = JSON.stringify(content);
                stringified = stringified.replaceAll('{{minLimit}}', getMinLimit);
                return JSON.parse(stringified);
            } catch (e) {
                return content;
            }
        });
    },

    loadDepositDetails() {
        const { filteredDepositApiJson } = controller.vm.state;

        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            controller.newDepositMethod();
        }
    },

    async newDepositMethod() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { t } = controller.vm.props;

        let _cryptoOptions = [];
        let _cryptoChain = {};
        let count = 1;

        for (let option in filteredDepositApiJson.channel) {
            let optionObj = {};
            optionObj.coin = option;
            optionObj.code = option;
            optionObj.name = '{channel}';
            // optionObj.displayName = window.SPL_Translation.getTranslatedTransactionMsg(option, 'depositChannel');
            optionObj.channelIndex = t('transaction:transaction.deposit.crypto.channelName', 'Channel') + t(' ') + count;
            _cryptoOptions.push(optionObj);
            count++;
        }

        window.SPL_Content.getBankName(_cryptoOptions, 'channel');

        _cryptoOptions.map((option) => {
            if (option.name !== '{channel}') {
                option.channelIndex = option.name;
            }

            return option;
        });

        _cryptoChain = controller.getCryptoChain(_cryptoOptions, controller.vm.state.selectedCryptoIndex);
        // for (const chain of _cryptoChain) {
        //     chain.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${chain.coin}_${chain.network}.svg`, `${chain.coin}_${chain.network}`);
        // }

        controller.vm.setState(
            {
                cryptoOptions: _cryptoOptions,
                cryptoChain: _cryptoChain,
            },
            () => {
                controller.filterCoinAmountCrypto();
            }
        );
    },

    filterCoinAmountCrypto() {
        const { paymentGatewaySetting } = controller.vm.props;
        const { cryptoChain, selectedChainIndex } = controller.vm.state;
        if (gu_getNestedValue(paymentGatewaySetting, 'isCoinAmount')) {
            let coinAmount = gu_getNestedValue(paymentGatewaySetting, 'isCoinAmount');
            let selectedCryptoChain = gu_getNestedValue(cryptoChain, `${selectedChainIndex}.code`);

            const updateCoinAmount = {
                ...controller.vm.state,
                coinAmountDisable: coinAmount.find((isCoin) => isCoin === selectedCryptoChain),
            };
            controller.vm.setState(updateCoinAmount, () => {
                controller.checkChainOptions();
            });
        }
    },

    handleAmountChange(value) {
        const { fields } = controller.vm.state;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.CT]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                const updateAmount = {
                    ...fields,
                    amount: value,
                };
                controller.vm.setState({ fields: updateAmount }, () => {
                    controller.validateDepositAmount();
                });
            }
        } else {
            const updateAmount = {
                ...fields,
                amount: '',
            };
            controller.vm.setState({ fields: updateAmount }, () => {
                controller.validateDepositAmount();
            });
        }
    },

    validateDepositAmount() {
        const { fields, depositMinMaxLimit } = controller.vm.state;
        const depositLimitOBj = { minLimit: depositMinMaxLimit.min, maxLimit: depositMinMaxLimit.max };
        window.SPL_Transaction.validateDepositAmount(fields.amount, depositLimitOBj).then((errMsg) => {
            if (errMsg) {
                let errMsgLimitAmount = controller.getLimitAmountForErrMsg(errMsg[0]);
                const updateErrorAmount = {
                    ...controller.vm.state,
                    amountErrorValue: gu_getNestedValue(errMsgLimitAmount, 'limitAmount'),
                    currency: gu_getNestedValue(errMsgLimitAmount, 'currency'),
                    errors: {
                        amount: errMsg[0],
                    },
                };

                controller.vm.setState(updateErrorAmount, () => {
                    controller.checkError();
                });
            }
        });
    },

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = controller.vm.props.user.account.currency;
        const { depositMinMaxLimit } = controller.vm.state;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    },

    checkError() {
        const { fields, errors } = controller.vm.state;

        const isInvalidAmount = !fields.amount || errors.amount;
        const updateDepositButton = {
            ...controller.vm.state,
            disableDepositButton: isInvalidAmount,
            isFormValid: !isInvalidAmount,
        };

        controller.vm.setState(updateDepositButton);
    },

    submitForm() {
        const { cryptoChain, fields, selectedChainIndex, depositSubmitCountdown } = controller.vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = controller.vm.props;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = gu_getNestedValue(filteredDepositApiJson, 'optionCode');
        const submissionData = gu_getNestedValue(depositSubmission, 'submissionData');
        const paymentObj = gu_getNestedValue(submissionData, `submissionData${paymentCode}`);

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let methodObj = gu_getNestedValue(cryptoChain, `${selectedChainIndex}`);
            let amount = fields.amount;
            let callbackUrl = '/myaccount/deposit';
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let stateUrl = `/${langPath}/quickpayredirect`;
            let methodCode = methodObj.code;
            let methodId = methodObj.id;
            let methodParam = {
                id: methodId,
                code: methodCode,
            };

            const customParam = {
                bank: methodParam.code,
                cryptoAmount: amount,
                returnUrl: null,
                preferredWallet: null,
                merchantBankId: methodParam.id,
                methodCode: methodParam.code,
            };

            window.SPL_Transaction.requestDepositOnlineTransfer(
                methodParam,
                methodParam.code,
                amount,
                null,
                null,
                stateUrl,
                language.key,
                callbackUrl,
                null,
                null,
                customParam
            ).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                }
            });
        }
    },

    getCryptoChain(cryptoOptions, selectedCryptoIndex) {
        const { filteredDepositApiJson } = controller.vm.state;
        let cryptoChain = {};
        if (cryptoOptions.length > 0) {
            cryptoChain = Object.values(filteredDepositApiJson.channel)[selectedCryptoIndex];
        }
        for (let i in cryptoChain) {
            cryptoChain[i].province = cryptoChain[i].coin;
        }
        return cryptoChain;
    },

    getCryptoQrCode(param) {
        window.open(param);
    },

    checkChainOptions() {
        const { cryptoChain, selectedChainIndex } = controller.vm.state;
        const { account } = controller.vm.props.user;

        let userValidate = window.SPL_Member.toShowMemberVerification(account);

        if (userValidate) {
            let showDialog = userValidate.showDialog,
                showEmail = userValidate.showEmail,
                showName = userValidate.showName,
                showPhone = userValidate.showPhone,
                showDob = userValidate.showDob;

            if (showDialog) {
                core.portalIsLoading(controller.vm.props, false);
                controller.getCountryInfo();
                controller.vm.setState({ memberVerifyPopup: showDialog, showEmail: showEmail, showName: showName, showPhone: showPhone, showDob: showDob });
            } else {
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'CT',
                    controller.vm.props.user.account.currency,
                    cryptoChain[selectedChainIndex]?.code,
                    cryptoChain[selectedChainIndex]?.coin,
                    cryptoChain[selectedChainIndex]?.bankType
                );
                controller.getDepositDisplaySetting();
                controller.getQrCode();
            }
        }
    },

    handleCryptoFieldChanged(e, field) {
        const { cryptoFields, errors, telCodeList } = controller.vm.state;

        if (field === 'telCode') {
            if (cryptoFields[field] !== e) {
                cryptoFields[field] = e.target.value;
                for (let i = 0; i < telCodeList.length; i++) {
                    if (`+${telCodeList[i].telCode}` === cryptoFields[field]) {
                        cryptoFields['currency'] = telCodeList[i].currency;
                        controller.vm.setState({ cryptoFields });
                    }
                }
            }
        } else {
            cryptoFields[field] = field === 'dob' ? e : e.target.value;
            controller.vm.setState({ cryptoFields });
        }

        if (field === 'fullname' && typeof cryptoFields['fullname'] !== undefined) {
            let fullname = cryptoFields['fullname'];
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'fullname', errors);
            });
        }

        if (field === 'email' && typeof cryptoFields['email'] !== undefined) {
            let email = cryptoFields['email'];
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }

        if (field === 'phone' && typeof cryptoFields['phone'] !== undefined) {
            let phone = cryptoFields['phone'];
            let telCode = cryptoFields['telCode'];
            window.SPL_Register.validatePhone(phone, window.merchantCode, telCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'phone', errors);
            });
        }

        if (typeof cryptoFields['dob'] !== undefined) {
            let dob = cryptoFields['dob'];
            let errMsg = [];
            if (dob === undefined || dob === null) {
                errMsg.push('global:global.form.dob-placeholder');
            }
            controller.handleInputError(errMsg, 'dob', errors);
        }
    },

    handleInputError(errMsg, field, errors) {
        const { showName, fullname, showPhone, phone, showEmail, email } = controller.vm.state.cryptoFields;
        let memberVerifyDisable = true;

        if (errMsg.length > 0) {
            errors[field] = errMsg[0];
        } else {
            errors[field] = '';
        }

        if ((showName && !fullname) || (showPhone && !phone) || (showEmail && !email) || errors['fullname'] || errors['phone'] || errors['email']) {
            memberVerifyDisable = true;
        } else {
            memberVerifyDisable = false;
        }

        controller.vm.setState({ errors, memberVerifyDisable: memberVerifyDisable });
    },

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            const { language } = controller.vm.props;
            controller.vm.setState({ telCodeList: telCodeList });
            controller.getOptionList(telCodeList, language.country);
        });
    },

    getDepositDisplaySetting() {
        controller.vm.setState({ chainRemark: [] }, () => {
            const { countryLanguageKey } = controller.vm.props.language;
            const { selectedChainIndex, cryptoChain } = controller.vm.state;
            let bankCode = cryptoChain[selectedChainIndex]?.code;

            window.SPL_Transaction.getDepositDisplaySetting().then((data) => {
                if (data && data.remarkNotice && data.remarkNotice[countryLanguageKey] && data.remarkNotice[countryLanguageKey][bankCode]) {
                    controller.vm.setState({ chainRemark: data.remarkNotice[countryLanguageKey][bankCode] });
                }
            });
        });
    },

    async changeCryptoChannel(e) {
        const { screen } = controller.vm.props;
        let fields = controller.vm.state.fields;
        let value = '';
        fields.amount = '';

        if (screen.viewType === 'mobile') {
            value = e;
        } else {
            value = e.target.value;
        }

        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.setState({
            selectedCryptoIndex: value,
            selectedChainIndex: disablePreselectBank ? null : 0,
            fields: fields,
            disableDepositButton: true,
            isFormValid: false,
            cryptoQrCode: '',
            coinAmountDisable: true,
        });

        let _cryptoChain = controller.getCryptoChain(controller.vm.state.cryptoOptions, value);
        // for (const chain of _cryptoChain) {
        //     chain.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${chain.coin}_${chain.network}.svg`, `${chain.coin}_${chain.network}`);
        // }

        controller.vm.setState({ cryptoChain: _cryptoChain }, () => {
            controller.filterCoinAmountCrypto();
        });
    },

    onChainChanged(chainObj) {
        core.portalIsLoading(controller.vm.props, true);

        const { selectedChainIndex, cryptoChain } = controller.vm.state;
        let foundIndex = cryptoChain.indexOf(chainObj);

        if (foundIndex !== selectedChainIndex) {
            controller.vm.setState({ selectedChainIndex: foundIndex }, () => {
                const {
                    enableScrollToDepositStep2 = false,
                    scrollToDepositStep2Attempts = 15,
                    scrollToDepositStep2Delay = 200,
                } = controller.vm.props.portal?.settings?.features?.depositSettings || {};
                scrollToDepositStep2(enableScrollToDepositStep2, selectedChainIndex, foundIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                controller.filterCoinAmountCrypto();
            });
        }
    },

    getPaymentGatewaySetting() {
        return new Promise((resolve) => {
            let amountFieldNotRequired = [];
            window.SPL_Transaction.getPaymentGatewaySetting().then((data) => {
                if (data) {
                    amountFieldNotRequired = data.amountFieldNotRequired;
                    controller.vm.setState({ amountFieldNotRequired }, () => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });
    },

    getQrCode() {
        const { countryLanguageKey } = controller.vm.props.language;
        const { currency } = controller.vm.props.user.account;
        const { selectedChainIndex, cryptoChain, coinAmountDisable, amountFieldNotRequired } = controller.vm.state;
        const selectedChain = cryptoChain[selectedChainIndex];

        controller.resetQrCode();
        core.portalIsLoading(controller.vm.props, true);
        let qrParams = {
            bank: selectedChain?.code,
            methodCode: selectedChain?.code,
            merchantCode: window.merchantCode,
            portalLanguage: countryLanguageKey,
            merchantBankId: selectedChain?.id,
        };

        if (coinAmountDisable) {
            let params = {
                merchantBankId: qrParams.merchantBankId,
                purpose: 'D',
            };
            window.SPL_Transaction.getCcExchangeRate(params, cryptoChain[selectedChainIndex]?.province).then((data) => {
                if (data) {
                    const updateData = {
                        ...controller.vm.state,
                        showSubmitForm: true,
                        CCaccountNumber:
                            cryptoChain[selectedChainIndex]?.bankType === 'CC' && cryptoChain[selectedChainIndex]?.accountNumber
                                ? cryptoChain[selectedChainIndex]?.accountNumber
                                : null,
                        ccMinAmount: data.min,
                        CCExchangeRate: data.exchangeRate,
                        isLoading: false,
                        isSubLoading: false,
                        restoreOnLoad: false,
                    };
                    controller.vm.setState(updateData);
                }
            });
        } else {
            if (selectedChain?.bankType !== 'CC' && amountFieldNotRequired.includes(selectedChain?.code)) {
                controller.vm.setState({ isLoading: true }, () => {
                    window.SPL_Transaction.getDepositQr(qrParams, currency).then((data) => {
                        if (data && data.cryptoDetail) {
                            controller.vm.setState({
                                cryptoQrLink: data.cryptoDetail.link,
                                cryptoQrCode: data.cryptoDetail.address,
                                selectedChainMin:
                                    controller.vm.state.depositMinMaxLimit && controller.vm.state.depositMinMaxLimit.min
                                        ? controller.vm.state.depositMinMaxLimit.min
                                        : data.min,
                                exchangeRate: data.cryptoDetail.exchangeRate,
                                chainBranch: cryptoChain[selectedChainIndex]?.network,
                                chainName: cryptoChain[selectedChainIndex]?.province,
                                isLoading: false,
                            });
                        } else if (data && data.errorMsg) {
                            notification.showNotification('error', data.errorMsg);
                        }
                        core.portalIsLoading(controller.vm.props, false);
                    });
                });
            }
        }
    },

    resetQrCode() {
        controller.vm.setState({
            cryptoQrLink: '',
            cryptoQrCode: '',
            selectedChainMin: '',
            exchangeRate: '',
            chainBranch: '',
            chainName: '',
        });
    },

    qrcode() {
        const { cryptoQrCode } = controller.vm.state;
        return cryptoQrCode;
    },

    getShareInfo() {
        controller.vm.setState({ getShareInfo: true });
    },

    downloadQrCode() {
        const { chainName } = controller.vm.state;

        const qrCodeURL = document.getElementById('cryptoQR').toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = chainName + '.png';
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    },

    submitCryptoForm() {
        const { fields, isFormValid, filteredDepositApiJson, depositSubmitCountdown, cryptoChain, selectedChainIndex } = controller.vm.state;
        const { language, t, depositSubmission, user } = controller.vm.props;
        const { features } = controller.vm.props.portal.settings;

        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];
        const selectedChain = cryptoChain[selectedChainIndex];

        if (isFormValid) {
            if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
                notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
            } else {
                proceedSubmit();
            }
        }

        function proceedSubmit() {
            let methodParam = {
                id: selectedChain?.bankId,
                code: selectedChain?.code,
            };

            // callback URL configuration
            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }
            let callbackUrl = '/myaccount/deposit/crypto';
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let stateUrl = '';
            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
                callbackUrl = `/${langPath}/myaccount/deposit/crypto`;
            }
            // end of callback URL configuration

            const customParam = {
                cryptoAmount: parseInt(fields.amount),
                bank: selectedChain?.code,
                merchantBankId: selectedChain?.id,
            };

            let quickpaySubmitUrl =
                (hideLangPathUrl ? '' : '/' + langPath) +
                `/quickpaysubmit?id=${selectedChain?.id}` +
                `&code=${methodParam.code}` +
                `&bank=${selectedChain?.code}` +
                `&cryptoAmount=${parseInt(fields.amount)}` +
                `&stateUrl=${encodeURIComponent(stateUrl)}` +
                `&callbackUrl=${encodeURIComponent(callbackUrl)}` +
                `&lang=${language.key}` +
                `&customParam=${JSON.stringify(customParam)}`;
            window.open(quickpaySubmitUrl, '_blank');

            trans_handlePGDepositCountDown(controller.vm, true, 60, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
        }
    },
};

export default controller;
