// library
import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { MenuItem } from '../menu-item/menu-item';
import styles from './menu-list.module.scss';

// logic
import { gu_filterCommonKey } from '../../../utils/general-util';

const MenuList = ({ menu, ...props }) => {
    const { menus, menuListDirection, iconPosition, icon, onClickCallback } = props;

    if (menuListDirection) {
        // waiting to implement for vertical / horizontal styling
    }

    const history = useHistory();

    const [menuList, setCurrentMenuList] = useState(null);

    useEffect(() => {
        if (menus?.length > 0) {
            const updatedMenus = menus.map((item) => {
                return { ...item, translation: item?.translation };
            });
            setCurrentMenuList(updatedMenus);
        }
    }, [menus]);

    const onMenuItemClick = (menu) => {
        if (!menu) {
            return;
        }

        // special specific redirect
        if (menu.route === 'switch-mobile') {
            window.SPL_Other.setViewPort('mobile');
            onClickCallback && onClickCallback();
            return;
        } else if (menu.route === 'livechat') {
            openLiveChat();
            onClickCallback && onClickCallback();
            return;
        } else if (menu.url) {
            const url = gu_filterCommonKey(menu.url);
            onClickCallback && onClickCallback();
            window.open(url);
            return;
        } else if (menu.name === 'affiliate') {
            window.open(menu.route);
        } else {
            onClickCallback && onClickCallback();
            history.push(menu.route);
        }

        // normal routing
        // const newCurrentPathName = processMenuSpecialPathName(menu.route);
    };

    const _menuListDirection = menuListDirection || 'vertical';

    return (
        <div className={`${styles[_menuListDirection]}Menus`}>
            {(menuList || []).map((menu) => {
                return (
                    <Fragment key={menu.id}>
                        <MenuItem menu={menu} icon={icon} iconPosition={iconPosition} onClick={onMenuItemClick} />
                    </Fragment>
                );
            })}
        </div>
    );
};

export default MenuList;

export const openLiveChat = async (countryLanguageKey, viewType) => {
    const data = await window.SPL_Content.getContact(countryLanguageKey, viewType);

    const liveChatUrl = (data || []).map((item) => (item.name === 'LiveChat' ? item.url : null)).find((url) => url !== null);
    if (liveChatUrl) {
        window.open(liveChatUrl, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    }
};

export const processMenuSpecialPathName = (pathname) => {
    const _pathname = (pathname || '').toLowerCase();

    const mappings = {
        '/promotions': '/promotion',
        '/esports': '/e-sport',
        '/cmd368': '/sports',
        '/virtualsports-landing': '/sports',
        '/': '/home',
    };

    const updatedPathname = mappings[_pathname] || _pathname;

    return updatedPathname;
};
