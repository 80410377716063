import transactionHelper from 'root/utils/transaction-helper';
import notification from 'root/utils/notification';
import commonUtil from '../../../../../../utils/common-util';
import { gu_togglePopup } from '../../../../../../utils/general-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { scrollToDepositStep2 } from '../../deposit-util';

const METHOD_OR_CHANNEL_CODE = 'BA';
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositMinMaxLimit: { min: 0, max: 0 },
            depositAmountOptions: [],

            bankList: [],
            selectedBankIndex: disablePreselectBank ? null : 0,
            selectedMemberBankIndex: 0,
            selectedChannelIndex: 0,
            hoveredIndex: null,
            depositOptions: [],

            // should be can put at parent de variables
            memberBankList: [],
            showOnlineBankDetails: false,
            receiptImg: [],
            fields: {
                amount: '',
                reference: '',
            },
            bankNameErrMsg: [],
            errors: {},
            addBankDialogIsShow: false,
            disableDepositButton: disablePreselectBank ? true : false,
            isFormValid: false,
            accountBankfields: {
                selectedBankIndex: disablePreselectBank ? null : 0,
                bankName: '',
                bankBranch: '',
                accountName: controller.vm.props.user.account.name,
                accountNumber: '',
            },
            showAmountField: true,

            compSettings: {},
            customTranslationObj: {},
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'bankAppsSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'ba' : '';
        controller.vm.setState({ customFilename: customFilename });
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },

    getMerchantAndMemberBanks() {
        let loadMerchantBankPromise = controller.loadMerchantBank();
        let loadMemberBankPromise = controller.loadMemberBankAccount();

        Promise.all([loadMerchantBankPromise, loadMemberBankPromise]).then(() => {
            transactionHelper.checkCertainBankRequiredReceipt(controller.vm);
            commonUtil.getDepositMinMax(
                controller.vm,
                'BA',
                controller.vm.props.user.account.currency,
                controller.vm.state.bankList[controller.vm.state.selectedBankIndex]?.id
            );
        });
    },

    async loadMerchantBank() {
        const vm = controller.vm;
        const { filteredDepositApiJson } = vm.state;
        const { paymentGatewaySetting } = vm.props;

        // always get first one channel, because bank transfer currently only has 1 channel
        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        if (filteredDepositApiJson && filteredDepositApiJson.channel && Object.values(filteredDepositApiJson.channel) && Object.values(filteredDepositApiJson.channel)[0]) {
            let _bankList = Object.values(filteredDepositApiJson.channel)[0];
            for (let i in _bankList) {
                const bank = _bankList[i];
                bank.accountHolder = bank.accountName;
                bank.bankAppsPhone = bank.phoneNumber;
                // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
            }

            const bank = _bankList[0];
            const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, bank);
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

            vm.setState(
                {
                    bankList: _bankList,
                    showAmountField,
                    isFormValid,
                    disableDepositButton: disablePreselectBank && !_bankList?.length ? false : controller.vm.state.disableDepositButton,
                },
                () => {
                    // Todo: make to promise as required memberbanks and merchantbanks
                    transactionHelper.checkCertainBankRequiredReceipt(controller.vm);
                }
            );
        }
    },

    loadMemberBankAccount() {
        return new Promise(function (resolve) {
            transactionHelper.loadMemberBankAccounts(controller.vm).then(() => {
                resolve();
            });
        });
    },

    onBankChanged(bankObj, onChangeValue) {
        let index;

        if (onChangeValue !== undefined) {
            index = onChangeValue;
        } else {
            index = controller.vm.state.bankList.indexOf(bankObj);
        }

        controller.vm.setState({ disableDepositButton: false }, () => {
            const {
                enableScrollToDepositStep2 = false,
                scrollToDepositStep2Attempts = 15,
                scrollToDepositStep2Delay = 200,
            } = controller.vm.props.portal?.settings?.features?.depositSettings || {};
            scrollToDepositStep2(enableScrollToDepositStep2, controller.vm.state.selectedBankIndex, index, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
            transactionHelper.onBankChanged(controller.vm, bankObj, onChangeValue, 'BA');
        });
    },

    // This should be not using
    loadBankTransferDropdownOption() {
        const { t } = controller.vm.props;
        let depositOptions = [
            { value: 'O', label: t('settings:settings.dropdown.onlineBanking') },
            { value: 'D', label: t('settings:settings.dropdown.cashDepositMachine') },
            { value: 'A', label: t('settings:settings.dropdown.atmTransfer') },
        ];

        if (controller.vm.state.depositAllSettings.showOnlineBankingOnly) {
            depositOptions = depositOptions.filter((a) => a.value === 'O');
        } else {
            if (controller.vm.state.depositAllSettings.hideATM) {
                depositOptions = depositOptions.filter((a) => a.value !== 'A');
            }
            if (controller.vm.state.depositAllSettings.hideCashDeposit) {
                depositOptions = depositOptions.filter((a) => a.value !== 'D');
            }
        }

        controller.vm.setState({ depositOptions: depositOptions }, () => {
            controller.hideshowOnlineBank();
        });
    },

    // can make combine
    hideshowOnlineBank() {
        if (controller.vm.state.depositAllSettings.allowOnlineBankDetails) {
            let channel = controller.vm.state.depositOptions[controller.vm.state.selectedChannelIndex];
            if (channel.value === 'O' || channel.value === 'A') {
                controller.vm.setState({ showOnlineBankDetails: true });
            } else {
                controller.vm.setState({ showOnlineBankDetails: false, receiptRequired: false });
            }
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { depositAllSettings } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };

        if (depositAllSettings && depositAllSettings.depositJson && depositAllSettings.depositJson.onlinetransfer) {
            _depositMinMaxLimit = depositAllSettings.depositJson.onlinetransfer[currency];
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    // can make combined
    changeChannel(e) {
        controller.vm.setState({ selectedChannelIndex: e.target.value }, () => {
            controller.hideshowOnlineBank();
            transactionHelper.handleReceiptValidation(controller.vm);
        });
    },

    submitForm() {
        const { isFormValid, disableDepositButton } = controller.vm.state;

        if (isFormValid && !disableDepositButton) {
            // validate form again before proceed

            const isReceiptValid = transactionHelper.handleReceiptValidation(controller.vm);
            if (isReceiptValid) {
                controller.submitBankTransferDeposit();
            }
        }
    },

    submitBankTransferDeposit() {
        controller.vm.setState({ disableDepositButton: true });
        const { selectedBankIndex, selectedMemberBankIndex } = controller.vm.state;
        const { receiptImgFile, bankList, memberBankList } = controller.vm.state;
        const { fields } = controller.vm.state;

        let merchantBankObj = bankList[selectedBankIndex];
        let merchantBankId = merchantBankObj.id;
        let memberBankDetails = null;

        if (memberBankList[selectedMemberBankIndex] && memberBankList[selectedMemberBankIndex].memberBankId) {
            memberBankDetails = memberBankList[selectedMemberBankIndex].memberBankId;
        }

        let picFile = receiptImgFile;

        window.SPL_Transaction.localPaySubmit(null, null, merchantBankId, fields.amount, METHOD_OR_CHANNEL_CODE, null, memberBankDetails, picFile, fields.reference).then(
            (data) => {
                if (data.status === 'S') {
                    transactionHelper.resetForm(controller.vm);
                    controller.vm.setState({ disableDepositButton: false });
                    notification.showNotification('success', controller.vm.props.t('transaction:transaction.deposit.request-success'));
                } else {
                    let errorMsg = '';
                    if (data.code === 'receipt_fail') {
                        errorMsg = 'transaction:transaction.withdrawal.request-uploadReceiptError';
                    } else if (data.code === 'fail_deposit' || data.msg.data.status === 409 || data.msg.data.status === 403 || data.msg.data.status === 500) {
                        errorMsg = 'transaction:transaction.withdrawal.request-pending';
                    } else {
                        errorMsg = 'transaction:transaction.withdrawal.request-error';
                    }
                    controller.vm.setState({ disableDepositButton: false });
                    notification.showNotification('error', controller.vm.props.t(errorMsg));
                }
            }
        );
    },

    handleBankChange(e) {
        let accountBankfields = this.vm.state.accountBankfields;
        let showBankName = false;
        let bankObj = this.vm.state.unOwnedBankList[e.target.value];
        if (bankObj.bankId === 0 || bankObj.bankId === '0' || bankObj.bankId === 'o') {
            showBankName = true;
        }

        accountBankfields.selectedBankIndex = e.target.value;

        this.vm.setState({ showBankName: showBankName });
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.BA]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    togglePopup(component) {
        gu_togglePopup(controller.vm, component);
    },

    addBankSuccess() {
        controller.togglePopup('add-bank');
        transactionHelper.loadMemberBankAccounts(controller.vm);
    },
};

export default controller;
