// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import AddBankDialog from '../../../../../add-bank-dialog/component/add-bank-dialog_96c';
import QRCodeDialog from 'root/webapp/portal/settings/qrcode-dialog/qrcode-dialog.selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';

// controller/logic
import vm from '../../region-qr.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { popupAction } from '../../../../../../../../redux/action';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import isEmpty from 'lodash/isEmpty';

class RegionQR extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        if (this.state.depositAllSettings !== null) {
            vm.initCompSettings();

            const _loadMerchantBank = () => {
                return (callback) => {
                    vm.loadMerchantBank(callback);
                };
            };

            const _loadMemberBankAccount = () => {
                return (callback) => {
                    vm.loadMemberBankAccount(callback);
                };
            };

            // sequencial
            vm.setIsLoading(true);
            window.SPL_Other.spawnCallback(
                _loadMerchantBank,
                _loadMemberBankAccount
            )(() => {
                transactionHelper.handleValidation(this, 'RQR');
                vm.setIsLoading(false);
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.RQR));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.RQR) });
        }
    }

    render() {
        const {
            isLoading,
            compSettings,
            selectedMerchantBank,
            bankList,
            depositAmountOptions,
            depositMinMaxLimit,
            depositAllSettings,
            isFormValid,
            disableDepositButton,
            fields,
            addBankDialogIsShow,
            errors,
            amountErrorValue,
            memberBankList,
            receiptImg,
            receiptRequired,
            showAmountField,
            popupCollection,
            customTranslationObj,
            qrCodeDialogIsShow,
            selectedQrCodeImg,
        } = this.state;
        const { depositPagesSettings } = compSettings;
        const accountHolder = selectedMerchantBank && selectedMerchantBank.accountHolder;
        const accountNumber = selectedMerchantBank && selectedMerchantBank.accountNumber;
        const selectedMerchantBankImage = (selectedMerchantBank && selectedMerchantBank.image) || '/public/html/images/download/qrcode.webp';
        const { user, portal } = this.props;
        const { openQrCodeDialog } = vm;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.regionQR.regionQR', 'Region QR'),
            customTotalAllowTitle: this.props.t('transaction:transaction.deposit.cashdeposit.totalallowed2', 'Total Allow'),
            customMinMaxTitle: this.props.t('transaction:transaction.deposit.cashdeposit.min/maxlimit2', 'Min / Max'),
        };

        let addBankDialogProps = {
            addBankSuccess: vm.addBankSuccess,
            unOwnedBankList: this.state.unOwnedBankList,
        };

        let qrCodeDialogProps = {
            closeQrCodeDialog: vm.closeQrCodeDialog,
            qrCodeDialogIsShow,
            qrCodeImg: selectedQrCodeImg,
        };

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return isLoading || depositAllSettings === null || depositMinMaxLimit === null ? (
            <SmallLoading />
        ) : (
            <>
                {depositAllSettings && depositPagesSettings && (
                    <Translation>
                        {(t) => (
                            <Fragment>
                                {(addBankDialogIsShow || (popupCollection && popupCollection['add-bank'])) && (
                                    <AddBankDialog
                                        {...addBankDialogProps}
                                        closeFunction={() => {
                                            vm.togglePopup('add-bank');
                                            this.props.dispatch(popupAction.setPopupVisible(false));
                                        }}
                                    />
                                )}
                                {qrCodeDialogIsShow && <QRCodeDialog {...qrCodeDialogProps}></QRCodeDialog>}

                                {/* Bank QR CODE */}
                                {selectedMerchantBankImage && (
                                    <div className='bank-qrcode'>
                                        <div className='frame'>
                                            <LazyLoadImage
                                                src={selectedMerchantBankImage}
                                                alt='bank-qrcode'
                                                onClick={() => openQrCodeDialog(selectedMerchantBankImage)}
                                            ></LazyLoadImage>

                                            <div className='top-mask'></div>
                                            <div className='right-mask'></div>
                                            <div className='bottom-mask'></div>
                                            <div className='left-mask'></div>
                                        </div>
                                        <div className='bank-account-holder'>{accountHolder}</div>
                                    </div>
                                )}

                                {/* SELECTED BANK HOLDER AND BANK NUMBER */}
                                {bankList.length > 0 && (
                                    <div className='group-item bank-holder-details'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='bank-details'>
                                            <input id='backaccount' type='text' autoComplete='off' disabled={true} readOnly={true} value={accountHolder} />
                                            <SVGElements className='copy' name='copy' onClick={() => core.copy(t, accountHolder)} />
                                        </div>
                                        <div className='bank-details'>
                                            <input id='backaccount' type='text' autoComplete='off' disabled={true} readOnly={true} value={accountNumber} />
                                            <SVGElements className='copy' name='copy' onClick={() => core.copy(t, accountNumber)} />
                                        </div>
                                    </div>
                                )}

                                {/* DEPOSIT AMOUNT */}
                                {showAmountField && (
                                    <div className='group-item input-item'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='amount-content'>
                                            <div className='input-box'>
                                                <div className='deposit-input-box'>
                                                    <input
                                                        id='depositamount'
                                                        type='numeric'
                                                        autoComplete='off'
                                                        placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                            min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                            max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                        })}
                                                        min={'0'}
                                                        value={fields['amount']}
                                                        onChange={(e) => vm.handleAmountChange(e)}
                                                        onBlur={() => {
                                                            transactionHelper.handleValidation(this, 'RQR');
                                                        }}
                                                    />
                                                    {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                        <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                            <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='error-validation'>
                                                    <Trans
                                                        i18nKey={errors['amount']}
                                                        values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                                    ></Trans>
                                                </div>
                                            </div>
                                            <ul>
                                                {depositAmountOptions.map((item) => (
                                                    <li
                                                        key={item}
                                                        onClick={() => {
                                                            transactionHelper.onDepositAmountClicked(this, item);
                                                        }}
                                                    >
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                {/* SENDER BANK */}
                                <div className='group-item'>
                                    <MobileSelect
                                        itemList={memberBankList}
                                        itemKey={'displayName'}
                                        onChange={(e) => vm.handleBankDetailChange(e)}
                                        itemIndex={this.state.selectedMemberBankIndex}
                                        title={
                                            customTranslationObj && customTranslationObj.bank
                                                ? t(customTranslationObj.bank)
                                                : t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')
                                        }
                                        required={true}
                                        enableInfoIcon={true}
                                        onImportantNoticeIconClick={() => vm.onImportantNoticeIconClick()}
                                        isShowNoticeBox={this.state.isShowNoticeBox}
                                        depositBankDetailInfo={t(
                                            'transaction:transaction.deposit.bank-select-notify',
                                            'Add or select a bank account you wish to transfer the money from.'
                                        )}
                                    />

                                    {isEmpty(memberBankList) && <div className='error-validation'>
                                        <Trans i18nKey={errors['memberBankList']}></Trans>
                                    </div>}

                                    {!depositAllSettings.hideAddBankAccount && (
                                        <button
                                            className='addBankButton'
                                            onClick={() => {
                                                vm.togglePopup('add-bank');
                                                this.props.dispatch(popupAction.setPopupVisible(true));
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                        </button>
                                    )}
                                </div>

                                {/* RECEIPT CONTAINER */}
                                {receiptImg.length > 0 && (
                                    <div className='group-item'>
                                        <div className='title'></div>
                                        <div className='amount-content'>
                                            <div className='input-box receipt-image-box'>
                                                <LazyLoadImage src={receiptImg} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='group-item submit-container'>
                                    <div className='title'></div>
                                    <div className='amount-content'>
                                        <div className='flexbox m-standard-2-button-container'>
                                            <button
                                                className='btn btn-submit standard-button'
                                                onClick={() => {
                                                    vm.submitForm();
                                                }}
                                                disabled={!isFormValid || disableDepositButton || receiptRequired}
                                            >
                                                {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                            </button>
                                            {depositAllSettings.uploadReceipt && (
                                                <button
                                                    className='btn btn-secondary standard-button'
                                                    onClick={() => {
                                                        transactionHelper.handleUploadReceiptButtonOnClick();
                                                    }}
                                                >
                                                    {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                                    <input
                                                        id='hiddenFileInput'
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        accept='image/*'
                                                        onChange={(e) => vm.handleReceiptImageChange(e)}
                                                    ></input>
                                                </button>
                                            )}
                                        </div>
                                        {receiptRequired && <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>}
                                    </div>
                                </div>

                                <TransactionNote {...transactionNoteProps} />
                            </Fragment>
                        )}
                    </Translation>
                )}
            </>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(RegionQR)));
