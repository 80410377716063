import notification from 'root/utils/notification';
import core from 'root/utils/core';
import APIClient from '../../../../utils/APIClient';
import commonUtil from '../../../../utils/common-util';
import { ou_otpErrorHandler } from '@utils/otp-util';
import { trans_massageHideAddBank, trans_handleBankStatementClearReceipt, trans_handleBankStatementReceiptImageChange } from 'root/utils/transaction-util';
import { MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { popupAction } from '@redux/action/popup.action';
import { portalAction, authSettingsAction } from '@redux/action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            bankList: [],
            memberBankList: [],
            unOwnedBankList: [],
            depositAllSettings: null,

            addBankDialogIsShow: false,
            deleteBankDialogIsShow: false,
            deleteEwalletDialogIsShow: false,
            selectedBankObj: null,

            showBankName: false,
            bankNameErrMsg: [],
            statusMsg: '',
            selectedTab: 0,
            isAddBank: false,
            fields: {
                selectedBankIndex: 0,
                bankName: '',
                bankBranch: '',
                accountName: this.vm.props.user.account.name,
                accountNumber: '',
            },
            errors: {},
            method: 'bank',
            cryptoAddress: [],
            addressData: '',
            addressOption: [],
            selectedCurrency: '',
            networkOption: [{ displayName: 'transaction:transaction.deposit.crypto.selectNetwork' }],
            selectedNetwork: '',
            address: '',
            otpCode: '',
            cryptoError: '',
            cryptoAddressDisable: true,
            disableOtpBtn: true,
            submittedOTP: false,
            countdownTimer: 0,
            isAddCoin: false,
            selectedCoinIndex: 0,
            selectedNetworkIndex: 0,
            networkAndCoin: {},
            ewalletEnabled: false,
            ewalletList: [],
            ewalletBank: [],
            unOwnedEwalletBank: [],
            receiptImg: [],
            compSettings: {},
            preferContact: null,
            attemptCount: 0,
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            const { user } = controller.vm.props;
            const { currency } = user.account;

            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'withdrawalSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let reqBankStatementByCurrencies = false;
            if (tempSettings.reqBankStatementByCurrencies) {
                reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
            }

            // Step 3: Initial all into compSettings
            let compSettings = {
                reqBankStatementByCurrencies,
                seperateTngToEwallet: tempSettings && tempSettings.seperateTngToEwallet,
            };

            controller.vm.setState({ compSettings }, () => {
                window.SPL_Content.getBankNameJson(controller.vm.props);

                resolve();
            });
        });
    },

    loadBankAccounts() {
        core.portalIsLoading(controller.vm.props, true);
        let currency = controller.vm.props.user.account.currency;
        const { ewalletList, ewalletEnabled } = controller.vm.state;
        window.SPL_Transaction.loadDepositRelatedSettings(currency).then((depositSettings) => {
            window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then(async (data) => {
                const { memberBankList, unOwnedBankList } = data;
                for (let i = 0; i < data.memberBankList.length; i++) {
                    const memberbank = data.memberBankList[i];
                    for (let j = 0; j < data.bankList.length; j++) {
                        const bank = data.bankList[j];
                        if (bank.bankCode === memberbank.bankCode) {
                            memberbank.bankNameOnly = bank.bankNameOnly;
                        } else if (memberbank.bankId === null) {
                            memberbank.bankNameOnly = memberbank.bankName;
                        }
                    }
                    if (memberbank.bankId === null) {
                        memberbank.bankCode = 'other';
                    }

                    //only tng is image
                    if (memberbank.code === 'TNG') {
                        memberbank.isSvgImage = true;
                    }

                    // NOTE: if there's like hunred of banks, it's spamming html request... texture packing required
                    // memberbank.svgData = await window.SPL_Other.renderTemplateSVG(
                    //     `${commonUtil.getDepositImgPath('bank-icon')}${memberbank.bankCode}.svg`,
                    //     `${memberbank.bankCode}`
                    // );
                }

                // NOTE: fix showBankName issue
                let fields = this.vm.state.fields;

                let showBankName = false;
                let bankObj = data.unOwnedBankList[0];
                if (bankObj) {
                    if (bankObj.bankId === 0 || bankObj.bankId === '0' || bankObj.bankId === 'o') {
                        showBankName = true;
                        fields.bankName = '';
                    } else {
                        fields.bankName = bankObj.bankName;
                    }
                    fields.selectedBankIndex = 0;
                }

                //filter tng in bank details
                const eWalletsBankCodes = (ewalletList || []).map((bank) => bank.code);
                const eWalletBank = ewalletEnabled && ewalletList?.length > 0 ? memberBankList.filter((data) => eWalletsBankCodes.includes(data.code)) : [];
                const unOwnedEwalletBank = ewalletEnabled && ewalletList?.length > 0 ? unOwnedBankList.filter((data) => eWalletsBankCodes.includes(data.code)) : [];
                const processedMemberBankList =
                    ewalletEnabled && ewalletList?.length > 0 ? memberBankList.filter((data) => !eWalletsBankCodes.includes(data.code)) : memberBankList;
                const processedUnOwnedBankList =
                    ewalletEnabled && ewalletList?.length > 0 ? unOwnedBankList.filter((data) => !eWalletsBankCodes.includes(data.code)) : unOwnedBankList;
                // init hideAddBankAccount by 1st tab code
                depositSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'withdraw', 'B');

                controller.vm.setState(
                    {
                        depositAllSettings: depositSettings,
                        bankList: data.bankList,
                        memberBankList: processedMemberBankList,
                        unOwnedBankList: processedUnOwnedBankList,
                        ewalletBank: eWalletBank,
                        unOwnedEwalletBank: unOwnedEwalletBank,
                        showBankName,
                        bankNameErrMsg: [],
                        fields,
                    },
                    core.portalIsLoading(controller.vm.props, false)
                );
            });
        });
    },

    openAddBankDialog() {
        controller.vm.setState({ addBankDialogIsShow: true });
    },

    closeAddBankDialog(vm, flag) {
        vm.setState({ addBankDialogIsShow: false }, () => {
            if (flag) {
                controller.loadBankAccounts();
            }
        });
    },

    openDeleteBankDialog(bankObj, method) {
        let { deleteEwalletDialogIsShow, deleteBankDialogIsShow } = controller.vm.state;
        if (method === 'ewallet') {
            deleteEwalletDialogIsShow = true;
        } else {
            deleteBankDialogIsShow = true;
        }
        controller.vm.setState({
            deleteBankDialogIsShow: deleteBankDialogIsShow,
            selectedBankObj: bankObj,
            method: method,
            deleteEwalletDialogIsShow: deleteEwalletDialogIsShow,
        });
    },

    closeDeleteBankDialog(vm, flag) {
        vm.setState({ deleteBankDialogIsShow: false, deleteEwalletDialogIsShow: false, selectedBankObj: null }, () => {
            if (flag) {
                let selectedTab = controller.vm.state.selectedTab;

                if (vm.state.method === 'bank' || vm.state.method === 'ewallet') {
                    if (selectedTab === controller.vm.state.memberBankList.length - 1) {
                        selectedTab = selectedTab - 1;
                        if (selectedTab >= 0) {
                            controller.vm.setState({ selectedTab: selectedTab });
                        }
                    }

                    controller.loadBankAccounts();
                } else if (vm.state.method === 'crypto') {
                    if (selectedTab === controller.vm.state.cryptoAddress.length - 1) {
                        selectedTab = selectedTab - 1;
                        if (selectedTab >= 0) {
                            controller.vm.setState({ selectedTab: selectedTab });
                        }
                    }

                    controller.getCryptoMemberFullAddress();
                }
            }

            if (vm && vm.myRef) {
                vm.myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        });
    },

    isNumber(val) {
        return /^\d*$/.test(val);
    },

    handleBankChange(e) {
        let fields = this.vm.state.fields;
        if (fields.selectedBankIndex === e.target.value) {
            return;
        }

        let showBankName = false;
        let bankObj = this.vm.state.unOwnedBankList[e.target.value];
        if (bankObj.bankId === 0 || bankObj.bankId === '0' || bankObj.bankId === 'o') {
            showBankName = true;
            fields.bankName = '';
        } else {
            fields.bankName = bankObj.bankName;
        }

        fields.selectedBankIndex = e.target.value;

        this.vm.setState({ showBankName: showBankName, bankNameErrMsg: [], fields });
    },

    handleFieldChange(e, field) {
        let fields = this.vm.state.fields;
        let val = e.target.value;
        fields[field] = e.target.value;
        this.vm.setState({ fields }, () => {
            if (field === 'bankName') {
                this.validateBankName();
            }
            if (field === 'accountNumber') {
                if (this.isNumber(val)) {
                    this.vm.setState({ errors: { accountNumber: '' } });
                } else {
                    this.vm.setState({ errors: { accountNumber: 'transaction:transaction.withdrawal.accountNumber-error' } });
                }
            }
        });
    },

    validateBankName() {
        window.SPL_Transaction.validateBankName(this.vm.state.fields.bankName).then((errMsg) => {
            this.vm.setState({ bankNameErrMsg: errMsg });
        });
    },

    isValidForm() {
        const { bankNameErrMsg, errors, showBankName } = this.vm.state;
        const { bankName, accountNumber } = this.vm.state.fields;

        if (showBankName && (bankName === null || bankName === '')) {
            return false;
        } else if (accountNumber === null || accountNumber === '') {
            return false;
        } else {
            return !(bankNameErrMsg.length === 0 && errors.accountNumber && errors.accountNumber.length > 0);
        }
    },

    handleSubmit(vm) {
        const { authSettingsReducer, popupReducer, dispatch } = controller.vm.props;

        const _bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
        const _config = {
            gaEnabled: _bankDetailBankAuthSettings?.gaEnabled,
            sixPinEnabled: _bankDetailBankAuthSettings?.sixPinEnabled,
        };

        // if enable Pin, but haven't got enter pin number for GA yet, then will trigger pin popup
        if (_bankDetailBankAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
            core.portalIsLoading(controller.vm.props, false);
            dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
            dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
            dispatch(popupAction.setSubmitSuccessCb(controller.submitAddBankDetail.bind(this, vm)));
        } else {
            controller.submitAddBankDetail(vm);
        }
    },

    submitAddBankDetail(vm) {
        const { popupReducer, dispatch, authSettingsReducer } = controller.vm.props;

        let fields = this.vm.state.fields;
        let receiptImgFile = this.vm.state.receiptImgFile;
        let selectedBankIndex = fields['selectedBankIndex'];
        let accountName = fields['accountName'];
        let accountNumber = fields['accountNumber'];
        let bankName = fields['bankName'];
        let bankBranch = fields['bankBranch'];
        this.vm.setState({ statusMsg: '' });
        let bankObj = this.vm.state.unOwnedBankList[selectedBankIndex];
        dispatch(portalAction.setIsSubmitting(true));
        const _bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];

        const sixPinAuth = {
            enteredPin: _bankDetailBankAuthSettings?.sixPinEnabled ? popupReducer?.enteredPin : false,
            sixPin: _bankDetailBankAuthSettings?.sixPinEnabled ? OVT.SIX_PIN : false,
        };

        window.SPL_Member.addBankAcc(accountName, accountNumber, bankBranch, bankObj.bankId, bankName, receiptImgFile, sixPinAuth.enteredPin, sixPinAuth.sixPin).then(
            (data) => {
                if (data.status === 'S') {
                    controller.clearInputField();
                    controller.loadBankAccounts();
                    controller.handleMobileAddBank(false, vm);
                    // this.vm.props.closeAddBankDialog(this.vm.props.parentvm, true);
                    dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, false));
                    dispatch(popupAction.setEnteredPin(''));
                } else {
                    if (data.errorCode === 2717) {
                        dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                    }

                    if (data.detail.toLowerCase() === 'bank account already in use') {
                        notification.showNotification('error', controller.vm.props.t('global:global.verification.duplicateBankAcc', 'Bank account is already in use'));
                    } else {
                        notification.showNotification('error', controller.vm.props.t(data?.errMsg || data?.detail));
                    }
                }
                dispatch(portalAction.setIsSubmitting(false));
            }
        );
    },

    handleEwalletSubmit(vm) {
        const { authSettingsReducer, popupReducer, dispatch } = controller.vm.props;

        const _bankDetailEwalletAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_EWALLET];
        const _config = {
            gaEnabled: _bankDetailEwalletAuthSettings?.gaEnabled,
            sixPinEnabled: _bankDetailEwalletAuthSettings?.sixPinEnabled,
        };

        // if enable Pin, but haven't got enter pin number for GA yet, then will trigger pin popup
        if (_bankDetailEwalletAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
            core.portalIsLoading(controller.vm.props, false);
            dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
            dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
            dispatch(popupAction.setSubmitSuccessCb(controller.submitAddEwalletDetail.bind(this, vm)));
        } else {
            controller.submitAddEwalletDetail(vm);
        }
    },

    submitAddEwalletDetail(vm) {
        const { popupReducer, dispatch, authSettingsReducer } = controller.vm.props;
        let fields = this.vm.state.fields;
        let selectedBankIndex = fields['selectedBankIndex'];
        let accountName = fields['accountName'];
        let accountNumber = fields['accountNumber'];
        let bankName = fields['bankName'];
        let bankBranch = fields['bankBranch'];
        this.vm.setState({ statusMsg: '' });
        let bankObj = this.vm.state.unOwnedEwalletBank[selectedBankIndex];

        dispatch(portalAction.setIsSubmitting(true));
        const _bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_EWALLET];

        const sixPinAuth = {
            enteredPin: _bankDetailBankAuthSettings?.sixPinEnabled ? popupReducer?.enteredPin : false,
            sixPin: _bankDetailBankAuthSettings?.sixPinEnabled ? OVT.SIX_PIN : false,
        };

        window.SPL_Member.addBankAcc(
            accountName,
            accountNumber,
            bankBranch,
            bankObj.bankId,
            bankName,
            null,
            sixPinAuth.enteredPin,
            sixPinAuth.sixPin
        ).then((data) => {
            if (data.status === 'S') {
                controller.clearInputField();
                controller.loadBankAccounts();
                controller.handleMobileAddEwallet(false, vm);
                dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, false));
                dispatch(popupAction.setEnteredPin(''));
            } else {
                if (data.errorCode === 2717) {
                    dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                }

                if (data.detail.toLowerCase() === 'bank account already in use') {
                    notification.showNotification('error', controller.vm.props.t('global:global.verification.duplicateBankAcc', 'Bank account is already in use'));
                } else {
                    notification.showNotification('error', controller.vm.props.t(data?.errMsg || data.detail));
                }
            }
            dispatch(portalAction.setIsSubmitting(false));
        });
    },

    clearInputField() {
        this.vm.setState({
            fields: {
                selectedBankIndex: 0,
                bankName: '',
                bankBranch: '',
                accountName: this.vm.props.user.account.name,
                accountNumber: '',
            },
            receiptImg: [],
        });
    },
    toogleTab(tab) {
        if (tab !== controller.vm.state.selectedTab) {
            controller.vm.setState({ selectedTab: tab });
        }
    },

    handleMobileAddBank(openClose, vm) {
        controller.vm.setState({ isAddBank: openClose }, () => {
            if (vm && vm.myRef) {
                vm.myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        });
    },

    onMethodChange(method, optionCode) {
        let depositAllSettings = controller.vm.state.depositAllSettings;

        if (depositAllSettings) {
            depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'withdraw', optionCode);
        }

        if (method === 'crypto') {
            controller.getCryptoMemberFullAddress();
            controller.loadCryptoDisplaySettings();
        }

        controller.vm.setState({ method: method, selectedTab: 0, depositAllSettings }, () => {
            controller.handleSetPreferContact();
        });
    },

    loadEwallet() {
        return new Promise((resolve) => {
            const { compSettings } = controller.vm.state;
            if (compSettings && compSettings.seperateTngToEwallet) {
                window.SPL_Transaction.getWithdrawalBankList().then((data) => {
                    if (data && data.R && data.R.R.length > 0) {
                        controller.vm.setState({ ewalletEnabled: true, ewalletList: data.R.R });
                        resolve();
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    },

    getCryptoMemberFullAddress() {
        core.portalIsLoading(controller.vm.props, true);

        window.SPL_Transaction.getMemberCryptoAddress().then(async (data) => {
            if (data && data.orginalAddressList) {
                // const cloneCryptoAddress = [...data.orginalAddressList];
                // // for (const address of cloneCryptoAddress) {
                // //     address.svgData = await window.SPL_Other.renderTemplateSVG(
                // //         `${commonUtil.getDepositImgPath()}${address.coin}_${address.network}.svg`,
                // //         `${address.coin}_${address.network}`
                // //     );
                // // }
                controller.vm.setState({
                    cryptoAddress: data.orginalAddressList,
                });
            }
        });

        controller.loadCryptoWithdraw();
    },

    loadCryptoDisplaySettings() {
        const { countryLanguageKey } = controller.vm.props.language;

        window.SPL_Transaction.loadCryptoDisplaySettings(countryLanguageKey).then((data) => {
            if (data) {
                if (data.networkAndCoin) {
                    controller.vm.setState({ networkAndCoin: data.networkAndCoin });
                }
            }
        });
    },

    loadCryptoWithdraw() {
        window.SPL_Transaction.getCryptWithdrawBank().then((data) => {
            if (data) {
                let addressData = data.coinAndSupportNetwork,
                    addressOption = [{ displayName: 'transaction:transaction.deposit.crypto.selectCryptoCurrency' }];

                if (addressData) {
                    for (let i = 0; i < Object.keys(addressData).length; i++) {
                        addressOption.push({
                            displayName: Object.keys(addressData)[i],
                        });
                    }

                    controller.vm.setState(
                        {
                            addressData: addressData,
                            addressOption: addressOption,
                        },
                        () => {
                            core.portalIsLoading(controller.vm.props, false);
                        }
                    );
                } else {
                    core.portalIsLoading(controller.vm.props, false);
                }
            } else {
                core.portalIsLoading(controller.vm.props, false);
            }
        });
    },

    handleCryptoCurrencyChange(e) {
        let value = e.target.customValue ? e.target.customValue : e.target.value;

        controller.vm.setState({ selectedCurrency: value, selectedNetwork: '', selectedCoinIndex: e.target.value }, () => {
            controller.filterAddressData();
            controller.checkAddressBtnDisable();
            controller.checkOtpBtnDisable();
        });
    },

    filterAddressData() {
        const { networkAndCoin, selectedCurrency, addressData } = controller.vm.state;
        let networkFilter = '',
            networkOption = [{ displayName: 'transaction:transaction.deposit.crypto.selectNetwork' }],
            addressFilter = [];

        if (selectedCurrency) {
            networkFilter = networkAndCoin && networkAndCoin[selectedCurrency] ? networkAndCoin[selectedCurrency] : [];
            addressFilter = addressData[selectedCurrency];
            if (networkFilter && networkFilter.length > 0) {
                for (let j = 0; j < addressFilter.length; j++) {
                    for (let i = 0; i < networkFilter.length; i++) {
                        if (addressFilter[j].indexOf(networkFilter[i]) !== -1) {
                            networkOption.push({ displayName: networkFilter[i] });
                        }
                    }
                }
            }
        }

        controller.vm.setState({ networkOption: networkOption });
    },

    handleCryptoNetworkChange(e) {
        let value = e.target.customValue ? e.target.customValue : e.target.value;

        controller.vm.setState({ selectedNetwork: value, selectedNetworkIndex: e.target.value }, () => {
            controller.checkAddressBtnDisable();
            controller.checkOtpBtnDisable();
        });
    },

    handleCryptoAddressChange(e) {
        if (e.target.value.match(/^([^`~!@#$%^&*()\-_=+[{\]}\\\|;:',<.>\/? ]*)$/)) {
            controller.vm.setState({ address: e.target.value }, () => {
                controller.checkAddressBtnDisable();
                controller.checkOtpBtnDisable();
            });
        }
    },

    handleOtpChange(e) {
        controller.vm.setState({ otpCode: e.target.value }, () => {
            controller.checkAddressBtnDisable();
        });
    },

    validateOtp() {
        const { otpCode, address } = controller.vm.state;
        core.portalIsLoading(controller.vm.props, true);

        window.SPL_Member.validateOtp(null, otpCode, address)
            .then((result) => {
                if (result) {
                    controller.checkAddressBtnDisable(result);
                    controller.vm.setState({
                        cryptoError: '',
                    });
                } else {
                    controller.vm.setState({
                        cryptoError: 'global:global.verification.codeIncorrect',
                        cryptoAddressDisable: true,
                    });
                }

                core.portalIsLoading(controller.vm.props, false);
            })
            .catch(() => {
                controller.vm.setState({
                    cryptoError: 'global:global.verification.generalError',
                });

                core.portalIsLoading(controller.vm.props, false);
            });
    },

    async sendOtpCode() {
        const { method, preferContact } = controller.vm.state;
        let bankType = method === 'bank' ? MT.BANK_DETAIL_BANK : method === 'crypto' ? MT.CRYPTO_ADDRESS : method === 'ewallet' ? MT.BANK_DETAIL_EWALLET : null;

        // Check if the OTP button is not disabled
        if (!controller.vm.state.disableOtpBtn) {
            const _otpApiParam = {
                contactType: 'CRYPTO',
                cryptoAddress: controller.vm.state.address,
                verificationMethod: preferContact?.toUpperCase(),
            };
            const splParams = {
                otpOptions: { otpType: bankType, isPublic: false },
                data: _otpApiParam,
            };

            try {
                // Make the API call to request OTP
                const res = await window.SPL_Member.postRequestOTP(splParams);

                // Update state on successful OTP request
                controller.vm.setState(
                    {
                        submittedOTP: true,
                        countdownTimer: 60,
                        cryptoError: '',
                        errorMsg: '',
                    },
                    () => {
                        // Start the countdown timer
                        controller.timerCount();
                    }
                );
            } catch (err) {
                const _errObj = ou_otpErrorHandler(err);

                let errMsg = '';

                errMsg = _errObj?.key ? `otp:otp.request.message.${_errObj?.key}` : _errObj?.message;

                controller.vm.setState({ cryptoError: errMsg, disableOtpBtn: true, submittedOTP: false, attemptCount: _errObj?.maxAttempt });
            }
        } else {
            console.warn('OTP button is disabled.');
        }
    },

    timerCount() {
        let vm = controller.vm,
            secondCount = 0;

        if (vm.state.countdownTimer > 0) {
            setTimeout(() => {
                secondCount = vm.state.countdownTimer - 1;
                vm.setState({ countdownTimer: secondCount });
                controller.timerCount();
            }, 1000);
        }
    },

    checkOtpBtnDisable() {
        const { selectedCurrency, selectedNetwork, address } = controller.vm.state;

        if (selectedCurrency && selectedNetwork && address) {
            controller.vm.setState({ disableOtpBtn: false });
        } else {
            controller.vm.setState({ disableOtpBtn: true });
        }
    },

    checkAddressBtnDisable() {
        const { selectedCurrency, selectedNetwork, address, otpCode } = controller.vm.state;
        const { processedData } = controller.vm.props.authSettingsReducer;

        if (processedData?.[MT.CRYPTO_ADDRESS]?.otp_feature) {
            if (selectedCurrency && selectedNetwork && address && otpCode) {
                controller.vm.setState({ cryptoAddressDisable: false });
            } else {
                controller.vm.setState({ cryptoAddressDisable: true });
            }
        } else if (selectedCurrency && selectedNetwork && address) {
            controller.vm.setState({ cryptoAddressDisable: false });
        } else {
            controller.vm.setState({ cryptoAddressDisable: true });
        }
    },

    handleCryptoAddressSubmit(vm) {
        const { selectedCurrency, selectedNetwork, address, preferContact, otpCode } = controller.vm.state;
        const params = {
            address: address,
            cryptoCoin: selectedCurrency,
            cryptoNetwork: selectedNetwork,
            verificationMethod: preferContact?.toUpperCase(),
            verifyKey: otpCode,
        };

        const onSuccess = () => {
            core.portalIsLoading(controller.vm.props, true);
            controller.vm.setState(
                {
                    selectedNetwork: '',
                    selectedCurrency: '',
                    address: '',
                    otpCode: '',
                    countdownTimer: 0,
                    submittedOTP: false,
                    cryptoError: '',
                    cryptoAddressDisable: true,
                },
                () => {
                    controller.getCryptoMemberFullAddress();
                    controller.handleMobileAddCoin(false, vm);
                }
            );
        };

        const onError = (errMsg) => {
            controller.vm.setState({ cryptoError: errMsg });
        };

        APIClient.createMemberCryptoAddress(params, onSuccess, onError);
    },

    handleMobileAddCoin(openClose, vm) {
        if (openClose === false) {
            controller.vm.setState({
                selectedCoinIndex: 0,
                selectedNetworkIndex: 0,
                networkOption: [{ displayName: 'transaction:transaction.deposit.crypto.selectNetwork' }],
            });
        }

        controller.vm.setState({ isAddCoin: openClose }, () => {
            if (vm && vm.myRef) {
                vm.myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        });
    },

    handleMobileAddEwallet(openClose, vm) {
        controller.vm.setState({ isAddEwallet: openClose }, () => {
            if (vm && vm.myRef) {
                vm.myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        });
    },

    handleClearReceipt() {
        trans_handleBankStatementClearReceipt(controller.vm);
    },

    handleReceiptImageChange(e) {
        trans_handleBankStatementReceiptImageChange(controller.vm, e);
    },

    getAddressModal(flag) {
        controller.vm.setState({ addressModal: flag });
    },

    handleSetPreferContact(contact) {
        const { authSettingsReducer } = controller.vm.props;
        const { method } = controller.vm.state;

        let bankType = method === 'bank' ? MT.BANK_DETAIL_BANK : method === 'crypto' ? MT.CRYPTO_ADDRESS : method === 'ewallet' ? MT.BANK_DETAIL_EWALLET : null;

        if (bankType) {
            const getContact = contact ? contact : authSettingsReducer?.processedData?.[bankType]?.authMethod?.[0]?.type;

            controller.vm.setState({ preferContact: getContact });
        }
    },
};

export default controller;
