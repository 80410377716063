import { userConstants, USER_LOGIN_ERROR_CODE } from '../constant';
import { authSettingsAction } from '@redux/action';
export const userAction = {
    login,
    isAuthenticated,
    logout: logout,
    clearResetPassword,
    hideHomepageAnnouncement,
    hideDepositAnnouncement,
    hideWithdrawAnnouncement,
    getUnreadMsg,
    openSpecialDialog,
    closeSpecialDialog,
    updateUnreadMsg,
    getAffiliateOrReferralCode,
    updateCertainUser,
};

function login(loginObj, isAllowTurnstileForLiteApp) {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            dispatch({ type: userConstants.LOGIN_REQUEST, payload: loginObj });
            window.SPL_Member.authenticate(loginObj)
                .then((data) => {
                    dispatch({ type: userConstants.LOGIN_SUCCESS, payload: { isLogin: true } });
                    dispatch({ type: userConstants.IS_LOADING, payload: { isLoading: true } });
                    resolve();
                })
                .catch((err) => {
                    if (err.errCode === USER_LOGIN_ERROR_CODE?.TURNSTILE_ATTEMPT_REACH) {
                        // lite app handling
                        const showTurnstile = isAllowTurnstileForLiteApp || !window.SPL_LiteApp.isLiteApp();
                        const payload = {
                            module: 'LOGIN',
                            moduleEnableTurnstile: showTurnstile,
                        };
                        dispatch(authSettingsAction.setTurnstileEnabled(payload));
                    }
                    dispatch({ type: userConstants.LOGIN_FAILURE, payload: { isLogin: false, isFailedLogin: true } });
                    dispatch({ type: userConstants.SHOW_RESET_PASSWORD, payload: { showResetPassword: true, code: err.errCode } });
                    reject();
                });
        });
    };
}

function isAuthenticated() {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMember(
                {},
                (account) => {
                    if (account) {
                        if (account.login) {
                            window.lh_ml = account.login;
                        }

                        if (account.groupName) {
                            window.lh_mgn = account.groupName;
                        }

                        if (account.membershipLevel) {
                            window.lh_mgt = account.membershipLevel;
                        }
                        dispatch({ type: userConstants.LOGIN_SUCCESS, payload: { isLogin: true } });
                        dispatch({ type: userConstants.IS_LOADING, payload: { isLoading: false } });
                        dispatch({ type: userConstants.GET_MEMBER_ACCOUNT, payload: account });
                    }
                    dispatch({ type: userConstants.GET_MEMBER_REQUEST, payload: true });
                    // dispatch({ type: userConstants.LOGIN_REQUEST, payload: {loading:true} });

                    resolve();
                },
                (err) => {
                    dispatch({ type: userConstants.GET_MEMBER_REQUEST, payload: true });
                    dispatch({ type: userConstants.GET_MEMBER_ACCOUNT_FAILED, payload: null });
                    // dispatch({ type: userConstants.LOGIN_REQUEST, payload: {loading:true} });

                    resolve();
                }
            );
        });
    };
}

function logout() {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            window.SPL_Member.logout()
                .then(() => {
                    window.lh_ml = '';
                    window.lh_mgn = '';
                    window.lh_mgt = '';
                    dispatch({ type: userConstants.LOGOUT, payload: { isLogin: false, isFailedLogin: false } });
                    resolve();
                })
                .catch((err) => {
                    dispatch({ type: userConstants.LOGOUT, payload: { isLogin: false, isFailedLogin: false } });
                    reject();
                });
        });
    };
}

function clearResetPassword() {
    return (dispatch) => {
        dispatch({ type: userConstants.SHOW_RESET_PASSWORD, payload: { showResetPassword: false } });
    };
}

function hideHomepageAnnouncement() {
    return (dispatch) => {
        dispatch({ type: userConstants.HIDE_HOMEPAGE_ANNOUNCEMENT, payload: { showAnnoucement: 'hide' } });
    };
}

function hideDepositAnnouncement() {
    return (dispatch) => {
        dispatch({ type: userConstants.HIDE_DEPOSIT_ANNOUNCEMENT, payload: { depositAnnouncement: 'hide' } });
    };
}

function hideWithdrawAnnouncement() {
    return (dispatch) => {
        dispatch({ type: userConstants.HIDE_WITHDRAW_ANNOUNCEMENT, payload: { withdrawAnnouncement: 'hide' } });
    };
}

function getUnreadMsg(count) {
    return (dispatch) => {
        dispatch({ type: userConstants.GET_UNREAD_MSG, payload: { unreadMsg: count } });
    };
}

function openSpecialDialog() {
    return (dispatch) => {
        dispatch({ type: userConstants.OPEN_SPECIAL_DIALOG, payload: { isSpecialDialog: true } });
    };
}

function closeSpecialDialog() {
    return (dispatch) => {
        dispatch({ type: userConstants.CLOSE_SPECIAL_DIALOG, payload: { isSpecialDialog: false } });
    };
}

function updateUnreadMsg(totalUnreadMessageCount, totalUnreadNotificationCount, totalUnreadPaymentCount, totalUnreadPromotionCount, totalUnreadSystemCount) {
    return (dispatch) => {
        dispatch({
            type: userConstants.MESSAGE_UNREAD_COUNT,
            payload: {
                totalUnreadMessageCount: totalUnreadMessageCount,
                totalUnreadNotificationCount: totalUnreadNotificationCount,
                totalUnreadPaymentCount: totalUnreadPaymentCount,
                totalUnreadPromotionCount: totalUnreadPromotionCount,
                totalUnreadSystemCount: totalUnreadSystemCount,
            },
        });
    };
}

function getAffiliateOrReferralCode() {
    return (dispatch) => {
        return new Promise(function (resolve) {
            var refCode = window.SPL_Cookie.getCookie('RefCode');
            var affCode = window.SPL_Cookie.getCookie('AffCode');
            var refCurrency = window.SPL_Cookie.getCookie('Ref_Currency');
            let affConfig = {};

            if (refCode) {
                affConfig.affiliateid = refCode;
                affConfig.currency = refCurrency;
                affConfig.disabledAffiliate = true;
                affConfig.referralToggle = true;

                dispatch({ type: userConstants.GET_AFFILIATE_OR_REFERRAL_CODE, payload: { affConfig: affConfig } });
                resolve();
            } else if (affCode) {
                affConfig.affiliateid = affCode;
                affConfig.disabledAffiliate = true;
                affConfig.referralToggle = true;

                dispatch({ type: userConstants.GET_AFFILIATE_OR_REFERRAL_CODE, payload: { affConfig: affConfig } });
                resolve();
            } else {
                window.SPL_Register.getCorrectRegisterAffiliateOrReferral().then((data) => {
                    if (data && data.referralCode) {
                        affConfig.affiliateid = data.referralCode;
                        affConfig.disabledAffiliate = true;
                        affConfig.referralToggle = true;

                        dispatch({ type: userConstants.GET_AFFILIATE_OR_REFERRAL_CODE, payload: { affConfig: affConfig } });
                        resolve();
                    } else {
                        resolve();
                    }
                });
            }
        });
    };
}

function updateCertainUser(account) {
    return (dispatch) => {
        dispatch({ type: userConstants.GET_MEMBER_ACCOUNT, payload: account });
    };
}
