// library
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// components / selector
import Popup, { PopupHeader, PopupBody, PopupFooter } from 'root/components/organisms/popup/popup';
import FormField from 'root/components/molecules/form-field/form-field';
import notification from 'root/utils/notification';
import Button from 'root/components/atoms/button/button';

// controller / util
import { getPasswordConfigApi, fetchProcessedPasswordConfig } from 'root/redux/action/password.action';
import { useValidatePassword, usePreCheckSpecialCharFormatValidity } from 'root/hooks/password-hook';
import { psw_validateConfirmPassword, psw_validateCurrentPassword } from 'root/utils/password-util';
import { gu_logout } from 'root/utils/general-util';

const ForceChangePassword = () => {
    const { t } = useTranslation(['password', 'settings']);
    const dispatch = useDispatch();
    const screen = useSelector((state) => state.screen);
    const passwordReducer = useSelector((state) => state.passwordReducer);
    const languageRedux = useSelector((state) => state.language);
    const passwordConfig = passwordReducer?.processedData?.passwordConfig || {};

    const [isSkipCheckSpecialChars, setIsSkipCheckSpecialChars] = useState(true);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const [changedField, setChangedField] = useState(null);
    const [fields, setField] = useState({
        currentPassword: '',
        password: '',
        confirmPassword: '',
    });
    const [errors, setError] = useState({});

    useEffect(() => {
        dispatch(getPasswordConfigApi());
    }, []);

    useEffect(() => {
        if (passwordReducer?.rawData?.status) {
            dispatch(fetchProcessedPasswordConfig());
        }
    }, [passwordReducer?.rawData?.status]);

    const onFieldChange = (event) => {
        const { name, value } = event.target;
        setField({
            ...fields,
            [name]: value,
        });
        setChangedField(name);
        setIsButtonClicked(false);
    };

    useEffect(() => {
        switch (changedField) {
            case 'currentPassword':
                onValidateCurrentPassword();
                break;
            case 'password':
                onValidatePassword();
                break;
            case 'confirmPassword':
                onValidateConfirmPassword();
                break;
            default:
                break;
        }
    }, [changedField, fields[changedField]]);

    const passwordError = useValidatePassword(fields['password'], isSkipCheckSpecialChars);

    const verifyCurrentAndNewPassword = (updatedError) => {
        const checkErrorKey = psw_validateCurrentPassword(fields['currentPassword'], fields['password']);
        const isSamePwd = fields['currentPassword'] === fields['password'];

        updatedError.password = isSamePwd ? 'password_same' : passwordError;

        updatedError.currentPassword = isSamePwd ? '' : checkErrorKey;

        return updatedError;
    };

    function onValidateCurrentPassword() {
        let updatedError = {};
        updatedError = verifyCurrentAndNewPassword(updatedError);
        // setError({ ...errors, currentPassword: currentPasswordErrorKey });
        setError({ ...errors, ...updatedError });
    }

    function onValidatePassword() {
        let updatedError = {};
        updatedError = verifyCurrentAndNewPassword(updatedError);
        if (fields['confirmPassword']) {
            updatedError.confirmPassword = psw_validateConfirmPassword(fields['password'], fields['confirmPassword']);
        }

        setError({ ...errors, ...updatedError });
    }

    function onValidateConfirmPassword() {
        const errorKey = psw_validateConfirmPassword(fields['password'], fields['confirmPassword']);
        setError({ ...errors, confirmPassword: errorKey });
    }

    const isPreCheckPasswordContainError = !usePreCheckSpecialCharFormatValidity(fields['password'], isSkipCheckSpecialChars);
    const submitForceChangePasswordForm = async () => {
        setIsButtonClicked(true);
        if (isPreCheckPasswordContainError) {
            setIsSkipCheckSpecialChars(false);
            return;
        }

        let changePasswordApiParam = {
            newPassword: fields['password'],
            password: fields['currentPassword'],
        };
        const passwordConfigApiData = passwordReducer.rawData?.passwordConfigApiData;
        if (passwordConfigApiData && Object.keys(passwordConfigApiData).length > 0) {
            changePasswordApiParam = {
                ...changePasswordApiParam,
                passwordConfig: passwordConfigApiData,
            };
        }

        try {
            const isUpdateSuccess = await window.SPL_Member.changePasswordV2(changePasswordApiParam);
            if (isUpdateSuccess) {
                notification.showNotification(
                    'success',
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('password:password.messages.success', { interpolation: { escapeValue: false } }),
                        }}
                    />
                );
                gu_logout(dispatch, languageRedux?.countryLanguage);
            }
        } catch (err) {
            // TODO: can ask whether we should have more clear error message based on errorKey/message from BE, currently we only show general error message
            notification.showNotification(
                'error',
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('global:global.messages.error.invalidcurrentpass', { interpolation: { escapeValue: false } }),
                    }}
                />
            );
        }
    };
    useEffect(() => {
        // submit trigger isSkipCheckSpecialChars become false and validate field again.
        if (!isSkipCheckSpecialChars) {
            onValidatePassword();
        }
    }, [isSkipCheckSpecialChars]);

    useEffect(() => {
        isSubmitBtnDisable();
    }, [isButtonClicked]);

    const isSubmitBtnDisable = () => {
        const isMandatoryFieldsInvalid = fields && Object.keys(fields).some((field) => !fields[field]);
        const isErrorExist = Object.keys(errors).some((field) => errors[field]);
        return isMandatoryFieldsInvalid || isErrorExist || isButtonClicked ? true : false;
    };

    return (
        <Popup isOpen={true} className='chg-psw-popup-modal-container'>
            <PopupHeader popupTitle={t('settings:settings.form.changePassword', 'Change Password')} />

            <PopupBody>
                <FormField
                    label={t('global:global.form.currentpassword', 'Current Password')}
                    isMandatory={true}
                    type={'password'}
                    name={'currentPassword'}
                    value={fields['currentPassword']}
                    errorMessage={
                        errors['currentPassword'] &&
                        t(`password:password.messages.${errors['currentPassword']}`, { min: passwordConfig?.minLength, max: passwordConfig?.maxLength })
                    }
                    placeholder={t('global:global.form.currentpassword', 'Current password')}
                    classes='passwordInput'
                    onChange={onFieldChange}
                    onBlur={onValidateCurrentPassword}
                    debouncedInputMS={1000}
                    layout={screen.viewType === 'mobile' ? null : 'leftRight'}
                />

                <FormField
                    label={t('global:global.form.newpassword', 'Password')}
                    isMandatory={true}
                    type={'password'}
                    name={'password'}
                    value={fields['password']}
                    errorMessage={errors['password'] && t(`password:password.messages.${errors['password']}`)}
                    placeholder={t('global:global.form.newpassword', 'Password')}
                    onChange={onFieldChange}
                    onBlur={onValidatePassword}
                    isShowSpecialCharsGuide={isSkipCheckSpecialChars ? false : true}
                    debouncedInputMS={1000}
                    layout={screen.viewType === 'mobile' ? null : 'leftRight'}
                    isValidationChecker={true}
                    showErrorMsgPriority={fields['password'] === fields['currentPassword']}
                />

                <FormField
                    label={t('global:global.form.confirmpassword', 'Confirm Password')}
                    isMandatory={true}
                    type={'password'}
                    name={'confirmPassword'}
                    value={fields['confirmPassword']}
                    errorMessage={errors['confirmPassword'] && t(`password:password.messages.${errors['confirmPassword']}`)}
                    placeholder={t('global:global.form.confirmpassword', 'Confirm Password')}
                    onChange={onFieldChange}
                    onBlur={onValidateConfirmPassword}
                    debouncedInputMS={1000}
                    layout={screen.viewType === 'mobile' ? null : 'leftRight'}
                />
            </PopupBody>

            <PopupFooter>
                <div className='standard-button-container'>
                    <Button scssClasses='standard-submit-form-button' classes='submitButton' disabled={isSubmitBtnDisable()} onClick={submitForceChangePasswordForm}>
                        <span>{t('settings:settings.form.button', 'Save')}</span>
                    </Button>
                </div>
            </PopupFooter>
        </Popup>
    );
};

export default ForceChangePassword;
