import React, { Suspense, Fragment, lazy } from 'react';
import FlipMove from 'react-flip-move';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import DeleteBankDialog from '../../../delete-bank-dialog/delete-bank-dialog.selector';
import vm from '../../bank-detail.controller';
import styles from '../bank-detail.module.scss';

import { trans_handleUploadReceiptButtonOnClick } from 'root/utils/transaction-util';
import { ou_filterMasterSwitchAuthMethods, ou_constructContactVerificationMethods } from '@utils/otp-util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BankIcon, { CryptoBankIcon } from '../../../../../../components/molecules/bank-icon/bank-icon';
import { MODULE_TYPE as MT } from '@constants';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import AddCryptoAddressPopup from '@components/organisms/add-crypto-address-popup/add-crypto-address-popup';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import Button from '@components/atoms/button/button';
import { SetupSixDigitPinNotice } from '@components/organisms/set-up-pin-popup/set-up-pin-popup';

const AuthOtpTabs = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs'));
const AuthOtpTab = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs').then((module) => ({ default: module.AuthOtpTab })));

class BankDetail extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.loadEwallet().then(() => {
                vm.loadBankAccounts();
                vm.handleSetPreferContact();
            });
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
    render() {
        const { authSettingsReducer } = this.props;
        const _authSettingsCryptoAddressModule = authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS];
        const _authSettingsBankDetailModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
        const _authSettingsBankDetailEWalletModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_EWALLET];

        const _authMethods = ou_filterMasterSwitchAuthMethods(_authSettingsCryptoAddressModule?.authMethod);
        const authMethodType = ou_constructContactVerificationMethods(_authSettingsCryptoAddressModule?.authMethod);

        const otpNs = 'otp:otp';

        const chkBankDetailSixPinStatus =
            (_authSettingsBankDetailModule?.sixPinEnabled && _authSettingsBankDetailModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailModule?.sixPinEnabled && !_authSettingsBankDetailModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const chkBankDetailEWalletSixPinStatus =
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && _authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && !_authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const { depositAllSettings, compSettings, receiptImg, receiptImgFile, preferContact, cryptoError, attemptCount } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;
        const showBankBranch = depositAllSettings?.showBankBranch;
        if (!depositAllSettings) return null;

        let deleteBankDialogProps = {
            parentvm: this,
            closeDeleteBankDialog: vm.closeDeleteBankDialog,
            deleteBankDialogIsShow: this.state.deleteBankDialogIsShow,
            selectedBankObj: this.state.selectedBankObj,
            method: this.state.method,
        };

        let deleteEwalletDialogProps = {
            parentvm: this,
            closeDeleteBankDialog: vm.closeDeleteBankDialog,
            deleteBankDialogIsShow: this.state.deleteEwalletDialogIsShow,
            selectedBankObj: this.state.selectedBankObj,
            method: this.state.method,
            customDeleteDialogHeader: 'bank:bank.deleteEwalletConfirm',
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div className='bank-page'>
                        <AuthSettings module={MT?.CRYPTO_ADDRESS} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_EWALLET} isPublic={false} />

                        <div className='group-item bank-option'>
                            <span className={this.state.method === 'bank' ? 'on' : ''} onClick={() => vm.onMethodChange('bank', 'B')}>
                                {t('settings:settings.profiletab.bank', 'Bank Details')}
                            </span>
                            <span className={this.state.method === 'crypto' ? 'on' : ''} onClick={() => vm.onMethodChange('crypto', 'CT')}>
                                {t('transaction:transaction.deposit.crypto.cryptoAddress', 'Crypto Address')}
                            </span>
                            {this.state.ewalletEnabled && (
                                <span className={this.state.method === 'ewallet' ? 'on' : ''} onClick={() => vm.onMethodChange('ewallet')}>
                                    {t('transaction:transaction.deposit.cashdeposit.ewallet', 'Ewallet')}
                                </span>
                            )}
                        </div>

                        {this.state.deleteBankDialogIsShow && <DeleteBankDialog {...deleteBankDialogProps}></DeleteBankDialog>}
                        {this.state.deleteEwalletDialogIsShow && <DeleteBankDialog {...deleteEwalletDialogProps}></DeleteBankDialog>}
                        {this.state.addressModal && (
                            <AddCryptoAddressPopup
                                isOpen={this.state.addressModal}
                                onRequestClose={() => vm.getAddressModal(false)}
                                onCryptoAddressPopupSubmitSuccessCb={() => vm.getCryptoMemberFullAddress()}
                            />
                        )}

                        {this.state.method === 'bank' && (
                            <>
                                {chkBankDetailSixPinStatus && (
                                    <Suspense fallback={<div></div>}>
                                        <SetupSixDigitPinNotice module={MT.BANK_DETAIL_BANK} />
                                    </Suspense>
                                )}
                                <div className='bank-account-list'>
                                    <div className='table-header'>
                                        <ul>
                                            <li>{t('bank:bank.bank')}</li>
                                            {showBankBranch && <li>{t('bank:bank.branch')}</li>}
                                            <li>{t('bank:bank.account.holder')}</li>
                                            <li>{t('bank:bank.account.number')}</li>
                                            {reqBankStatementByCurrencies && <li>{t('bank:bank.account.status')}</li>}
                                            <li></li>
                                        </ul>
                                    </div>
                                    <FlipMove className='table-list' enterAnimation='fade' leaveAnimation='fade' duration='500'>
                                        {this.state.memberBankList.map((bank, i) => (
                                            <ul key={i}>
                                                <li className='icon-hover-box bank-name on'>
                                                    <div className='icon-box'>
                                                        <BankIcon bankCode={bank.bankCode} />
                                                    </div>
                                                    <div className='bank-name'>{bank.bankName}</div>
                                                </li>
                                                {showBankBranch && (
                                                    <li>
                                                        <span>{bank.bankBranch}</span>
                                                    </li>
                                                )}
                                                <li>
                                                    <span>{bank.accountHolder}</span>
                                                </li>
                                                <li>
                                                    <span>{bank.accountNumber}</span>
                                                </li>
                                                {reqBankStatementByCurrencies && (
                                                    <li>
                                                        <span className={bank.verified ? 'verified' : 'verification-status'}>
                                                            {t(`bank:bank.account.verification.${bank.verifiedDisplayStatus}`)}
                                                        </span>
                                                    </li>
                                                )}
                                                <li>
                                                    {this.state.depositAllSettings && !this.state.depositAllSettings.hideDeleteBankAccount && (
                                                        <i
                                                            className='icon-delete'
                                                            onClick={() => {
                                                                vm.openDeleteBankDialog(bank, 'bank');
                                                            }}
                                                        ></i>
                                                    )}

                                                    {/* <i className="icon-edit"></i> */}
                                                </li>
                                            </ul>
                                        ))}
                                        {/* TODO REMOVE IF NO USED */}
                                        {/* <div key='tip-box' className='tip-box'>
                                            <CSSTransition classNames='fade-next' in={this.state.memberBankList.length < 5} timeout={500} unmountOnExit>
                                                <span>
                                                    You can add {this.state.memberBankList.length != 5 ? 5 - this.state.memberBankList.length : 1}
                                                    &nbsp; more bank accounts.
                                                </span>
                                            </CSSTransition>
                                        </div> */}
                                    </FlipMove>
                                </div>

                                {!chkBankDetailSixPinStatus && (
                                    <div className='bank-add'>
                                        <div className='add-title'>{t('bank:bank.newBank.title')}</div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.bank', 'Bank')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <select onChange={(e) => vm.handleBankChange(e)} value={this.state.fields.selectedBankIndex}>
                                                        {this.state.unOwnedBankList.map((bank, index) => (
                                                            <option key={index} value={index}>
                                                                {t(bank.bankName)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.showBankName && (
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span> {t('bank:bank.newBank.bankName', 'Bank Name')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={this.state.fields['bankName']}
                                                            onChange={(e) => vm.handleFieldChange(e, 'bankName')}
                                                            id='bankName'
                                                            placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                        ></input>
                                                    </div>
                                                    {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                        return (
                                                            <div key={index} className='error-validation'>
                                                                <Trans i18nKey={errMsg}></Trans>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {this.state.depositAllSettings && this.state.depositAllSettings.showBankBranch && (
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.branch', 'Bank Branch')}</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={this.state.fields['bankBranch']}
                                                            onChange={(e) => vm.handleFieldChange(e, 'bankBranch')}
                                                            id='bankBranch'
                                                            placeholder={t('bank:bank.branch', 'Bank Branch')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                                <span> </span>
                                            </div>
                                            <div className='group'>
                                                <span>{this.state.fields['accountName']}</span>
                                            </div>
                                        </div>

                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <input
                                                        type='text'
                                                        value={this.state.fields['accountNumber']}
                                                        onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                        id='accountNumber'
                                                        placeholder={t('bank:bank.newBank.account-number', 'Account Number')}
                                                    ></input>
                                                </div>
                                                <div className='error-validation'>{t(this.state.errors['accountNumber'])}</div>
                                                {/* <div className='check-box'>
              <i
                  className={['checkbox', this.state.preferred ? 'on' : ''].join(' ')}
                  onClick={() => {
                      this.setState({
                          ...this.state,
                          preferred: !this.state.preferred,
                      });
                  }}
              ></i>
              <span
                  onClick={() => {
                      this.setState({
                          ...this.state,
                          preferred: !this.state.preferred,
                      });
                  }}
              >
                  Set as Preferred
              </span>
          </div> */}
                                            </div>
                                        </div>

                                        {reqBankStatementByCurrencies && (
                                            <>
                                                <div>
                                                    {' '}
                                                    {this.state.receiptImg.length > 0 && (
                                                        <div className='group-item input-item'>
                                                            <div className='title'></div>
                                                            <div className='group'>
                                                                <div className='receipt-image-box'>
                                                                    {receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                                        <>
                                                                            <label className='pdf-label'>
                                                                                {t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}
                                                                            </label>
                                                                            <span className='pdf-name'>{receiptImgFile.name}</span>
                                                                            <img
                                                                                src='/public/images/icon/close-red.png'
                                                                                className='pdf-clear'
                                                                                onClick={() => vm.handleClearReceipt()}
                                                                                alt=''
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <LazyLoadImage src={receiptImg} alt='' onClick={() => vm.handleClearReceipt()} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='group-item input-item'>
                                                    <div className='title'>
                                                        <span>{t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}</span>
                                                        <span className='required'>*</span>
                                                    </div>
                                                    <div className='group'>
                                                        <div className='input-box'>
                                                            {
                                                                <button
                                                                    className='btn btn-reset'
                                                                    onClick={() => {
                                                                        trans_handleUploadReceiptButtonOnClick();
                                                                    }}
                                                                >
                                                                    {t('transaction:transaction.withdrawal.upload', 'UPLOAD').toUpperCase()}
                                                                    <input
                                                                        id='hiddenBankStatementInput'
                                                                        style={{ display: 'none' }}
                                                                        type='file'
                                                                        accept='image/*,.pdf'
                                                                        onChange={(e) => vm.handleReceiptImageChange(e, this)}
                                                                    ></input>
                                                                </button>
                                                            }
                                                        </div>
                                                        <div className='error-validation'>
                                                            {t(
                                                                'transaction:transaction.withdrawal.bankStatementRequirement',
                                                                'Bank statement must show with full name and bank account number'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {vm.isValidForm()}

                                        {depositAllSettings && !depositAllSettings.hideAddBankAccount && (
                                            <button
                                                className='btn-submit'
                                                disabled={!vm.isValidForm() || (reqBankStatementByCurrencies && receiptImg.length <= 0)}
                                                onClick={() => {
                                                    vm.handleSubmit();
                                                }}
                                            >
                                                {t('global:entity.action.add').toUpperCase()}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        {this.state.method === 'crypto' && (
                            <>
                                <div className='bank-account-list'>
                                    <div className='table-header'>
                                        <ul>
                                            <li>{t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')}</li>
                                            <li>{t('transaction:transaction.deposit.crypto.network', 'Network')}</li>
                                            <li>{t('transaction:transaction.deposit.crypto.address', 'Address')}</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <FlipMove className='table-list' enterAnimation='fade' leaveAnimation='fade' duration='500'>
                                        {this.state.cryptoAddress &&
                                            this.state.cryptoAddress.length > 0 &&
                                            this.state.cryptoAddress.map((result, i) => (
                                                <ul key={i}>
                                                    <li className='icon-hover-box bank-name on'>
                                                        <div className='icon-box'>
                                                            <CryptoBankIcon bankCode={`${result.coin}_${result.network}`} coin={result.coin} />
                                                        </div>
                                                        <div className='bank-name'>{result.coin}</div>
                                                    </li>
                                                    <li>
                                                        <span>{result.network}</span>
                                                    </li>
                                                    <li>
                                                        <span>{result.address}</span>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className='icon-delete'
                                                            onClick={() => {
                                                                vm.openDeleteBankDialog(result, 'crypto');
                                                            }}
                                                        ></i>
                                                    </li>
                                                </ul>
                                            ))}
                                    </FlipMove>
                                </div>

                                <div className='bank-add'>
                                    {_authSettingsCryptoAddressModule?.gaEnabled && (
                                        <>
                                            {!depositAllSettings?.hideAddBankAccount && (
                                                <AddCryptoAddressButton
                                                    module={MT?.CRYPTO_ADDRESS}
                                                    onClick={() => vm.getAddressModal(true)}
                                                    text={t('transaction:transaction.deposit.crypto.addAddress', 'Add Address')}
                                                    className={'standard-button standard-submit-button'}
                                                />
                                            )}
                                        </>
                                    )}
                                    {!_authSettingsCryptoAddressModule?.gaEnabled && (
                                        <>
                                            <div className='add-title'>{t('transaction:transaction.deposit.crypto.addAddress', 'Add Address')}</div>

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <select onChange={(e) => vm.handleCryptoCurrencyChange(e)} value={this.state.selectedCurrency}>
                                                            {this.state.addressOption.map((curr, index) => (
                                                                <option key={index} value={curr.displayName}>
                                                                    {t(curr.displayName)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span> {t('transaction:transaction.deposit.crypto.network', 'Network')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <select onChange={(e) => vm.handleCryptoNetworkChange(e)} value={this.state.selectedNetwork}>
                                                            {this.state.networkOption.map((network, index) => (
                                                                <option key={index} value={network.displayName}>
                                                                    {t(network.displayName)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('transaction:transaction.deposit.crypto.address', 'Address')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={this.state.address}
                                                            onChange={(e) => vm.handleCryptoAddressChange(e)}
                                                            id='accountNumber'
                                                            placeholder={t('transaction:transaction.deposit.crypto.address', 'Address')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>

                                            {!_authSettingsCryptoAddressModule?.gaEnabled && _authSettingsCryptoAddressModule?.otp_feature && _authMethods.length > 0 && (
                                                <div>
                                                    <AuthOtpTabs
                                                        className={styles.authOtpTabsContainer}
                                                        authMethodType={authMethodType?.[preferContact === 'WHATSAPP' ? 'PHONE' : preferContact]}
                                                        handleSetPreferContact={vm.handleSetPreferContact}
                                                        preferContact={preferContact}
                                                    >
                                                        {(_authMethods || []).map((method) => {
                                                            const { type } = method;
                                                            const contactType = type && type.toLowerCase();
                                                            let _preferContact = preferContact === 'WHATSAPP' ? 'PHONE' : preferContact;
                                                            return (
                                                                <Fragment key={method.type}>
                                                                    <AuthOtpTab
                                                                        tabText={t(`${otpNs}.tab.${contactType}`)}
                                                                        onClick={() => vm.handleSetPreferContact(type)}
                                                                        isActive={_preferContact === type}
                                                                    />
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </AuthOtpTabs>

                                                    <div className='group-item input-item'>
                                                        <div className='title'>
                                                            <span>{t('transaction:transaction.deposit.crypto.OTP', 'OTP')}</span>
                                                            <span className='required'>*</span>
                                                        </div>
                                                        <div className='group'>
                                                            <div className='input-box box-otp'>
                                                                <input
                                                                    name='otp'
                                                                    type='number'
                                                                    value={this.state.otpCode}
                                                                    onChange={(e) => vm.handleOtpChange(e)}
                                                                    // onBlur={vm.validateOtp}
                                                                    id='accountNumber'
                                                                    placeholder={t('global:global.verification.enterOTPCode')}
                                                                    autoComplete='off'
                                                                    onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                                                                    debouncetimeout={1000}
                                                                ></input>

                                                                <button disabled={this.state.disableOtpBtn}>
                                                                    {this.state.submittedOTP === true ? (
                                                                        this.state.countdownTimer > 0 ? (
                                                                            <span>{this.state.countdownTimer}s</span>
                                                                        ) : (
                                                                            <span onClick={() => vm.sendOtpCode()}>{t('global:global.verification.resend')}</span>
                                                                        )
                                                                    ) : (
                                                                        <span onClick={() => vm.sendOtpCode()}>{t('register:register.form.getCode')}</span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='group-item input-item error-item'>
                                                <div className='title'></div>
                                                <div className='group'>{cryptoError && <div className='error-validation'>{t(cryptoError, { attemptCount })}</div>}</div>
                                            </div>

                                            {depositAllSettings && !depositAllSettings.hideAddBankAccount && (
                                                <button
                                                    className='btn-submit'
                                                    disabled={this.state.cryptoAddressDisable}
                                                    onClick={() => {
                                                        vm.handleCryptoAddressSubmit();
                                                    }}
                                                >
                                                    {t('global:entity.action.save').toUpperCase()}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {this.state.method === 'ewallet' && (
                            <>
                                {chkBankDetailEWalletSixPinStatus && (
                                    <Suspense fallback={<div></div>}>
                                        <SetupSixDigitPinNotice module={MT.BANK_DETAIL_EWALLET} />
                                    </Suspense>
                                )}
                                <div className='bank-account-list'>
                                    <div className='table-header'>
                                        <ul>
                                            <li>{t('bank:bank.newBank.eWallet')}</li>
                                            {showBankBranch && <li>{t('bank:bank.branch')}</li>}
                                            <li>{t('bank:bank.newBank.walletName')}</li>
                                            <li>{t('bank:bank.newBank.walletNumber')}</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <FlipMove className='table-list' enterAnimation='fade' leaveAnimation='fade' duration='500'>
                                        {this.state.ewalletBank.map((bank, i) => (
                                            <ul key={i}>
                                                <li className='icon-hover-box bank-name on'>
                                                    <div className='icon-box'>
                                                        <BankIcon bankCode={bank.bankCode || bank.code} />
                                                    </div>
                                                    <div className='bank-name'>{bank.bankName}</div>
                                                </li>
                                                {showBankBranch && (
                                                    <li>
                                                        <span>{bank.bankBranch}</span>
                                                    </li>
                                                )}
                                                <li>
                                                    <span>{bank.accountHolder}</span>
                                                </li>
                                                <li>
                                                    <span>{bank.accountNumber}</span>
                                                </li>
                                                <li>
                                                    {this.state.depositAllSettings && !this.state.depositAllSettings.hideDeleteBankAccount && (
                                                        <i
                                                            className='icon-delete'
                                                            onClick={() => {
                                                                vm.openDeleteBankDialog(bank, 'ewallet');
                                                            }}
                                                        ></i>
                                                    )}

                                                    {/* <i className="icon-edit"></i> */}
                                                </li>
                                            </ul>
                                        ))}
                                    </FlipMove>
                                </div>

                                {!chkBankDetailEWalletSixPinStatus && (
                                    <div className='bank-add'>
                                        <div className='add-title'>{t('bank:bank.newBank.walletTitle')}</div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.eWallet', 'Bank')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <select onChange={(e) => vm.handleBankChange(e)} value={this.state.fields.selectedBankIndex}>
                                                        {this.state.unOwnedEwalletBank.map((bank, index) => (
                                                            <option key={index} value={index}>
                                                                {bank.bankName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {showBankBranch && (
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.walletName', 'Bank Branch')}</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={this.state.fields['bankBranch']}
                                                            onChange={(e) => vm.handleFieldChange(e, 'bankBranch')}
                                                            id='bankBranch'
                                                            placeholder={t('bank:bank.newBank.walletName', 'Bank Branch')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.walletName', 'Wallet Name')}</span>
                                                <span> </span>
                                            </div>
                                            <div className='group'>
                                                <span>{this.state.fields['accountName']}</span>
                                            </div>
                                        </div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.walletNumber', 'Wallet Number')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <input
                                                        type='text'
                                                        value={this.state.fields['accountNumber']}
                                                        onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                        id='accountNumber'
                                                        placeholder={t('bank:bank.newBank.walletNumber', 'Wallet Number')}
                                                    ></input>
                                                </div>
                                                <div className='error-validation'>{t(this.state.errors['accountNumber'])}</div>
                                            </div>
                                        </div>
                                        {vm.isValidForm()}
                                        <button
                                            className='btn-submit'
                                            disabled={!vm.isValidForm()}
                                            onClick={() => {
                                                vm.handleEwalletSubmit();
                                            }}
                                        >
                                            {t('global:entity.action.add').toUpperCase()}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'bank', 'register', 'global', 'otp', 'contactus'])(withRouter(BankDetail)));

export const AddCryptoAddressButton = ({ text, onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT.CRYPTO_ADDRESS, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <>
            <section className='btn-add-crypto-address-section'>
                <Button className='primary-btn' onClick={onAddBtnClick}>
                    {text}
                </Button>
            </section>
        </>
    );
};
