import commonUtil from '../../../../../../utils/common-util';
import transactionHelper from '../../../../../../utils/transaction-helper';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { scrollToDepositStep2 } from '../../deposit-util';

const METHOD_OR_CHANNEL_CODE = 'QR';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositAmountOptions: [],

            depositMinMaxLimit: { min: 0, max: 0 },
            methodList: [],
            bankList: [],
            qrpaySupportedBankList: [],
            qrpaySupportedBankCache: {},
            selectedMethodIndex: 0,
            selectedBankIndex: disablePreselectBank ? null : 0,
            hoveredIndex: null,

            // should be can put at parent de variables
            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            disableDepositButton: disablePreselectBank ? true : false,
            isFormValid: false,
            showAmountField: true,

            enableCustomArticleJSON: false,
            compSettings: null,
            depositSubmitCountdown: null,
        };
    },

    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { customCountdown } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'qrCodeSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, customCountdown, paymentCode, Date.now().valueOf(), { disableDepositButton: true });
                resolve();
            });
        });
    },

    async loadQRbank() {
        const vm = controller.vm;
        const { filteredDepositApiJson } = vm.state;
        const { paymentGatewaySetting } = vm.props;

        let _bankList = [];
        let _methodList = [];

        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            // For loop the channel/method list
            for (let method in filteredDepositApiJson.channel) {
                let methodObj = {};
                methodObj.channel = method;
                methodObj.displayName = window.SPL_Content.getBankName(method, 'channel', window.SPL_Translation.getTranslatedTransactionMsg(method, 'depositChannel'));
                // methodObj.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${methodObj.channel}.svg`, `${methodObj.channel}`);
                _methodList.push(methodObj);

                filteredDepositApiJson.channel[method].map((e) => {
                    e.name = window.SPL_Content.getBankName(e.code, 'bank', window.SPL_Translation.getBankChannelTranslation(e, 'channelCode'));
                });
            }
            if (_methodList.length > 0) {
                _bankList = Object.values(filteredDepositApiJson.channel)[0];

                // for (const bank of _bankList) {
                //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                // }
                transactionHelper.updateBankListWithPaymentGatewaySetting(paymentGatewaySetting, 'isRedirectToApp', _bankList);
            }

            const method = _methodList[0];
            const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);

            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    methodList: _methodList,
                    bankList: _bankList,
                    showAmountField,
                    isFormValid,
                    disableDepositButton: disablePreselectBank && !_bankList?.length ? false : controller.vm.state.disableDepositButton,
                },
                () => {
                    // controller.getCorrectDepositMinMaxLimit();
                    commonUtil.getDepositMinMax(
                        controller.vm,
                        'QR',
                        controller.vm.props.user.account.currency,
                        controller.vm.state.bankList[controller.vm.state.selectedBankIndex]?.code
                    );
                }
            );
        }
    },

    async changeQRChannel(methodObj, index) {
        const vm = controller.vm;
        const { paymentGatewaySetting } = vm.props;
        const { filteredDepositApiJson, selectedMethodIndex, methodList } = vm.state;
        if (selectedMethodIndex !== index) {
            let methodChannel = filteredDepositApiJson.channel;
            let newBankList = methodObj ? methodChannel[methodObj.channel] : [];
            // for (const bank of newBankList) {
            //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
            // }

            const method = methodList[index];
            const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);

            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            vm.setState(
                {
                    selectedMethodIndex: index,
                    bankList: newBankList,
                    selectedBankIndex: disablePreselectBank ? null : 0,
                    showAmountField,
                    isFormValid,
                    disableDepositButton: disablePreselectBank && !newBankList?.length ? false : controller.vm.state.disableDepositButton,
                },
                () => {
                    // controller.getCorrectDepositMinMaxLimit();
                    commonUtil.getDepositMinMax(
                        controller.vm,
                        'QR',
                        controller.vm.props.user.account.currency,
                        controller.vm.state.bankList[controller.vm.state.selectedBankIndex]?.code
                    );
                }
            );
        }
    },

    onBankChanged(bankObj, onChangeValue) {
        const { selectedBankIndex, bankList } = controller.vm.state;
        const {
            enableScrollToDepositStep2 = false,
            scrollToDepositStep2Attempts = 15,
            scrollToDepositStep2Delay = 200,
        } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

        // mobile use dao
        if (onChangeValue !== undefined) {
            if (!bankObj) {
                bankObj = bankList[onChangeValue];
            }

            controller.vm.setState({ selectedBankIndex: onChangeValue, disableDepositButton: false }, () => {
                // controller.getCorrectDepositMinMaxLimit(bankObj);
                scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, onChangeValue, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'QR',
                    controller.vm.props.user.account.currency,
                    controller.vm.state.bankList[controller.vm.state.selectedBankIndex]?.code
                );
                controller.getQrPaySupportedBankList(bankObj);
            });
        } else {
            let foundIndex = bankList.indexOf(bankObj);
            if (foundIndex !== selectedBankIndex) {
                controller.vm.setState({ selectedBankIndex: foundIndex, disableDepositButton: false }, () => {
                    // controller.getCorrectDepositMinMaxLimit(bankObj);
                    scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, foundIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                    commonUtil.getDepositMinMax(
                        controller.vm,
                        'QR',
                        controller.vm.props.user.account.currency,
                        controller.vm.state.bankList[controller.vm.state.selectedBankIndex]?.code
                    );
                    controller.getQrPaySupportedBankList(bankObj);
                });
            }
        }
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QR]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    getQrPaySupportedBankList(bankObj) {
        const { screen } = controller.vm.props;
        let { depositAllSettings, qrpaySupportedBankCache } = controller.vm.state;
        let qrpaySupportedBankList = [];
        if (qrpaySupportedBankCache[bankObj.code]) {
            qrpaySupportedBankList = qrpaySupportedBankCache[bankObj.code];
        } else {
            // get and cache the list
            if (depositAllSettings.depositJson && depositAllSettings.depositJson.QrSupportedBank && depositAllSettings.depositJson.QrSupportedBank[bankObj.code]) {
                let oriSupportedBank = depositAllSettings.depositJson.QrSupportedBank[bankObj.code];
                for (let i = 0; i < oriSupportedBank.length; i++) {
                    let bankObj = oriSupportedBank[i];
                    bankObj.bankName = window.SPL_Transaction.getBankName(bankObj, window.merchantCode);
                    qrpaySupportedBankList.push(bankObj);
                }

                // append dummy object to make the grid look nice
                if (screen.viewType !== 'mobile' && qrpaySupportedBankList.length % 3 !== 0) {
                    let dummyCount = 3 - (qrpaySupportedBankList.length % 3);
                    for (let i = 0; i < dummyCount; i++) {
                        qrpaySupportedBankList.push({
                            isDummyBank: true,
                        });
                    }
                }
                qrpaySupportedBankCache[bankObj.code] = qrpaySupportedBankList;
            } else {
                qrpaySupportedBankCache[bankObj.code] = [];
            }
            controller.vm.setState({ qrpaySupportedBankCache: qrpaySupportedBankCache });
        }
        controller.vm.setState({ qrpaySupportedBankList: qrpaySupportedBankList });
    },

    toggleQrPayBankList(flag) {
        const { qrpaySupportedBankList } = controller.vm.state;
        if (qrpaySupportedBankList.length > 0) {
            controller.vm.setState({ showQrBankList: flag });
        }
    },

    getCorrectDepositMinMaxLimit(bankObj) {
        const { depositAllSettings, bankList } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };
        let _bankObj = bankObj;
        if (!_bankObj && bankList.length > 0) {
            _bankObj = bankList[0];
        }

        if (bankList.length > 0 && depositAllSettings.depositJson.quickPay[_bankObj.code]) {
            _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[_bankObj.code][currency];
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    submitForm() {
        const { isFormValid, disableDepositButton } = controller.vm.state;

        if (isFormValid && !disableDepositButton) {
            controller.submitQrPayDeposit();
        }
    },

    submitQrPayDeposit() {
        const vm = controller.vm;
        const { bankList, fields, selectedBankIndex, depositSubmitCountdown } = vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = vm.props;
        const { features } = vm.props.portal.settings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }
            let bankObj = bankList[selectedBankIndex];
            let amount = fields.amount;
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let callbackUrl = `/${langPath}/myaccount/deposit`;
            let stateUrl = '';

            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }
            let methodParam = {
                id: bankObj.id,
                code: bankObj ? bankObj.code : null,
                isRedirectToAPP: bankObj && bankObj.isRedirectToApp ? bankObj.isRedirectToApp : null,
            };
            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                }
            });
        }
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'qr' : '';
        controller.vm.setState({ customFilename: customFilename });
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
