import { constSystemFeature } from '../constant';

const initialState = {
    systemFeature: {},
    siteKey: '',
    temp_site_key: '',
};

export function systemFeatureReducer(state = initialState, action) {
    switch (action.type) {
        case constSystemFeature.LOAD_SYSTEM_FEATURE:
            return {
                ...state,
                systemFeature: action.payload,
            };
        case constSystemFeature.FETCH_TURNSTILE_SETTINGS:
            return {
                ...state,
                siteKey: action.payload,
            };
        case constSystemFeature.CLEAR_SITE_KEY:
            return {
                ...state,
                temp_site_key: state.siteKey,
                siteKey: '',
            };
        case constSystemFeature.RESET_SITE_KEY:
            return {
                ...state,
                siteKey: state.temp_site_key,
            };
        default:
            return state;
    }
}
