import core from 'root/utils/core';
import commonUtil from '../../../../../../utils/common-util';
import transactionHelper from '../../../../../../utils/transaction-helper';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { scrollToDepositStep2 } from '../../deposit-util';

const METHOD_OR_CHANNEL_CODE = 'P2P';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,

            depositMinMaxLimit: { min: 0, max: 0 },
            methodList: [],
            selectedMethodIndex: 0,
            banks: [],
            selectedBankIndex: disablePreselectBank ? null : 0,
            thirdPartyMemberBank: [],

            // should be can put at parent de variables
            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            disableDepositButton: disablePreselectBank ? true : false,
            isFormValid: false,
            selectedMethodCache: {},
            showAmountField: true,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: [],
            depositSubmitCountdown: null,
        };
    },

    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { customCountdown } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'p2pSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, customCountdown, paymentCode, Date.now().valueOf(), { disableDepositButton: true });
                resolve();
            });
        });
    },

    loadDepositDetails() {
        const { filteredDepositApiJson } = controller.vm.state;

        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            controller.newDepositMethod();
        }
    },

    async newDepositMethod() {
        const vm = controller.vm;
        const { filteredDepositApiJson, depositAllSettings } = vm.state;
        const { paymentGatewaySetting } = vm.props;
        let _methodList = [];

        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        for (let channel in filteredDepositApiJson.channel) {
            if (filteredDepositApiJson.channel[channel]) {
                let method = filteredDepositApiJson.channel[channel][0];

                if (method.code === 'MGP2P') {
                    method.displayName = method.displayName ? method.displayName : 'Mango Pay';
                } else if (method.code === 'TP2P') {
                    method.displayName = method.displayName ? method.displayName : 'TPAY';
                } else {
                    method.displayName = method.displayName ? method.displayName : window.SPL_Translation.getBankChannelTranslation(method, 'channelCode');
                }

                // method.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${method.code}.svg`, `${method.code}`);
                _methodList.push(method);
            }
        }

        if (_methodList.length > 0) {
            core.portalIsLoading(vm.props, true);

            const method = _methodList[0];
            const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);

            vm.setState({ methodList: _methodList, showAmountField, isFormValid }, () => {
                controller.setDefaultCheckedValue();
                // controller.getCorrectMinMaxSetting();
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'P2P',
                    controller.vm.props.user.account.currency,
                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex]?.code
                );
                controller.getMemberThirdPartyBank();

                if (depositAllSettings && depositAllSettings.showBankMaintenanceStatus) {
                    controller.processBankMaintenanceInfo();
                }
            });
        }
    },

    setDefaultCheckedValue() {
        const { selectedMethodIndex, methodList } = controller.vm.state;

        if (selectedMethodIndex === 0 && methodList.length > 0) {
            methodList[0].isChecked = true;
        }

        controller.vm.setState({ methodList });
    },

    getCorrectMinMaxSetting() {
        const { depositAllSettings, selectedMethodIndex, methodList } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let methodObj = methodList[selectedMethodIndex];
        let _depositMinMaxLimit = { min: 500, max: 50000 };

        if (methodObj && depositAllSettings.depositJson.quickPay[methodObj.code]) {
            _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[methodObj.code][currency];
        }

        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    getMemberThirdPartyBank() {
        const { methodList, selectedMethodIndex } = controller.vm.state;
        let methodObj = methodList[selectedMethodIndex];

        // temporarily hardcode, TPay dont want to show supported bank list
        if (methodObj.code !== 'TP2P' && methodObj.code !== 'XXPP2P' && methodObj.code !== 'DDPP2P') {
            window.SPL_Transaction.getMemberThirdPartyBank(methodObj.id).then((data) => {
                controller.applySVGForBank(data);
            });
        } else {
            core.portalIsLoading(controller.vm.props, false);
        }
    },

    async applySVGForBank(data) {
        for (let i in data) {
            const bank = data[i];
            bank.accountHolder = bank.accountName;
            // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.bankCode}.svg`, `${bank.bankCode}`);
            bank.displayName = await window.SPL_Transaction.getBankName(bank);
        }

        controller.vm.setState({ thirdPartyMemberBank: data });
        core.portalIsLoading(controller.vm.props, false);
    },

    processBankMaintenanceInfo() {
        let banks = controller.vm.state.banks;

        for (let i = 0; i < banks.length; i++) {
            let bankX = banks[i];

            if (bankX) {
                let regularMaintenance = bankX.regularMaintenance;
                let emergencyMaintenance = bankX.emergencyMaintenance;

                if (
                    regularMaintenance.start !== null &&
                    regularMaintenance.end !== null &&
                    isBankUnderEmergencyMaintenance(new Date(regularMaintenance.start), new Date(regularMaintenance.end))
                ) {
                    bankX.underRegularMaintenance = true;
                }

                if (emergencyMaintenance !== null) {
                    bankX.underEmergencyMaintenance = isBankUnderEmergencyMaintenance(emergencyMaintenance.start, emergencyMaintenance.end);
                }
            }
        }

        function isBankUnderEmergencyMaintenance(start, end) {
            end = null;
            if (start === null) return false;

            let now = new Date();
            let startDate = new Date(start);
            let endDate = end === null ? null : new Date(end);

            if (now.getTime() >= startDate.getTime()) {
                if (endDate === null) return true;

                if (now.getTime() < endDate.getTime()) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },

    onMethodChanged(e) {
        const vm = controller.vm;
        const { paymentGatewaySetting } = vm.props;

        core.portalIsLoading(controller.vm.props, true);
        let methodList = controller.vm.state.methodList;
        let selectedMethodIndex = null;

        if (e && e.target) {
            selectedMethodIndex = e.target.value;
        } else if (e || e === 0) {
            for (let i in methodList) {
                if (i === e) {
                    methodList[i].isChecked = true;
                } else {
                    methodList[i].isChecked = false;
                }
            }
            selectedMethodIndex = e;
        }

        const method = methodList[selectedMethodIndex];
        const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        controller.vm.setState({ selectedMethodIndex, methodList, thirdPartyMemberBank: [], showAmountField, isFormValid }, () => {
            const { selectedMethodIndex, methodList } = controller.vm.state;
            let selectedMethodCache = controller.vm.state.selectedMethodCache;
            let selectedMethodObj = methodList[selectedMethodIndex];

            if (selectedMethodCache[selectedMethodObj.id]) {
                controller.vm.setState(
                    {
                        banks: selectedMethodCache[selectedMethodObj.id],
                        disableDepositButton: disablePreselectBank && !selectedMethodCache[selectedMethodObj.id]?.length ? false : controller.vm.state.disableDepositButton,
                    },
                    () => {
                        controller.getMemberThirdPartyBank();
                    }
                );
            } else {
                let selectedMethodObj = methodList[selectedMethodIndex];

                window.SPL_Transaction.getDepositDetailsOnMethodChange([], selectedMethodObj, controller.vm.props.user.account.currency).then((data) => {
                    // cache the selected new methodlist bank list
                    let banks = data.banks;

                    if (banks === undefined || banks === null) {
                        banks = [];
                    }

                    selectedMethodCache[selectedMethodObj.id] = banks;

                    controller.vm.setState(
                        { selectedMethodCache, banks: banks, disableDepositButton: disablePreselectBank && !banks?.length ? false : controller.vm.state.disableDepositButton },
                        () => {
                            controller.getMemberThirdPartyBank();
                        }
                    );
                });
            }

            // controller.getCorrectMinMaxSetting();
            commonUtil.getDepositMinMax(
                controller.vm,
                'P2P',
                controller.vm.props.user.account.currency,
                controller.vm.state.methodList[controller.vm.state.selectedMethodIndex]?.code
            );
        });
    },

    onBankChanged(e) {
        const { banks, selectedBankIndex: oldSelectedBankIndex } = controller.vm.state;
        let selectedBankIndex = null;

        if (e && e.target) {
            selectedBankIndex = e.target.value;
        } else if (e || e === 0) {
            for (let i in banks) {
                if (i === e) {
                    banks[i].isChecked = true;
                } else {
                    banks[i].isChecked = false;
                }
            }
            selectedBankIndex = e;
        }

        controller.vm.setState({ selectedBankIndex, disableDepositButton: false }, () => {
            const {
                enableScrollToDepositStep2 = false,
                scrollToDepositStep2Attempts = 15,
                scrollToDepositStep2Delay = 200,
            } = controller.vm.props.portal?.settings?.features?.depositSettings || {};
            scrollToDepositStep2(enableScrollToDepositStep2, oldSelectedBankIndex, selectedBankIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
        });
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.P2P]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    submitForm() {
        const vm = controller.vm;
        const { isFormValid, methodList, fields, selectedBankIndex, selectedMethodIndex, thirdPartyMemberBank, depositSubmitCountdown } = controller.vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = controller.vm.props;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let methodObj = methodList[selectedMethodIndex] ? methodList[selectedMethodIndex] : null;
            let bankObj = thirdPartyMemberBank[selectedBankIndex] ? thirdPartyMemberBank[selectedBankIndex] : null;
            let amount = fields.amount;
            let callbackUrl = '/myaccount/deposit';
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let stateUrl = `/${langPath}/quickpayredirect`;
            let methodCode = methodObj.code;
            let methodId = methodObj.id;
            let bank = bankObj && bankObj.code ? bankObj.code : null;
            let methodParam = {
                id: methodId,
                code: methodCode,
            };

            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl, null, bankObj).then(
                (data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                    }
                }
            );
        }
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'p2p' : '';
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
