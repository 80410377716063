import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import LazyComponent from 'root/webapp/shared/lazyComponent';
const DesktopHome = lazy(() => import('./desktop/Home'));
const MobileHome = lazy(() => import('./mobile/index'));

class Home extends React.Component {
    components = {
        desktop: {
            default: DesktopHome,
        },
        mobile: {
            default: MobileHome,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'home', this.props.screen.viewType);
        return LazyComponent(TagName)();
    }
}

export default connect(mapStateToProps, null)(Home);
