export const authSettingsConstant = {
    FETCH_AUTH_SETTINGS: 'FETCH_AUTH_SETTINGS',
    SET_GOOGLE_MEMBER_REGISTERED: 'SET_GOOGLE_MEMBER_REGISTERED',
    SET_MODULE_GOOGLE_REGISTERED: 'SET_MODULE_GOOGLE_REGISTERED',
    SET_SIX_PIN_MEMBER_REGISTERED: 'SET_SIX_PIN_MEMBER_REGISTERED',
    SET_MODULE_SIX_PIN_REGISTERED: 'SET_MODULE_SIX_PIN_REGISTERED',
    SET_SIX_PIN_LOCKED: 'SET_SIX_PIN_LOCKED',
    SET_MODULE_SIX_PIN_LOCKED: 'SET_MODULE_SIX_PIN_LOCKED',
    SET_TURNSTILE_ENABLE: 'SET_TURNSTILE_ENABLE',
};
