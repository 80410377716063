import notification from 'root/utils/notification';
import commonUtil from '../../../utils/common-util';
import core from 'root/utils/core';
import { floatingAction } from 'root/redux/action';
import { gu_getNestedValue } from 'root/utils/general-util';

const controller = {
    vm: null,

    init(vm) {
        vm.state = {
            promotionList: {
                displaypromotionContents: [],
                promotionTypes: [],
                activeTab: 'all',
                count: 0,
            },
            modalVisible: {
                showPromoDetail: [],
                showPromoApply: [],
                showPromoTrans: [],
            },
            promoApplyInput: {
                name: '',
                email: '',
                phone: '',
                telCode: '',
                remark: '',
                address: '',
                telcode: '',
            },
            telCodeOption: [],
            memberTelCode: null,
            disableSubmitBtn: false,
            applyMessage: '',
            merchantLiveChat: '',
            // showRemainingTimer: false,
            supportLinkPromotion: false,
            transferAmount: null,
            userCurrency: '',
            selectedPromoDetail: null,
            promoDetailIsVisible: false,
            promoApplyFormIsVisible: false,
            promoApplyTransferIsVisible: false,
            promoInfo: '',
            promoContent: '',
            fieldsDisplay: {},
            submitInProcess: false,
            mainWallet: null,
            selectedProvider: null,
            selectedProviderImage: null,
            selectedProviderBalance: null,
            redirectLink: null,
            transferSliderMin: 0,
            transferSliderMax: 0,
            targetTurnover: '0.00',
            bonusAmount: '0.00',
            providerList: [],
            providerWalletsList: [], // most likely same to providerList, just this var is for providerWallet fields only
            promoDetails: null,
            promotionId: null,
            transferInProgress: false,
            restoreOnLoad: false,
            providerDropDown: false,
            promotionDisabledField: [],
            categorizedProviderListByMoreType: [],
            itemIndex: 0,
            typeIndex: 0,
            floatingNotificationId: null,
            compSettings: {},
            randomImage: '',
            isLoading: false,
        };

        controller.vm = vm;
    },

    initCompSettings() {
        return new Promise((resolve) => {
            let compSettings = {
                enableCNYTheme: gu_getNestedValue(controller.vm, 'props.portal.settings.features.enableCNYTheme'),
            };

            controller.vm.setState({ compSettings: compSettings }, () => {
                resolve();
            });
        });
    },

    getPromotionContent(filter, flag) {
        let disableAutoPopupFlag = false;

        if (flag) {
            disableAutoPopupFlag = true;
        }

        const { language, screen } = controller.vm.props;

        controller.vm.setState({ isLoading: true });

        window.SPL_Content.getPromotionContent(language.countryLanguageKey, filter, screen.viewType, true).then((data) => {
            const { user } = controller.vm.props;
            let allCategory = {
                promotionType: 'ALL',
                desc: 'promotion:promotion.category.showall',
            };

            data.promotionTypes.unshift(allCategory);

            for (let i = 0; i < data.promotionTypes.length; i++) {
                let desc = data.promotionTypes[i].desc;

                switch (desc) {
                    case 'SPECIAL':
                        data.promotionTypes[i].desc = 'Special';
                        break;

                    case 'SLOTS':
                        data.promotionTypes[i].desc = 'Slots';
                        break;

                    case 'NEW MEMBER':
                        data.promotionTypes[i].desc = 'New Member';
                        break;

                    case 'SPORTSBOOK':
                        data.promotionTypes[i].desc = 'Sportsbook';
                        break;

                    case 'CASINO':
                        data.promotionTypes[i].desc = 'Casino';
                        break;
                    default:
                        break;
                }

                if (data.promotionTypes[i].promotionType && data.promotionTypes[i].promotionType.toUpperCase() === filter.toUpperCase()) {
                    data.promotionTypes[i].tabActive = true;
                } else {
                    data.promotionTypes[i].tabActive = false;
                }

                window.SPL_Content.getPromotionContent(language.countryLanguageKey, data.promotionTypes[i].promotionType, screen.viewType, true).then((index) => {
                    Object.assign(data.promotionTypes[i], { count: index.displaypromotionContents.length });
                });
            }

            window.SPL_Content.getPortalSettings().then((setting) => {
                for (let i = 0; i < data.displaypromotionContents.length; i++) {
                    let promoType = data.displaypromotionContents[i].promotionType.split(','),
                        color = '#ff4e00',
                        color2 = '#ff4e00';

                    if (promoType) {
                        // switch (promoType[0]) {
                        //   case 'SPECIAL':
                        //     color = '#00a1ff';
                        //     color2 = '#0064b7';
                        //     break;

                        //   case 'SLOTS':
                        //     color = '#eb1c21';
                        //     color2 = '#b7171d';
                        //     break;

                        //   case 'VIP':
                        //     color = '#00a1ff';
                        //     color2 = '#0064b7';
                        //     break;

                        //   case 'NEW_MEMBER':
                        //     color = '#00a1ff';
                        //     color2 = '#0064b7';
                        //     break;

                        //   case 'SPORTSBOOK':
                        //     color = '#01a1ff';
                        //     color2 = '#0064b7';
                        //     break;

                        //   case 'CASINO':
                        //     color = '#cca700';
                        //     color2 = '#967a00';
                        //     break;

                        //   default:
                        //     color = '#ff4e00';
                        //     color2 = '#ff2300';
                        //     break;
                        // }

                        Object.assign(data.displaypromotionContents[i], { line: color, darkColor: color2 });
                    }
                }

                // if (setting['showPromotionRemainingTimer']) {
                // controller.vm.setState({ showRemainingTimer: true });
                let currentDate = new Date();
                let chkProviderWalletField = false;

                for (let i = 0; i < data.displaypromotionContents.length; i++) {
                    if (data.displaypromotionContents[i] && data.displaypromotionContents[i].isRemainingTime) {
                        let endDate = data.displaypromotionContents[i].endDate;
                        let remainingTime = endDate === null ? null : new Date(endDate);

                        if (remainingTime === null || remainingTime === '') {
                            data.displaypromotionContents[i].remainingUnlimited = true;
                        } else {
                            var days = (remainingTime - currentDate) / 1000 / 3600 / 24;
                            var hours = (days - Math.floor(days)) * 24;
                            data.displaypromotionContents[i].remainingUnlimited = false;
                            data.displaypromotionContents[i].remainingDays = Math.floor(days);
                            data.displaypromotionContents[i].remainingHours = Math.ceil(hours);
                        }
                    }

                    // if detect the formFields contain provider_wallet, then call provider_wallet api
                    // provider_wallet => optionalFields, Note: required enable in merchant_settings
                    if (
                        user.isLogin &&
                        !chkProviderWalletField &&
                        data.displaypromotionContents[i].formFields &&
                        data.displaypromotionContents[i].formFields.includes('PROVIDER_WALLET')
                    ) {
                        chkProviderWalletField = true;
                        controller.getAllProviderWallets();
                    }
                }

                controller.vm.setState({ promotionList: data }, () => {
                    controller.imageRandom();
                });
                // } else {
                //   controller.vm.setState({ promotionList: data });
                // }

                if (disableAutoPopupFlag === false) {
                    controller.showPromotionInfoBasedOnId();
                }
            });
        });
    },

    // CNY image for promotion start
    generateRandomImagePattern(length) {
        const pattern = [];
        for (let i = 0; i < length; i++) {
            pattern.push(Math.round(Math.random())); // Generates either 0 or 1 randomly
        }
        return pattern;
    },

    imageRandom() {
        const { screen } = controller.vm.props;
        const { displaypromotionContents } = controller.vm.state.promotionList;
        const itemsPerRow = screen.viewType === 'mobile' ? 1.5 : 3;
        const dividedByThree = Math.floor(displaypromotionContents.length / itemsPerRow);

        const imageOptions = controller.generateRandomImagePattern(dividedByThree);
        const imagePaths = ['/public/html/images/cny/redlantern.png', '/public/html/images/cny/goldcoin.png'];
        const selectedImages = [];

        let topPosition = 0;

        const getRandomRotation = () => Math.floor(Math.random() * 60);
        const getRandomHeight = () => {
            const min = screen.viewType === 'mobile' ? 30 : 60;
            const max = screen.viewType === 'mobile' ? 60 : 80;

            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Always add red lantern first on right side with custom size
        selectedImages.push({
            src: imagePaths[0], // Red lantern
            style: {
                position: 'absolute',
                top: screen.viewType === 'mobile' ? '-1.2rem' : '-1.5rem',
                right: screen.viewType === 'mobile' ? '-1.5rem' : '-2.2rem',
                transform: 'none',
                height: screen.viewType === 'mobile' ? '130px' : '250px',
            },
        });

        // Continue with remaining images
        for (let index = 1; index < imageOptions.length; index++) {
            const isEvenIndex = index % 2 === 0;
            const currentImage = imagePaths[imageOptions[index]];
            const isLantern = currentImage.includes('lantern');
            const positionStyle = isEvenIndex
                ? { right: screen.viewType === 'mobile' ? (isLantern ? '-0.5rem' : '-10px') : isLantern ? '-0.5rem' : '-50px' }
                : { left: screen.viewType === 'mobile' ? (isLantern ? '-0.5rem' : '-10px') : isLantern ? '-0.5rem' : '-50px' };
            const rotation = getRandomRotation();
            const height = getRandomHeight();

            const adjustedHeight = isLantern ? (screen.viewType === 'mobile' ? height * 1.5 : height) : height;
            topPosition += screen.viewType === 'mobile' ? 13 : 455;

            selectedImages.push({
                src: imagePaths[1],
                style: {
                    position: 'absolute',
                    top: `${topPosition}${screen.viewType === 'mobile' ? 'rem' : 'px'}`,
                    ...positionStyle,
                    transform: `rotate(${rotation}deg)`,
                    height: `${adjustedHeight}px`,
                },
            });
        }

        controller.vm.setState({ randomImage: selectedImages, isLoading: false });
    },
    // CNY image for promotion end

    showPromotionInfoBasedOnId() {
        const { displaypromotionContents } = controller.vm.state.promotionList;
        let urlParam = window.SPL_Content.parse_query_string();
        if (urlParam && urlParam.promoId) {
            let promoIndex = null;
            let promoId = urlParam.promoId;

            for (let i = 0; i < displaypromotionContents.length; i++) {
                if (displaypromotionContents[i].parentId === null && String(displaypromotionContents[i].id) === promoId) {
                    promoIndex = i;
                    break;
                } else if (displaypromotionContents[i].parentId !== null && String(displaypromotionContents[i].parentId) === promoId) {
                    promoIndex = i;
                    break;
                }
            }
            if (promoIndex !== null) {
                controller.toggleModal('showPromoDetails', displaypromotionContents[promoIndex]);
            }
        }
    },

    getMerchantLiveChat() {
        let params = {
            language: controller.vm.props.language.countryLanguageKey,
        };

        window.SPL_Content.getMerchantContentLiveChat(params).then((res) => {
            controller.vm.setState({ merchantLiveChat: res.data });
        });
    },

    getSupportLinkPromotionEnabled() {
        window.SPL_Content.checkSupportLinkPromotionEnabled().then((data) => {
            controller.vm.setState({ supportLinkPromotion: data.data });
        });
    },

    onTabChange(type) {
        controller.getPromotionContent(type, true);
        controller.vm.setState({ activeTab: type });
    },

    toggleModal(field, detail, index) {
        const { user, screen, history } = controller.vm.props;
        const { merchantLiveChat, supportLinkPromotion, floatingNotificationId } = controller.vm.state;
        const { storeNotificationId } = controller.vm.props.floatingNotification;
        let t = controller.vm.props.t;
        let currency = '';

        if (field === 'showPromoApply') {
            if (!user.isLogin) {
                notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                return;
            }

            if (commonUtil.getIsZT828LiteApp()) {
                window.SPL_LiteApp.onPopOutToggle(true);
            }

            if (user.account) {
                currency = user.account.currency;
                controller.vm.setState({ userCurrency: currency });
            }

            const proceedPromotionProcess = async (promotionData) => {
                await controller.getMainWallet();
                let details = promotionData;
                let categorizedProviderList = null;

                const { transferAmount, mainWallet } = controller.vm.state;
                const min = mainWallet?.balance > details?.minBonus ? details?.minBonus : mainWallet?.balance;
                const rate = details?.promotion?.rate;
                const targetMultiplier = details?.promotion?.targetMultiplier;
                const maxDeposit = controller.rounding(details?.maxBonus / rate);
                const bonus = controller.rounding(transferAmount * rate);
                const turnover = controller.rounding((parseFloat(transferAmount) + parseFloat(bonus)) * targetMultiplier);
                const max = mainWallet?.balance > maxDeposit ? maxDeposit : mainWallet?.balance;

                let providerList = [];
                let promotionId = null;
                let foundDefaultTypeIndex = false;
                let newTypeIndex = 0;

                if (details?.promotion?.providers) {
                    providerList = details.promotion.providers;
                    promotionId = details.promotion.id;

                    if (providerList.length > 0) {
                        controller.handleProviderSelect(providerList[0]);

                        const categorizedProviderListByMoreType = await window.SPL_Provider.categoriseProviderByMoreType(
                            providerList,
                            controller.vm.props.language.currencyLang,
                        );

                        categorizedProviderListByMoreType.forEach((category, i) => {
                            switch (category.name) {
                                case 'sports':
                                    category.title = t('global:global.menu.sportsbook2');
                                    break;
                                case 'casino':
                                    category.title = t('global:global.menu.live_casino');
                                    break;
                                case 'slots':
                                    category.title = t('global:global.menu.slot2');
                                    break;
                                case 'fishing':
                                    category.title = t('global:global.menu.fishing2');
                                    break;
                                case 'esports':
                                    category.title = t('global:global.menu.esport2');
                                    break;
                                case 'pokers':
                                    category.title = t('global:global.menu.poker2');
                                    break;
                                case 'lottery':
                                    category.title = t('global:global.menu.lottery2');
                                    break;
                                case 'threeD':
                                    category.title = t('global:global.menu.games2');
                                    break;
                                case 'fastgames':
                                    category.title = t('global:global.menu.fastgames');
                                    break;
                                case 'others':
                                    category.title = t('settings:settings.mywallet', 'My Wallet');
                                    break;
                                default:
                                    break;
                            }

                            if (category.list && category.list.length > 0 && !foundDefaultTypeIndex) {
                                foundDefaultTypeIndex = true;
                                newTypeIndex = i;
                            }
                        });

                        const filterCategorizedProviderListByMoreType = categorizedProviderListByMoreType.filter((item) => item?.list?.length > 0);

                        const newState = {
                            categorizedProviderListByMoreType: filterCategorizedProviderListByMoreType,
                            typeIndex: newTypeIndex,
                            transferSliderMin: min,
                            transferSliderMax: max,
                            targetTurnover: turnover.toFixed(2),
                            bonusAmount: bonus.toFixed(2),
                            providerList,
                            categorizedProviderList: categorizedProviderList,
                            promoDetails: details,
                            promotionId,
                            promoApplyTransferIsVisible: true,
                            selectedPromoDetail: detail,
                            promoInfo: detail,
                        };

                        controller.vm.setState(newState);
                    }
                }
            };

            const handlePromotion = async (actionType) => {
                const promotionData = await window.SPL_Content.getPromotion(detail.promotionId, currency, controller.vm.props);

                switch (actionType) {
                    case 'REDIRECT':
                        if (promotionData.id) {
                            proceedPromotionProcess(promotionData);
                        } else {
                            history.push('/myaccount/transfer');
                        }
                        break;
                    case 'PROMO':
                        proceedPromotionProcess(promotionData);
                        break;

                    default:
                        break;
                }
            };

            switch (detail.applyAction) {
                case 'REDIRECT':
                    if (supportLinkPromotion) {
                        handlePromotion('REDIRECT');
                    } else {
                        history.push('/myaccount/transfer');
                    }

                    break;

                case 'PROMO':
                    handlePromotion('PROMO');

                    break;

                case 'DIRECT':
                    controller.vm.setState({ submitInProcess: true });
                    let date = new Date();
                    let params = {
                        createdDate: date,
                        status: 'P',
                        memberId: user.account.id,
                        promotionId: detail.id,
                        phone: user.account.phone,
                        name: user.account.name,
                        remark: null,
                        address: null,
                        email: user.account.email,
                        language: detail.language,
                        floatingNotificationId: floatingNotificationId ? floatingNotificationId : storeNotificationId,
                    };
                    window.SPL_Member.applyPromotion(params).then((data) => {
                        if (data.success) {
                            controller.vm.setState({ submitInProcess: false, applyMessage: 'promotion:promotion.successMsg' });
                            notification.showNotification('success', t(controller.vm.state.applyMessage));
                            controller.vm.props.dispatch(floatingAction.storeFloatingNotificationId(null));
                        } else {
                            if (data.errorMsg === 'Apply promotion content failed : promotion:promotion.exceeditLimit') {
                                controller.vm.setState({ submitInProcess: false, applyMessage: `transaction:transaction.deposit.${data.errorKey}` });
                            } else {
                                controller.vm.setState({ submitInProcess: false, applyMessage: data.errorMsg });
                            }

                            notification.showNotification('error', t(controller.vm.state.applyMessage));
                        }
                    });

                    break;

                case 'FORM':
                    controller.vm.setState({
                        promoApplyFormIsVisible: true,
                        promoApplyTransferIsVisible: false,
                        selectedPromoDetail: detail,
                        promoInfo: detail,
                    });

                    break;
                case 'DIRECT':
                    controller.vm.setState(
                        {
                            selectedPromoDetail: detail,
                        },
                        () => {
                            controller.directApply();
                        },
                    );

                    break;

                case 'CONTACT_CS':
                    if (detail.isContactCSUrlNewPage) {
                        if (detail.contactCSUrlType === 'N') {
                            detail.contactCSUrl && window.open(detail.contactCSUrl);
                        } else {
                            merchantLiveChat?.href && window.open(merchantLiveChat.href);
                        }
                    } else {
                        const popupPosition = screen.width - 625 < 0 ? 0 : screen.width - 625;
                        if (detail.contactCSUrlType === 'N') {
                            detail.contactCSUrl &&
                                window.open(
                                    detail.contactCSUrl,
                                    `${window.globMerchantName} 24/7 Live Chat - Pre-Chat Window`,
                                    `height=600, width=600, top=25, left=${popupPosition}`,
                                );
                        } else {
                            merchantLiveChat?.href &&
                                window.open(
                                    merchantLiveChat.href,
                                    `${window.globMerchantName} 24/7 Live Chat - Pre-Chat Window`,
                                    `height=600, width=600, top=25, left=${popupPosition}`,
                                );
                        }
                    }

                    break;

                default:
                    break;
            }
        } else if (field === 'showPromoDetails') {
            if (detail) {
                controller.vm.setState({ promoDetailIsVisible: true, promoInfo: detail, index: index }, () => {
                    window.SPL_Content.loadPromotionContent(detail.content).then((data) => {
                        controller.vm.setState({ promoContent: data.content });
                    });
                });

                if (commonUtil.getIsZT828LiteApp()) {
                    window.SPL_LiteApp.onPopOutToggle(true);
                }
            } else {
                controller.vm.setState({ promoDetailIsVisible: false, promoInfo: '', promoContent: '', index: '' });
            }
        } else {
            controller.vm.setState({
                promoDetailIsVisible: false,
                promoInfo: '',
                promoContent: '',
                index: '',
                promoApplyFormIsVisible: false,
                promoApplyTransferIsVisible: false,
                selectedPromoDetail: null,
                transferAmount: null,
            });

            if (commonUtil.getIsZT828LiteApp()) {
                window.SPL_LiteApp.onPopOutToggle(false);
            }
        }
    },

    getCountryInfo() {
        return new Promise((resolve) => {
            const { user } = controller.vm.props;
            window.SPL_Content.getCountryInfo().then((telCodeList) => {
                let telCodeOption = [];
                let targetCurrency = user?.account?.currency;

                if (targetCurrency === 'KHUSD') {
                    targetCurrency = 'USD';
                }

                for (let i = 0; i < telCodeList.length; i++) {
                    telCodeOption.push(telCodeList[i].telCode);
                }

                let memberTelCode = telCodeList.find((e) => e.currency === targetCurrency).telCode;

                controller.vm.setState({ telCodeOption, memberTelCode: memberTelCode });
                resolve();
            });
        });
    },

    getApplyFormField() {
        const { formFields } = controller.vm.state.selectedPromoDetail;
        const { account } = controller.vm.props.user;
        const { memberTelCode, providerWalletsList, promoContent } = controller.vm.state;

        let loginUser = null;

        if (account) {
            loginUser = account;
        }

        let formatedFormField = formFields.split(',');
        let fieldsDisplay = {
            showName: false,
            showEmail: false,
            showPhone: false,
            showRemark: false,
            showAddress: false,
            showBetId: false,
            showScorePrediction: false,
            showEWallet: false,
            showProviderWallet: false,
            showShippingAddress: false,
            showReceiptImage: false,
        };
        let promoApplyInput = {};

        window.SPL_Content.getPortalSettings().then((settings) => {
            let promotionDisabledField = settings.promotionDisabledField;
            if (!promotionDisabledField) {
                promotionDisabledField = [];
            }
            for (let i = 0; i < formatedFormField.length; i++) {
                switch (formatedFormField[i]) {
                    case 'NAME':
                        fieldsDisplay.showName = true;
                        promoApplyInput['name'] = loginUser ? loginUser.name : '';
                        break;

                    case 'EMAIL':
                        fieldsDisplay.showEmail = true;
                        promoApplyInput['email'] = loginUser ? loginUser.email : '';
                        break;

                    case 'PHONE':
                        fieldsDisplay.showPhone = true;
                        let phone = '';
                        let telCode = '';
                        let splitLength = null;

                        if (loginUser && loginUser.phone) {
                            splitLength = loginUser.phone.startsWith('+') ? memberTelCode.length + 1 : memberTelCode.length;
                            phone = loginUser.phone.replace(/\s/g, '').substring(splitLength);
                            telCode = loginUser.phone.substring(0, splitLength);
                        }

                        promoApplyInput['phone'] = phone;
                        promoApplyInput['telCode'] = telCode;
                        break;

                    case 'REMARK':
                        fieldsDisplay.showRemark = true;
                        promoApplyInput['remark'] = '';
                        break;

                    case 'ADDRESS':
                        fieldsDisplay.showAddress = true;
                        promoApplyInput['address'] = '';
                        break;

                    case 'SHIPPING_ADDRESS':
                        fieldsDisplay.showShippingAddress = true;
                        promoApplyInput['shippingAddress'] = '';
                        break;

                    case 'BET_TICKET_ID':
                        fieldsDisplay.showBetId = true;
                        promoApplyInput['betId'] = '';
                        break;

                    case 'SCORE_PREDICTION':
                        fieldsDisplay.showScorePrediction = true;
                        promoApplyInput['scorePrediction'] = '';
                        break;

                    case 'E_WALLET':
                        fieldsDisplay.showEWallet = true;
                        promoApplyInput['ewallet'] = '';
                        break;

                    case 'PROVIDER_WALLET':
                        fieldsDisplay.showProviderWallet = true;
                        if (providerWalletsList && providerWalletsList.length > 0) {
                            promoApplyInput['providerWallet'] = providerWalletsList[0].name;
                        }
                        break;

                    case 'IMAGE':
                        fieldsDisplay.showReceiptImage = true;
                        promoApplyInput['receiptImagePath'] = promoContent && promoContent.image ? promoContent.image : '';
                        promoApplyInput['receiptImageFileToUpload'] = null;
                        break;

                    default:
                        break;
                }
            }

            controller.vm.setState({ fieldsDisplay, promoApplyInput, promotionDisabledField }, () => {
                controller.handleValidation();
            });
        });
    },

    handleValidation() {
        const { fieldsDisplay } = controller.vm.state;
        const { name, phone, email, remark, address, shippingAddress, betId, scorePrediction, ewallet, providerWallet, receiptImageFileToUpload } =
            controller.vm.state.promoApplyInput;
        let disableSubmitBtn = false;

        if (fieldsDisplay.showName && (name === null || name === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showPhone && (phone === null || phone === '' || !phone.match(/^\d+$/))) {
            disableSubmitBtn = true;
        }

        if (
            fieldsDisplay.showEmail &&
            (email === null ||
                email === '' ||
                !email.match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ))
        ) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showRemark && (remark === null || remark === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showAddress && (address === null || address === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showShippingAddress && (shippingAddress === null || shippingAddress === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showBetId && (betId === null || betId === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showScorePrediction && (scorePrediction === null || scorePrediction === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showEWallet && (ewallet === null || ewallet === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showProviderWallet && (providerWallet === null || providerWallet === '')) {
            disableSubmitBtn = true;
        }

        if (fieldsDisplay.showReceiptImage) {
            const accept = controller.vm.props.portal.settings?.features?.promotionSettings?.uploadImageType;
            const uploadImageType = receiptImageFileToUpload?.type;

            if (!receiptImageFileToUpload || (accept?.length && !accept.some((type) => uploadImageType.includes(type))) || !/^image\//.test(uploadImageType)) {
                controller.vm.setState({
                    submitInProcess: false,
                });

                disableSubmitBtn = true;
            }
        }
        controller.vm.setState({ disableSubmitBtn: disableSubmitBtn });
    },

    handleApplySubmit() {
        const { user } = controller.vm.props;
        const { selectedPromoDetail } = controller.vm.state;
        const { name, phone, email, remark, address, telCode, shippingAddress, betId, scorePrediction, ewallet, providerWallet, receiptImageFileToUpload } =
            controller.vm.state.promoApplyInput;
        let t = controller.vm.props.t;

        controller.vm.setState({ submitInProcess: true });

        let optionalFields = {
            shippingAddress: shippingAddress,
            betTicketId: betId,
            scorePrediction: scorePrediction,
            eWallet: ewallet,
            providerWallet: providerWallet,
        };

        let date = new Date();
        let params = {
            createdDate: date,
            status: 'P',
            memberId: user.account.id,
            promotionId: selectedPromoDetail.id,
            phone: telCode + phone,
            name: name,
            remark: remark,
            address: address,
            email: email,
            language: selectedPromoDetail.language,
            optionalFieldsJson: JSON.stringify(optionalFields),
            receiptImagePath: '',
            receiptImageFileToUpload,
        };

        window.SPL_Member.applyPromotion(params).then((data) => {
            if (data.success) {
                controller.vm.setState({ submitInProcess: false, applyMessage: 'promotion:promotion.successMsg' }, () => {
                    controller.alertMsg('success');
                    controller.toggleModal('');
                });
            } else {
                if (data.errorMsg === 'Apply promotion content failed : promotion:promotion.exceeditLimit') {
                    controller.vm.setState({ submitInProcess: false, applyMessage: 'promotion:promotion.exceeditLimit' }, () => {
                        controller.alertMsg();
                    });
                } else {
                    controller.vm.setState({ submitInProcess: false, applyMessage: data.errorMsg }, () => {
                        controller.alertMsg();
                    });
                }
            }
            controller.vm.setState((prev) => ({ ...prev, submitInProcess: false }));
        });
    },

    alertMsg(type) {
        let t = controller.vm.props.t,
            status = 'error';

        if (type === 'success') {
            status = 'success';
        }

        notification.showNotification(status, t(controller.vm.state.applyMessage));
    },

    getMainWallet() {
        controller.vm.setState({ restoreOnLoad: true });

        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    controller.vm.setState({ mainWallet: mainWallet.data, restoreOnLoad: false }, () => {
                        controller.loadAsyncProviderWallet();
                        resolve(mainWallet.data);
                    });
                })

                .catch((err) => {
                    controller.vm.setState({ restoreOnLoad: true });
                    console.log(err);
                });
        });
    },

    loadAsyncProviderWallet() {
        window.SPL_Provider.loadAsyncProviderWallet(null, controller.vm.props).then((providerWallet) => {
            let providerList = controller.vm.state.providerList;

            // get available providers from sync (FE+BE provider wallets)
            let filterProviders = providerList.filter((e) => {
                return providerWallet.providerList.some((b) => e.provider === b.provider);
            });
            for (let i = 0; i < filterProviders.length; i++) {
                let filterProvider = filterProviders[i];
                filterProvider.balance = filterProvider.balance ? filterProvider : 0;
                filterProvider.isdisplay = true;
                filterProvider.serverIsUnderMaintenance = filterProvider.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
            }

            controller.vm.setState({ providerList: filterProviders });
        });
    },

    handleTypeChange(i) {
        controller.vm.setState({
            typeIndex: i,
            itemIndex: 0,
        });
    },

    handleProviderSelect(e, i = null) {
        let providerObj = e.target && e.target.customObj ? e.target.customObj : e;
        let selectedIndex = e.target && e.target.value ? e.target.value : 0;
        selectedIndex = i ? i : selectedIndex;
        let providerCode = providerObj.provider;
        let img = `/public/images/promotion-transfer/${providerCode}.png`;
        let balance = providerObj.balance;
        let redirect = null;

        switch (providerCode) {
            case 'AG2':
                redirect = '/casino/asia-gaming';
                break;
            case 'MEGA':
                redirect = '/mega888';
                break;
            case 'MGP':
                redirect = '/slots/microGamingPlus';
                break;
            case 'SG':
                redirect = '/slots/spadeGaming';
                break;
            case 'SA':
                redirect = '/casino/sa-gaming';
                break;
            case 'SCR2':
                redirect = '/918kiss';
                break;
            case 'PT2':
                redirect = '/slots/playTech';
                break;
            case 'EBET':
                redirect = '/casino/ebet';
                break;
            case 'TTG':
                redirect = '/slots/toptrendgaming';
                break;
            case 'UL':
                redirect = '/ultimate-slots';
                break;
            case 'GP':
                redirect = '/slots/gameplay';
                break;
            case 'AB':
                redirect = '/casino/allbet';
                break;
            case 'GD':
                redirect = '/casino/gold-deluxe';
                break;
            case 'QQK':
                redirect = '/qqkeno';
                break;
            case 'DG':
                redirect = '/casino/dream-gaming';
                break;
            case 'UG':
            case 'AES':
                redirect = '/casino/sexy-baccarat';
                break;
            case 'AES2':
                redirect = '/casino/sexy-baccarat2';
                break;
            case 'CMD':
                redirect = '/cmd368';
                break;
            case 'BR':
                redirect = '/virtualsports-landing';
                break;
            case 'IDN':
                redirect = '/poker';
                break;
            case 'SBO':
            case 'SBO2':
                redirect = '/sbo';
                break;
            case 'EVO':
                redirect = '/casino/evolution-gaming';
                break;
            case 'PG':
                redirect = '/slots/playNGo';
                break;
            case 'PP':
                redirect = '/slots/pragmaticPlay';
                break;
            case 'UFA':
                redirect = '/ufa';
                break;
            case 'WM':
                redirect = '/casino/wm';
                break;
            case 'W':
                redirect = '/casino/w';
                break;
            case 'YBL':
                redirect = '/casino/ybl';
                break;

            default:
                redirect = '/';
                break;
        }

        controller.vm.setState({
            selectedProvider: providerCode,
            selectedProviderImage: img,
            selectedProviderBalance: balance,
            redirectLink: redirect,
            itemIndex: selectedIndex,
        });
    },

    restoreWallet() {
        controller.vm.setState({ restoreOnLoad: true });

        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    controller.getMainWallet();
                    controller.vm.setState({ restoreOnLoad: false });
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    controller.getMainWallet();
                    controller.vm.setState({ restoreOnLoad: false });
                }, 5000);
            });
    },

    toogleDropdown() {
        controller.vm.setState({ providerDropDown: !controller.vm.state.providerDropDown });
    },

    handleFieldChanged(e, field) {
        switch (field) {
            case 'transferAmount':
                let transferAmount = e.target.value;
                const { transferSliderMax, promoDetails } = controller.vm.state;

                if (transferAmount === '' || transferAmount < 0) {
                    transferAmount = null;
                } else if (Number(transferAmount) >= transferSliderMax) {
                    transferAmount = transferSliderMax.toString();
                }

                let rate = promoDetails.promotion.rate;
                let targetMultiplier = promoDetails.promotion.targetMultiplier;
                let bonus = controller.rounding(transferAmount * rate);
                let turnover = controller.rounding((parseFloat(transferAmount) + parseFloat(bonus)) * targetMultiplier);

                let transferRangeHtml = document.getElementById('transfer-range');
                let percentage = (transferAmount / transferSliderMax) * 100;
                if (transferRangeHtml) {
                    transferRangeHtml.style.background = 'linear-gradient(to right, #bcbcbc 0%, #bcbcbc ' + percentage + '%, #ff4e00 ' + percentage + '%, #ff4e00 100%)';
                }

                controller.vm.setState({
                    transferAmount: transferAmount,
                    targetTurnover: turnover.toFixed(2),
                    bonusAmount: bonus.toFixed(2),
                });
                break;

            case 'receiptImageFileToUpload':
                controller.vm.state.promoApplyInput['receiptImageFileToUpload'] = e.target.files[0];
                const accept = controller.vm.props.portal.settings?.features?.promotionSettings?.uploadImageType;
                if (accept?.length) {
                    const uploadImageType = controller.vm.state.promoApplyInput['receiptImageFileToUpload'].type;
                    let isValid = false;
                    for (let i = 0; i < accept.length; i++) {
                        if (uploadImageType.includes(accept[i])) {
                            isValid = true;
                            break;
                        }
                    }

                    if (!isValid) {
                        const errorKey = 'receiptInvalid';
                        const message = `transaction:transaction.deposit.${errorKey}`;
                        controller.vm.setState({
                            applyMessage: message,
                            promoApplyInput: { ...controller.vm.state.promoApplyInput, receiptImageFileToUpload: null },
                        });

                        notification.showNotification('error', controller.vm.props.t(message));
                    }
                }
                break;
            default:
                let promoApplyInput = controller.vm.state.promoApplyInput;
                promoApplyInput[field] = e.target.value;
                if (field === 'receiptImageFileToUpload') {
                    promoApplyInput['receiptImageFileToUpload'] = e.target.files[0];
                }
                controller.vm.setState({ promoApplyInput });
        }

        controller.handleValidation();
    },

    rounding(amount) {
        return Math.round(amount * 100) / 100;
    },

    handleRequestTransfer() {
        const { transferAmount, promotionId, selectedProvider, transferSliderMin, transferSliderMax, transferInProgress } = controller.vm.state;
        const { t } = controller.vm.props;

        if (
            transferInProgress === false &&
            transferAmount !== '' &&
            promotionId !== '' &&
            selectedProvider !== '' &&
            transferSliderMin <= transferAmount &&
            transferAmount <= transferSliderMax
        ) {
            controller.vm.setState({ transferInProgress: true });

            window.SPL_Transaction.requestTransfer(transferAmount, promotionId, 'Member Deposit', selectedProvider, null).then((data) => {
                if (data.status === 'F') {
                    notification.showNotification('error', t(data.msg));
                    controller.vm.setState({ transferInProgress: false });
                } else {
                    controller.toggleModal('showPromoApply');
                }
            });
        }
    },

    directApply() {
        const { account } = controller.vm.props.user;

        let loginUser = null;

        if (account) {
            loginUser = account;
        }

        let promoApplyInput = {};
        promoApplyInput['name'] = loginUser ? loginUser.name : '';
        promoApplyInput['email'] = loginUser ? loginUser.email : '';
        let phone = '';
        let telCode = '';

        if (loginUser && loginUser.phone) {
            phone = loginUser.phone.substring(3);
            telCode = loginUser.phone.substring(0, 3);
        }

        promoApplyInput['phone'] = phone;
        promoApplyInput['telCode'] = telCode;
        promoApplyInput['remark'] = '';
        promoApplyInput['address'] = '';
        controller.vm.setState({ promoApplyInput }, () => {
            controller.handleApplySubmit();
        });
    },

    getAllProviderWallets() {
        window.SPL_Provider.getAllProviderWalletList(null, false, controller.vm.props).then((data) => {
            if (data && data.providerList) {
                core.portalIsLoading(controller.vm.props, true);
                let providerWalletsList = data.providerList.filter((e) => e.provider);
                controller.vm.setState(
                    {
                        providerWalletsList: providerWalletsList,
                    },
                    () => {
                        core.portalIsLoading(controller.vm.props, false);
                    },
                );
            }
        });
    },

    getFloatNotificationId() {
        const { location, history } = controller.vm.props;

        if (location && location.search) {
            let currentUrlParams = new URLSearchParams(location.search);
            let floatingNotificationId = currentUrlParams.get('floatingNotificationId');

            if (floatingNotificationId) {
                controller.vm.setState({ floatingNotificationId: parseInt(floatingNotificationId) }, () => {
                    history.replace();
                });
            }
        }
    },
};

export default controller;
