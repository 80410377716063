import { bannerConstants } from '../constant/banner.constant';

const initialState = {
    productBanner: {
        isLoading: false,
        error: {},
        data: {},
    },
};

export function bannerReducer(state = initialState, action) {
    switch (action.type) {
        case bannerConstants.GET_PRODUCT_BANNER_PENDING:
            return {
                ...state,
                productBanner: {
                    ...state.productBanner,
                    isLoading: true,
                },
            };

        case bannerConstants.GET_PRODUCT_BANNER_FULFILL:
            return {
                ...state,
                productBanner: {
                    ...state.productBanner,
                    isLoading: false,
                    data: action.payload,
                },
            };

        case bannerConstants.GET_PRODUCT_BANNER_FAILED:
            return {
                ...state,
                productBanner: {
                    ...state.productBanner,
                    isLoading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
}
