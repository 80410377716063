import React, { useState } from 'react';

export const ImageComponent = ({ 
  src,
  fallbackSrc,
  defaultSrc="/public/html/images/default-image.webp",
  maxAttempts = 3,
  alt="Image",
  loading = 'lazy',
  ...restProps
}) => {
    const [currentSrc, setCurrentSrc] = useState(src);
    const [attempt, setAttempt] = useState(0);

    React.useEffect(() => {
      if (attempt === 1 && fallbackSrc) { 
        setCurrentSrc(fallbackSrc); // fallbackSrc is used if load image fails 1st
      } else if (attempt === 2) { 
        setCurrentSrc(defaultSrc); // default src is used if load image fails all
      }
    }, [attempt, defaultSrc, fallbackSrc, src])

    const handleError = () => {
      if (src === '') {
        return;
      } else if (attempt < maxAttempts) {
        setAttempt(prevState => prevState + 1);
      }
    };

    return (
      <React.Fragment key={attempt}>
        <img 
          src={currentSrc} 
          onError={handleError}
          alt={alt}
          loading={loading}
          {...restProps}
        />
      </React.Fragment>
    );
};