// react/library
import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// components/files
import SVGBank from 'root/components/svgIcon/svgBank';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../reload.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import commonUtil from '../../../../../../../../utils/common-util';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.initReloadPage();
            vm.getMerchantAndMemberBanks();
            // vm.getCorrectDepositMinMaxLimit();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    clearReceipt() {
        this.setState({
            receiptImg: [],
            receiptImgFile: null,
            isReceiptValid: false,
        });
    }

    onBankClick(bank) {
        this.clearReceipt();
        vm.onBankChanged(bank);
    }

    render() {
        const {
            bankList,
            selectedBankIndex,
            depositAmountOptions,
            depositMinMaxLimit,
            depositAllSettings,
            fields,
            receiptImg,
            errors,
            amountErrorValue,
            reload_date,
            receiptRequired,
            showAmountField,
        } = this.state;
        const { user, portal } = this.props;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.reload.trueMoney', 'True Money'),
        };

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <>
                        {bankList.length > 0 && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list bank-selector'>
                                    <ul className='bank-list'>
                                        {bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className='small-bank bank-acc-list'
                                                onClick={() => {
                                                    this.onBankClick(bank);
                                                }}
                                            >
                                                <div className='member-bankacc'>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='bank-account'
                                                            value='0'
                                                            checked={selectedBankIndex === i}
                                                            onChange={() => {
                                                                this.onBankClick(bank);
                                                            }}
                                                        />
                                                        <span className='checkmark'></span>
                                                        <div className='banklist-group'>
                                                            <div className='group-box bankgroup-box-container'>
                                                                <div className={`bank-img ${bank.code === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                    <div
                                                                        className={[
                                                                            'icon-bank',
                                                                            bank.code === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                            selectedBankIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                        ].join(' ')}
                                                                    >
                                                                        <BankIcon bankCode={bank.code} />
                                                                    </div>
                                                                    <div className='bank-active'></div>
                                                                </div>

                                                                <div className='bank-name'>{bank.name}</div>
                                                            </div>

                                                            {selectedBankIndex === i && (
                                                                <>
                                                                    <div className='bank-details'>
                                                                        <input disabled='' readOnly defaultValue={bankList[i].accountHolder} />
                                                                        <SVGElements
                                                                            className='copy'
                                                                            name='copy'
                                                                            onClick={() => core.copy(t, bankList[selectedBankIndex]?.accountHolder)}
                                                                        />
                                                                    </div>
                                                                    <div className='bank-details'>
                                                                        <input disabled='' readOnly defaultValue={bankList[i].accountNumber} />
                                                                        <SVGElements
                                                                            className='copy'
                                                                            name='copy'
                                                                            onClick={() => core.copy(t, bankList[selectedBankIndex]?.accountNumber)}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content special-group'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.reload.date_time', 'Deposit Date/Time')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='date-content'>
                                <DatePicker
                                    selected={reload_date}
                                    onChange={(date) => vm.getReloadDate(date)}
                                    timeInputLabel='Time:'
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    showTimeInput
                                />
                            </div>
                        </div>

                        {depositAllSettings.showRefId && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='reference'
                                            type='text'
                                            autoComplete='off'
                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                            value={fields['reference']}
                                            onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'reference')}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {receiptImg.length > 0 && (
                            <div className='group-item'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='input-box receipt-image-box'>
                                        <LazyLoadImage src={receiptImg} alt='' />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox m-standard-2-button-container'>
                                    <button
                                        className='btn btn-submit standard-button'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={vm.isSubmitButtonDisabled()}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                    {depositAllSettings.uploadReceipt && (
                                        <button
                                            className='btn btn-secondary standard-button'
                                            onClick={() => {
                                                transactionHelper.handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                            <input
                                                id='hiddenFileInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*'
                                                key={receiptImg}
                                                onChange={(e) => vm.handleReceiptChange(e)}
                                            ></input>
                                        </button>
                                    )}
                                </div>
                                {receiptRequired && <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>}
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <ul>
                                <li>{t('transaction:transaction:rule_1')}</li>
                                <li>{t('transaction:transaction:rule_2')}</li>
                                <li>{t('transaction:transaction:rule_3')}</li>
                                <li>{t('transaction:transaction:rule_4')}</li>
                                <li>{t('transaction:transaction:rule_5')}</li>
                                <li>{t('transaction:transaction:rule_6')}</li>
                                <li>{t('transaction:transaction:rule_7')}</li>
                            </ul>
                        </div> */}
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankTransfer)));
