import core from 'root/utils/core';
import commonUtil from '../../../../../../utils/common-util';
import transactionHelper from 'root/utils/transaction-helper';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { scrollToDepositStep2 } from '../../deposit-util';

const METHOD_OR_CHANNEL_CODE = 'BQR';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositAmountOptions: [],

            selectedMethodCache: {},
            depositMinMaxLimit: { min: 0, max: 0 },
            methodList: [],
            bankList: [],
            selectedMethodIndex: 0,
            selectedBankIndex: disablePreselectBank ? null : 0,
            hoveredIndex: null,

            // should be can put at parent de variables
            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            disableDepositButton: true,
            isFormValid: false,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: null,

            //third-party-bank
            thirdPartyMemberBank: [],
            disableThirdPartyButton: false,
            depositSubmitCountdown: null,
        };
    },
    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { customCountdown } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            const vm = controller.vm;
            let currency = vm.props.user.account && vm.props.user.account.currency;

            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'bankQrSettings',
            });

            let showCurrencyConversion = null;
            if (currency === 'VND') {
                showCurrencyConversion = '1 = 1000 Đồng';
            } else if (currency === 'IDR') {
                showCurrencyConversion = '1 = 1000 RP';
            }

            let compSettings = {
                showCustomArticle: tempSettings.showCustomArticle ? tempSettings.showCustomArticle : false,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCurrencyConversion,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    },

    async loadQRbank() {
        const { filteredDepositApiJson, selectedMethodIndex } = controller.vm.state;
        const { paymentGatewaySetting } = controller.vm.props;
        const currency = controller.vm.props.user.account.currency;

        let _bankList = [];
        let _methodList = [];
        // if enableJsonAPi true from deposit-landing

        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            // For loop the channel/method list
            for (let channel in filteredDepositApiJson.channel) {
                if (filteredDepositApiJson.channel[channel]) {
                    let method = filteredDepositApiJson.channel[channel][0];
                    method.channel = channel;
                    method.displayName = window.SPL_Translation.getBankChannelTranslation(method, 'channelCode');
                    // method.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${method.code}.svg`, `${method.code}`);
                    _methodList.push(method);
                }
            }
            if (_methodList.length > 0) {
                core.portalIsLoading(controller.vm.props, true);
                let selectedMethodObj = _methodList[selectedMethodIndex];
                window.SPL_Transaction.getDepositDetailsOnMethodChange([], selectedMethodObj, currency).then(async (data) => {
                    _bankList = data.banks ? data.banks : [];
                    // for (const bank of _bankList) {
                    //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                    // }

                    // cache 1st selected method list's banks
                    let selectedMethodCache = controller.vm.state.selectedMethodCache;
                    let selectedMethodObj = _methodList[selectedMethodIndex];
                    selectedMethodCache[selectedMethodObj.id] = _bankList;
                    const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

                    controller.vm.setState(
                        {
                            methodList: _methodList,
                            selectedMethodCache,
                            bankList: _bankList,
                            disableDepositButton: disablePreselectBank && !_bankList?.length ? false : controller.vm.state.disableDepositButton,
                        },
                        () => {
                            commonUtil.getDepositMinMax(controller.vm, 'BQR', controller.vm.props.user.account.currency, selectedMethodObj.code);
                            if (
                                paymentGatewaySetting &&
                                paymentGatewaySetting.bankQRWithMemberBank &&
                                paymentGatewaySetting.bankQRWithMemberBank.includes(selectedMethodObj.code)
                            ) {
                                controller.getMemberThirdPartyBank();
                            }
                            core.portalIsLoading(controller.vm.props, false);
                        }
                    );
                });
            }
        }
    },

    async changeQRChannel(methodObj, index) {
        const vm = controller.vm;
        const { methodList } = vm.state;
        const currency = vm.props.user.account.currency;

        let _selectedMethodCache = vm.state.selectedMethodCache;
        let newSelectedMethodObj = methodList[index];
        const { selectedMethodIndex } = controller.vm.state;
        const { paymentGatewaySetting } = controller.vm.props;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        if (selectedMethodIndex !== index) {
            controller.vm.setState(
                {
                    selectedMethodIndex: index,
                },
                () => {
                    if (_selectedMethodCache[newSelectedMethodObj.id]) {
                        vm.setState(
                            {
                                bankList: _selectedMethodCache[newSelectedMethodObj.id],
                                disableDepositButton: disablePreselectBank && !_selectedMethodCache[newSelectedMethodObj.id]?.length ? false : controller.vm.state.disableDepositButton,
                            },
                            () => {
                                if (
                                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex] &&
                                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                                ) {
                                    commonUtil.getDepositMinMax(
                                        controller.vm,
                                        'BQR',
                                        controller.vm.props.user.account.currency,
                                        controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                                    );
                                }
                            }
                        );
                    } else {
                        if (paymentGatewaySetting && paymentGatewaySetting.bankQRWithMemberBank && paymentGatewaySetting.bankQRWithMemberBank.includes(methodObj.code)) {
                            controller.getMemberThirdPartyBank();
                        } else {
                            window.SPL_Transaction.getDepositDetailsOnMethodChange([], newSelectedMethodObj, currency).then(async (data) => {
                                let _bankList = data.banks ? data.banks : [];
                                // cache the selected new methodlist bank list
                                // for (const bank of _bankList) {
                                //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                                // }

                                _selectedMethodCache[newSelectedMethodObj.id] = _bankList;

                                vm.setState({
                                    selectedMethodCache: _selectedMethodCache,
                                    bankList: _bankList,
                                    disableDepositButton: disablePreselectBank && !_bankList?.length ? false : controller.vm.state.disableDepositButton,
                                });
                            });
                        }
                    }
                    commonUtil.getDepositMinMax(controller.vm, 'BQR', controller.vm.props.user.account.currency, methodList[index] && methodList[index].code);
                }
            );
        }
    },

    getMemberThirdPartyBank() {
        const { methodList, selectedMethodIndex } = controller.vm.state;
        let methodObj = methodList[selectedMethodIndex];

        window.SPL_Transaction.getMemberThirdPartyBank(methodObj.id).then((data) => {
            if (data.length > 0) {
                controller.applySVGForBank(data);
            } else {
                controller.vm.setState({ disableDepositButton: true });
            }
        });
    },
    async applySVGForBank(data) {
        for (let i in data) {
            const bank = data[i];
            bank.accountHolder = bank.accountName;
            // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.bankCode}.svg`, `${bank.bankCode}`);
            bank.displayName = await window.SPL_Transaction.getBankName(bank);
        }

        controller.vm.setState({ thirdPartyMemberBank: data });
        core.portalIsLoading(controller.vm.props, false);
    },

    onThirdPartyBankChanged(e) {
        controller.vm.setState({ selectedBankIndex: e, disableDepositButton: false }, () => {
            const {
                enableScrollToDepositStep2 = false,
                scrollToDepositStep2Attempts = 15,
                scrollToDepositStep2Delay = 200,
            } = controller.vm.props.portal?.settings?.features?.depositSettings || {};
            scrollToDepositStep2(enableScrollToDepositStep2, controller.vm.state.selectedBankIndex, e, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
        });
    },

    onBankChanged(bankObj, onChangeValue) {
        const { selectedBankIndex, bankList, methodList, selectedMethodIndex } = controller.vm.state;
        const {
            enableScrollToDepositStep2 = false,
            scrollToDepositStep2Attempts = 15,
            scrollToDepositStep2Delay = 200,
        } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

        if (onChangeValue !== undefined) {
            controller.vm.setState({ selectedBankIndex: onChangeValue, disableDepositButton: false }, () => {
                scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, onChangeValue, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'BQR',
                    controller.vm.props.user.account.currency,
                    methodList[selectedMethodIndex] && methodList[selectedMethodIndex].code
                );
            });
        } else {
            let foundIndex = bankList.indexOf(bankObj);
            if (foundIndex !== selectedBankIndex) {
                controller.vm.setState({ selectedBankIndex: foundIndex, disableDepositButton: false }, () => {
                    scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, foundIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                    commonUtil.getDepositMinMax(
                        controller.vm,
                        'BQR',
                        controller.vm.props.user.account.currency,
                        methodList[selectedMethodIndex] && methodList[selectedMethodIndex].code
                    );
                });
            }
        }
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.BQR]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { depositAllSettings, selectedMethodIndex, methodList } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };
        let methodObj = methodList[selectedMethodIndex];
        if (methodObj && depositAllSettings.depositJson.quickPay[methodObj.code]) {
            _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[methodObj.code][currency];
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    submitForm() {
        const { isFormValid, disableDepositButton, methodList, selectedMethodIndex } = controller.vm.state;
        const { paymentGatewaySetting } = controller.vm.props;

        if (isFormValid && !disableDepositButton) {
            if (
                paymentGatewaySetting &&
                paymentGatewaySetting.bankQRWithMemberBank &&
                methodList[selectedMethodIndex] &&
                methodList[selectedMethodIndex].code &&
                paymentGatewaySetting.bankQRWithMemberBank.includes(methodList[selectedMethodIndex].code)
            ) {
                controller.submitThirdPartyQrPayDeposit();
            } else {
                controller.submitQrPayDeposit();
            }
        }
    },

    submitQrPayDeposit() {
        const vm = controller.vm;
        const { bankList, fields, selectedBankIndex, selectedMethodIndex, methodList, depositSubmitCountdown } = vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = vm.props;
        const { features } = vm.props.portal.settings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }
            let bankObj = bankList[selectedBankIndex];
            let amount = Number(fields.amount);
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let callbackUrl = `/${langPath}/myaccount/deposit`;
            let stateUrl = '';

            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }
            let methodParam = {
                id: methodList[selectedMethodIndex] && methodList[selectedMethodIndex].id,
                code: methodList[selectedMethodIndex] && methodList[selectedMethodIndex].code,
            };
            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, bankObj.code, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                }
            });
        }
    },

    submitThirdPartyQrPayDeposit() {
        const { thirdPartyMemberBank, fields, selectedBankIndex, selectedMethodIndex, methodList, filteredDepositApiJson, depositSubmitCountdown } = controller.vm.state;
        const { language, depositSubmission, t, customCountdown } = controller.vm.props;
        const { features } = controller.vm.props.portal.settings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }
            let methodObj = methodList[selectedMethodIndex] ? methodList[selectedMethodIndex] : null;
            let bankObj = thirdPartyMemberBank[selectedBankIndex];
            let amount = Number(fields.amount);
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let callbackUrl = `/${langPath}/myaccount/deposit`;
            let stateUrl = '';
            let methodCode = methodObj.code;
            let methodId = methodObj.id;

            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }
            let methodParam = {
                id: methodId,
                code: methodCode,
            };
            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl, null, bankObj).then(
                (data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                    }
                }
            );
        }
    },
    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'bqr' : '';
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
