import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import vm from '../../deposit.controller';
import SVGElements from 'root/components/SVGElements';
import QRCode from 'qrcode.react';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EmailIcon, EmailShareButton, LineIcon, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import SVGBank from 'root/components/svgIcon/svgBank';
import SVGBankApp from 'root/components/svgIcon/svgBankApp';
import { Fragment } from 'react';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';

class MDeposit extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initDepositPage();
        vm.loadBankAccounts();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const {
            isFormValid,
            disableDepositButton,
            cryptoQrLink,
            depositAllSettings,
            isBankApp,
            isQRPay,
            expressDepositMemberBankList,
            expressDepositMerchantBankList,
            verifyAccountList,
            showAllBankMaintenaceMsg,
            depositAmountOptions,
        } = this.state;
        let bankList = this.state.unOwnedBankList;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const amountOptions = trans_getDepositAmountOptionByMethod(depositAmountOptions);

        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/'>
                        {this.state.memberVerifyPopup && (
                            <div
                                className='memberVerify-box mobile-box'
                                onClick={() => {
                                    vm.close('memberVerify');
                                }}
                            >
                                <div
                                    className='memberVerify-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.crypto.memberVerify', 'Member Information Verification')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.close('memberVerify');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content'>
                                        {this.state.showName && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.cryptoFields['fullname']}
                                                    placeholder={t('global:global.form.fullname', 'Full Name')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'fullname');
                                                    }}
                                                />
                                                <div className='input-error'>
                                                    {this.state.errors['fullname'] !== 'global:global.form.fullname-placeholderv2' && t(this.state.errors['fullname'])}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.showEmail && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.fields['email']}
                                                    placeholder={t('global:global.form.email', 'Email Address')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'email');
                                                    }}
                                                />
                                                <div className='input-error'>{t(this.state.errors['email'])}</div>
                                            </div>
                                        )}

                                        {this.state.showPhone && (
                                            <div className='input'>
                                                <div>
                                                    <select
                                                        className='phone'
                                                        value={this.state.fields['telCode']}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'telCode');
                                                        }}
                                                    >
                                                        {this.state.optionsList['telCode'].map((option, i) => (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <DebounceInput
                                                        type='text'
                                                        debounceTimeout='1000'
                                                        values={this.state.fields['phone']}
                                                        placeholder={t('global:global.form.phone', 'Telephone Number')}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'phone');
                                                        }}
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['phone'])}</div>
                                            </div>
                                        )}

                                        {this.state.showDob && (
                                            <div className='input'>
                                                <div>
                                                    <span className='item-title'>{t('global:global.form.dob', 'Date of Birth')}</span>
                                                    <DatePicker
                                                        autoComplete='off'
                                                        type='text'
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={t('transaction:transaction.deposit.cashdeposit.depositdate', 'Deposit Date/Time')}
                                                        selectsStart
                                                        selected={this.state.cryptoFields.dob}
                                                        maxDate={this.state.maxDob}
                                                        popperPlacement='bottom-end'
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'dob');
                                                        }}
                                                        timeFormat='yyyy-MM-dd'
                                                        dateFormat='yyyy-MM-dd'
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['dob'])}</div>
                                            </div>
                                        )}

                                        {this.state.requestRefill && (
                                            <div className='input'>
                                                <div className='input-error'>{t('transaction:transaction.deposit.crypto.fillAgain', 'Please fill in again.')}</div>
                                            </div>
                                        )}

                                        <button className='btn-submit' disabled={this.state.memberVerifyDisable} onClick={() => vm.handleMemberVerifySubmit()}>
                                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.getShareInfo && (
                            <div
                                className='shareInfo-box mobile-box'
                                onClick={() => {
                                    vm.close('shareInfo');
                                }}
                            >
                                <div
                                    className='shareInfo-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('settings:settings.inviteFriend', 'Invite Friend')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.close('shareInfo');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content shareInfo-content'>
                                        <EmailShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <EmailIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Email</span>
                                        </EmailShareButton>
                                        <WhatsappShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <WhatsappIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Whatsapp</span>
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <TelegramIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Telegram</span>
                                        </TelegramShareButton>
                                        <LineShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <LineIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>LINE</span>
                                        </LineShareButton>
                                        <button
                                            className='shareInfo-copy'
                                            onClick={() => {
                                                core.copy(t, this.state.cryptoQrCode);
                                            }}
                                        >
                                            <AiOutlineCopy></AiOutlineCopy> <span>Copy to Clipboard</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.showQrBankList && (
                            <div
                                className='qrbanklist-box mobile-box'
                                onClick={() => {
                                    vm.toggleQrPayBankList(false);
                                }}
                            >
                                <div
                                    className='qrbanklist-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.popup-title', 'List of supported bank for QR code deposit')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.toggleQrPayBankList(false);
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content qrbanklist-content'>
                                        {this.state.qrpaySupportedBankList.map((bank, index) => {
                                            return bank.isDummyBank ? (
                                                <div className='bank-container' key={index}></div>
                                            ) : (
                                                <div className='bank-container' key={index}>
                                                    <div className='icon-container'>
                                                        <span className='icon-box'>
                                                            {/* <SVGBank
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            ></SVGBank> */}
                                                            {bank.code && bank.code.toLowerCase().includes('other') ? (
                                                                <SVGBank
                                                                    className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                    name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                ></SVGBank>
                                                            ) : (
                                                                <img
                                                                    className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                    src={`/public/images/bankImg/${userCurrency}/${bank.code}.png`}
                                                                    alt='bank-icon'
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className='bankname-container'>{bank.bankName}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='deposit-page'>
                            <div className='group-item deposit-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.option', 'Deposit Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='deposit-options-list'>
                                    <ul>
                                        {this.state.methodList.map((method, i) => (
                                            <li
                                                key={i}
                                                className={['icon-hover-box', this.state.selectedMethodIndex === i ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.onMethodListChanged(method);
                                                }}
                                            >
                                                <span className='icon-box'>
                                                    {method.key !== 'fpy2' && <i className={'icon-deposit-' + method.key}></i>}
                                                    {method.key === 'fpy2' && (
                                                        <SVGElements
                                                            className={method.key}
                                                            name={method.key}
                                                            width='300%'
                                                            height='300%'
                                                            color={this.state.selectedMethodIndex === i ? '#FF4E00' : 'gray'}
                                                        />
                                                    )}
                                                </span>
                                                <span>{method.code === 'H2PP' ? 'Promptpay' : t(method.displayName ? method.displayName : method.shortName)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {this.state.isCrypto && (
                                <>
                                    <div className='group-item bank-options'>
                                        <div className='bank-list bank-selector'>
                                            <ul className='bank-list'>
                                                {this.state.cryptoChain &&
                                                    this.state.cryptoChain.length > 0 &&
                                                    this.state.cryptoChain.map((chain, i) => (
                                                        <li
                                                            key={i}
                                                            className='small-bank bank-acc-list'
                                                            onClick={() => {
                                                                vm.onChainChanged(chain);
                                                            }}
                                                        >
                                                            <div className='member-bankacc'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='bank-account'
                                                                        value='0'
                                                                        checked={this.state.selectedChainIndex === i}
                                                                        onChange={() => {
                                                                            vm.onChainChanged(chain);
                                                                        }}
                                                                    />
                                                                    <span className='checkmark'></span>
                                                                    <div className='banklist-group'>
                                                                        <div className='group-box'>
                                                                            <div className={`bank-img crypto-bank-img`}>
                                                                                <div
                                                                                    className={[
                                                                                        'icon-bank',
                                                                                        'icon-bank-big',
                                                                                        this.state.selectedChainIndex === i ? 'on' : '',
                                                                                    ].join(' ')}
                                                                                >
                                                                                    <SVGBank
                                                                                        className={`${core.getCorrectBankIcon(chain.coin, this, true)}`}
                                                                                        name={`${core.getCorrectBankIcon(chain.coin, this, true)}`}
                                                                                    ></SVGBank>
                                                                                </div>
                                                                                <div className='bank-active'></div>
                                                                            </div>
                                                                            <div className='bank-name'>{chain.displayName}</div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>

                                    {this.state.cryptoOptions && this.state.cryptoOptions.length > 1 && (
                                        <MobileSelect
                                            itemList={this.state.cryptoOptions}
                                            itemKey={'displayName'}
                                            onChange={(e) => vm.changeCryptoChannel(e, 'mobile')}
                                            itemIndex={this.state.selectedCryptoIndex}
                                            title={t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}
                                            required={true}
                                            onChangeIndexType={true}
                                        />
                                    )}

                                    <div className='group-item bank-options'>
                                        <div className='bank-list bank-selector crypto-qr' id='crypto-qr'>
                                            <ul className='bank-list'>
                                                {this.state.cryptoQrCode && (
                                                    <>
                                                        <li className='qr-box'>
                                                            <QRCode id='cryptoQR' value={vm.qrcode()} size={218} className='crypto-qr-img' />
                                                        </li>

                                                        <li className='box-info'>
                                                            <span onClick={() => vm.downloadQrCode()}>
                                                                {t('transaction:transaction.deposit.crypto.saveQr', 'SAVE QR CODE')}
                                                            </span>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='group-item deposit-options crypto-shareBox'>
                                        <div className='title'>
                                            <span>
                                                {t('transaction:transaction.deposit.crypto.sendToChainAddress', 'or send to this Chain address', {
                                                    chain: this.state.chainName,
                                                })}
                                            </span>
                                        </div>
                                        <ul>
                                            <li className='box-code'>
                                                <p>{this.state.cryptoQrCode}</p>
                                            </li>
                                            <li className='btn-share'>
                                                <p onClick={() => vm.getShareInfo()}>{t('transaction:transaction.deposit.crypto.share', 'SHARE')}</p>
                                            </li>

                                            {cryptoQrLink && (
                                                <button className='checkStatus' onClick={() => vm.getCryptoQrCode(cryptoQrLink)}>
                                                    {t('transaction:transaction.checkStatus', 'Check Status')}
                                                </button>
                                            )}
                                            {this.state.cryptoQrCode && (
                                                <li className='box-info'>
                                                    <p>
                                                        {this.state.chainName !== 'BTC' && `CHAIN : ${this.state.chainBranch} `}{' '}
                                                        {t('transaction:transaction.deposit.crypto.currentRate', 'Current exchange rate')}{' '}
                                                        <span>
                                                            {this.state.chainName} 1 = {userCurrency} {this.state.exchangeRate.toFixed(2)}
                                                        </span>
                                                    </p>
                                                </li>
                                            )}
                                            <li className='info-note'>
                                                {t(
                                                    'transaction:transaction.deposit.crypto.cryptoDepositNote1',
                                                    'Note: The current exchange rate above is FOR REFERENCE ONLY.'
                                                )}
                                            </li>
                                            <li className='info-note'>
                                                {t(
                                                    'transaction:transaction.deposit.crypto.cryptoDepositNote2',
                                                    'For the most accurate exchange rates, please refer to official {{chain}} exchange rates.',
                                                    {
                                                        chain: this.state.chainName,
                                                    }
                                                )}
                                            </li>
                                        </ul>
                                    </div>

                                    {this.state.chainRemark && this.state.chainRemark.length > 0 && (
                                        <div className='notice-box'>
                                            <span className='item-title'>{t('transaction:transaction.deposit.crypto.reminder', 'REMINDER')} :</span>
                                            <ul className='crypto-remark'>
                                                {this.state.chainRemark.map((info, index) => (
                                                    <li key={index}>{info}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </>
                            )}

                            {this.state.isExpressDeposit && (
                                <Fragment>
                                    <div className='group-item bank-options'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.expressDeposit.toBankAccount', 'To Bank Account')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='bank-list express-deposit-box'>
                                            {expressDepositMemberBankList && !this.state.showAllBankMaintenaceMsg ? (
                                                <Fragment>
                                                    {expressDepositMerchantBankList && !this.state.showNotBankSupportMsg ? (
                                                        <Fragment>
                                                            {expressDepositMerchantBankList.map((bank, i) => {
                                                                return (
                                                                    <ul className='bank-column-box'>
                                                                        <Fragment>
                                                                            <li className='icon-hover-box'>
                                                                                <div className={'bank-icon-box'}>
                                                                                    {/* <SVGBank
                                                                                        className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                        name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                    ></SVGBank> */}
                                                                                    {bank.bankCode && bank.bankCode.toLowerCase().includes('other') ? (
                                                                                        <SVGBank
                                                                                            className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                            name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                        ></SVGBank>
                                                                                    ) : (
                                                                                        <img
                                                                                            className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                            src={`/public/images/bankImg/${userCurrency}/${bank.bankCode}.png`}
                                                                                            alt='bank-icon'
                                                                                        />
                                                                                    )}
                                                                                    <span className='bank-name'>{bank.name}</span>
                                                                                </div>
                                                                            </li>
                                                                            <div className='express-deposit-bank-list'>
                                                                                <span className='account-holder'>{bank.accountName}</span>
                                                                                <span className='account-holder'>{bank.accountNumber}</span>
                                                                            </div>
                                                                        </Fragment>
                                                                    </ul>
                                                                );
                                                            })}
                                                        </Fragment>
                                                    ) : (
                                                        <span className='request-text'>
                                                            {t(
                                                                'transaction:transaction.deposit.expressDeposit.unavailableExpressBank',
                                                                'Express Deposit unavailable now, Please SELECT other Deposit Option, sorry for inconvenience caused'
                                                            )}
                                                        </span>
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <span className='request-text'>
                                                    {t(
                                                        'transaction:transaction.deposit.expressDeposit.requestAddBank',
                                                        'Please add your BANK account details for use Express Deposit'
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {expressDepositMemberBankList && !showAllBankMaintenaceMsg && (
                                        <div className='group-item bank-options'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.deposit.expressDeposit.yourBankAccount', 'Your Bank Account')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='bank-list express-deposit-box'>
                                                <div className='bank-column-box'>
                                                    <div className='bank-icon-box'>
                                                        {/* <SVGBank
                                                            className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                            name={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                        ></SVGBank> */}
                                                        {expressDepositMemberBankList.bankCode && expressDepositMemberBankList.bankCode.toLowerCase().includes('other') ? (
                                                            <SVGBank
                                                                className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                            ></SVGBank>
                                                        ) : (
                                                            <img
                                                                className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                src={`/public/images/bankImg/${userCurrency}/${expressDepositMemberBankList.bankCode}.png`}
                                                                alt='bank-icon'
                                                            />
                                                        )}
                                                        <span className='bank-name'>{expressDepositMemberBankList.bankName}</span>
                                                    </div>
                                                    <div className='express-deposit-bank-list'>
                                                        <span className='account-holder'>
                                                            {expressDepositMemberBankList.accountName
                                                                ? expressDepositMemberBankList.accountName
                                                                : expressDepositMemberBankList.accountHolder}
                                                        </span>
                                                        <span className='account-holder'>{expressDepositMemberBankList.accountNumber}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <button
                                                className='express-deposit-btn-submit btn-submit'
                                                onClick={() => {
                                                    vm.removeBankList(expressDepositMemberBankList);
                                                }}
                                            >
                                                {t('transaction:transaction.deposit.expressDeposit.remove', 'Remove').toUpperCase()}
                                            </button>
                                        </div>
                                    )}

                                    <div className='bank-add'>
                                        <MobileSelect
                                            itemList={this.state.verifyBank}
                                            itemKey={'bankName'}
                                            onChange={(e) => vm.handleBankDetailChange(e)}
                                            itemIndex={this.state.selectedBankDetailIndex}
                                            title={t('bank:bank.bank', 'Bank')}
                                            required={expressDepositMemberBankList && !showAllBankMaintenaceMsg ? false : true}
                                            disabledDropDown={expressDepositMemberBankList && !showAllBankMaintenaceMsg ? true : false}
                                            isPleaseSelect={true}
                                            isSelected={false}
                                            requiredReset={this.state.displayDefault}
                                            newItemArray={expressDepositMemberBankList}
                                        />
                                        <div
                                            className='addBankExressDeposit'
                                            onClick={() => {
                                                vm.addButtonOnClicked();
                                            }}
                                        >
                                            <span>{t('transaction:transaction.deposit.expressDeposit.addExpressDeposit', 'Click here to add bank for Express Deposit')}</span>
                                        </div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                                <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <input
                                                        type='text'
                                                        value={this.state.fields['accountName']}
                                                        disabled
                                                        id='accountNumber'
                                                        placeholder={
                                                            verifyAccountList.accountName ? verifyAccountList.accountName : t('bank:bank.newBank.account-name', 'Account Name')
                                                        }
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                                <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    {
                                                        <input
                                                            type='text'
                                                            value={this.state.fields['accountNumber']}
                                                            onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                            id='accountNumber'
                                                            placeholder={
                                                                verifyAccountList.accountNumber
                                                                    ? verifyAccountList.accountNumber
                                                                    : t('bank:bank.newBank.account-number', 'Account Number')
                                                            }
                                                            disabled
                                                        ></input>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='group-item submit-container'>
                                            <div className='title'></div>
                                            <div className='amount-content'>
                                                <button
                                                    className={`express-deposit-btn-submit btn-submit standard-button ${
                                                        expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'disable-grey' : ''
                                                    }`}
                                                    disabled={!isFormValid}
                                                    onClick={() => {
                                                        vm.handleSubmitExpressDepositBank();
                                                    }}
                                                >
                                                    {t('global:entity.action.select').toUpperCase()}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}

                            {!this.state.isCrypto && !this.state.isExpressDeposit && (
                                <>
                                    {!isQRPay && (
                                        <div className='group-item bank-options'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='bank-list bank-selector'>
                                                <ul className='bank-list'>
                                                    {this.state.bankList.map((bank, i) => (
                                                        <li
                                                            key={bank.code}
                                                            className='small-bank bank-acc-list'
                                                            onClick={() => {
                                                                vm.onBankChanged(bank);
                                                            }}
                                                        >
                                                            <div className='member-bankacc'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='bank-account'
                                                                        value='0'
                                                                        checked={this.state.selectedBankIndex === i}
                                                                        onChange={() => {
                                                                            vm.onBankChanged(bank);
                                                                        }}
                                                                    />
                                                                    <span className='checkmark'></span>
                                                                    <div className='banklist-group'>
                                                                        <div className='group-box bankgroup-box-container'>
                                                                            <div className={`bank-img ${bank.code === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                                <div
                                                                                    className={[
                                                                                        'icon-bank',
                                                                                        bank.code === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                                        this.state.selectedBankIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                                    ].join(' ')}
                                                                                >
                                                                                    {isBankApp ? (
                                                                                        <SVGBankApp
                                                                                            className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                            name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                        ></SVGBankApp>
                                                                                    ) : bank.code && bank.code.toLowerCase().includes('other') ? (
                                                                                        <SVGBank
                                                                                            className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                            name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                        ></SVGBank>
                                                                                    ) : (
                                                                                        // <SVGBank
                                                                                        //     className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                        //     name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                        // ></SVGBank>
                                                                                        <img
                                                                                            className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                                            src={`/public/images/bankImg/${userCurrency}/${bank.code}.png`}
                                                                                            alt='bank-icon'
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className='bank-active'></div>
                                                                            </div>
                                                                            {console.log('test', bank.name)}
                                                                            <div className='bank-name'>{bank.name}</div>
                                                                        </div>

                                                                        {(this.state.isOnlineTransfer || this.state.isReload) && this.state.selectedBankIndex === i && (
                                                                            <>
                                                                                <div className='bank-details'>
                                                                                    <input disabled='' readOnly defaultValue={this.state.bankList[i].accountHolder} />
                                                                                    <SVGElements
                                                                                        className='copy'
                                                                                        name='copy'
                                                                                        onClick={() =>
                                                                                            core.copy(t, this.state.bankList[this.state.selectedBankIndex]?.accountHolder)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='bank-details'>
                                                                                    <input disabled='' readOnly defaultValue={this.state.bankList[i].accountNumber} />
                                                                                    <SVGElements
                                                                                        className='copy'
                                                                                        name='copy'
                                                                                        onClick={() =>
                                                                                            core.copy(t, this.state.bankList[this.state.selectedBankIndex]?.accountNumber)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {isBankApp && this.state.selectedBankIndex === i && (
                                                                            <>
                                                                                <div className='bank-details'>
                                                                                    <input disabled='' readOnly defaultValue={this.state.bankList[i].accountHolder} />
                                                                                    <SVGElements
                                                                                        className='copy'
                                                                                        name='copy'
                                                                                        onClick={() =>
                                                                                            core.copy(t, this.state.bankList[this.state.selectedBankIndex]?.accountHolder)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='bank-details'>
                                                                                    <input disabled='' readOnly defaultValue={this.state.bankList[i].bankAppsPhone} />
                                                                                    <SVGElements
                                                                                        className='copy'
                                                                                        name='copy'
                                                                                        onClick={() =>
                                                                                            core.copy(t, this.state.bankList[this.state.selectedBankIndex]?.bankAppsPhone)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {isQRPay && (
                                        <div className='group-item bank-options'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='bank-list bank-selector'>
                                                <ul className='bank-list'>
                                                    {this.state.qrPayDepositChannelObjs.map((e, i) => (
                                                        <li
                                                            key={e.channel}
                                                            className='small-bank bank-acc-list'
                                                            onClick={() => {
                                                                vm.changeQRChannel(i);
                                                            }}
                                                        >
                                                            <div className='member-bankacc'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name='bank-account'
                                                                        value='0'
                                                                        checked={this.state.selectedChannelIndex === i}
                                                                        onChange={() => {
                                                                            vm.changeQRChannel(i);
                                                                        }}
                                                                    />
                                                                    <span className='checkmark'></span>
                                                                    <div className='banklist-group'>
                                                                        <div className='group-box bankgroup-box-container'>
                                                                            <div className={`bank-img ${e.channel === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                                <div
                                                                                    className={[
                                                                                        'icon-bank',
                                                                                        e.channel === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                                        this.state.selectedChannelIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                                    ].join(' ')}
                                                                                >
                                                                                    <SVGBank
                                                                                        className={`${core.getCorrectBankIcon(e.channel, this, true)}`}
                                                                                        name={`${core.getCorrectBankIcon(e.channel, this, true)}`}
                                                                                    ></SVGBank>
                                                                                </div>
                                                                                <div className='bank-active'></div>
                                                                            </div>

                                                                            <div className='bank-name'>{e.channelName}</div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {isQRPay && this.state.depositAllSettings.showDepositChannel && (
                                        <MobileSelect
                                            itemList={this.state.qrPayList}
                                            itemKey={'displayName'}
                                            onChangeIndexType={true}
                                            onChange={(e) => vm.onBankChanged(this.state.qrPayList[this.state.selectedBankIndex], e)}
                                            itemIndex={this.state.selectedBankIndex}
                                            title={t('transaction:transaction.deposit.onlinetransfer.qrOption', 'Options')}
                                            required={true}
                                        />
                                    )}

                                    <div className='group-item input-item'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='amount-content special-group'>
                                            <div className='input-box'>
                                                <input
                                                    id='depositamount'
                                                    type='numeric'
                                                    autoComplete='off'
                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                        min: window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min),
                                                        max: window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max),
                                                    })}
                                                    min={'0'}
                                                    value={this.state.fields['amount']}
                                                    onChange={(e) => vm.handleFieldChanged(e, 'amount')}
                                                    onBlur={() => {
                                                        vm.handleValidation();
                                                    }}
                                                />
                                                <div className='error-validation'>
                                                    <Trans
                                                        i18nKey={this.state.errors['amount']}
                                                        values={{
                                                            amount: window.SPL_Other.formatAmount(this.state.amountErrorValue),
                                                            currency: userCurrency,
                                                        }}
                                                    ></Trans>
                                                </div>
                                            </div>
                                            <ul>
                                                {amountOptions.map((item) => (
                                                    <li
                                                        key={item}
                                                        onClick={() => {
                                                            vm.onDepositAmountClicked(item);
                                                        }}
                                                    >
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                            {isQRPay && (
                                                <div className='input-box'>
                                                    <input
                                                        className='qr-reminder'
                                                        type='text'
                                                        readOnly={true}
                                                        value={t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.isReload && (
                                <div className='group-item input-item'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.reload.date_time', 'Deposit Date/Time')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='date-content'>
                                        <DatePicker
                                            selected={this.state.reload_date}
                                            onChange={(date) => vm.getReloadDate(date)}
                                            timeInputLabel='Time:'
                                            dateFormat='dd/MM/yyyy HH:mm'
                                            showTimeInput
                                        />
                                    </div>
                                </div>
                            )}

                            {isQRPay && this.state.qrpaySupportedBankList.length > 0 && (
                                <div className='group-item bank-options'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.title', 'Supported Banks')}</span>
                                    </div>
                                    <div className='amount-content'>
                                        <div
                                            className='qr-information'
                                            onClick={() => {
                                                vm.toggleQrPayBankList(true);
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.qrpay.supportedBanks.detail', 'Click to see the bank list for QR code deposit')}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(this.state.isOnlineTransfer || isBankApp) && this.state.depositAllSettings.showDepositChannel && (
                                <MobileSelect
                                    itemList={this.state.depositOptions}
                                    itemKey={'label'}
                                    onChange={(e) => vm.changeChannel(e)}
                                    itemIndex={this.state.selectedChannelIndex}
                                    title={t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}
                                    required={true}
                                />
                            )}

                            {(this.state.isOnlineTransfer || isBankApp) && this.state.depositAllSettings.showDepositChannel && (
                                <Fragment>
                                    <MobileSelect
                                        itemList={this.state.memberBankList}
                                        itemKey={'displayName'}
                                        onChange={(e) => vm.handleBankDetailChange(e)}
                                        itemIndex={this.state.selectedBankDetailIndex}
                                        title={t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')}
                                        required={true}
                                        disablePreselectBank={disablePreselectBank}
                                    />

                                    {!depositAllSettings.hideAddBankAccount && (
                                        <button className='addBankButton' onClick={() => vm.openAddBankDialog()}>
                                            {t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                        </button>
                                    )}
                                </Fragment>
                            )}

                            {this.state.addBankDialogIsShow && (
                                <div
                                    className='addBank-box'
                                    onClick={() => {
                                        vm.close('addBank');
                                    }}
                                >
                                    <div
                                        className='addBank-popup'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.addBank.addBankTitle', 'Add Bank')}</span>
                                            <i
                                                className='icon-close3'
                                                onClick={() => {
                                                    vm.close('addBank');
                                                }}
                                            ></i>
                                        </div>

                                        <div className='content'>
                                            <div className='input'>
                                                <div className='bankName'>{t('transaction:transaction.deposit.addBank.bankName', 'Bank Name')}</div>
                                                <select
                                                    value={this.state.accountBankfields['selectedBankIndex']}
                                                    onChange={(e) => {
                                                        vm.handleBankChange(e, 'fullname');
                                                    }}
                                                >
                                                    {bankList &&
                                                        bankList.length > 0 &&
                                                        bankList.map((bank, index) => (
                                                            <option key={index} value={index}>
                                                                {t(bank.bankName)}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            {this.state.showBankName && (
                                                <div className='input'>
                                                    <div className='bankName'>{t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}</div>
                                                    <DebounceInput
                                                        type='text'
                                                        values={this.state.accountBankfields['bankName']}
                                                        placeholder={t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}
                                                        onChange={(e) => {
                                                            vm.handleAddBankChange(e, 'bankName');
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            <div className='input'>
                                                <div>{t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}</div>
                                                <DebounceInput
                                                    type='text'
                                                    values={this.state.accountBankfields['bankBranch']}
                                                    placeholder={t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}
                                                    onChange={(e) => {
                                                        vm.handleAddBankChange(e, 'bankBranch');
                                                    }}
                                                />
                                            </div>

                                            <div className='input'>
                                                <div>{t('transaction:transaction.deposit.addBank.accountName', 'Account Name')}</div>
                                                <DebounceInput type='text' placeholder={this.props.user.account.name} disabled />
                                            </div>

                                            <div className='input'>
                                                <div>{t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}</div>
                                                <DebounceInput
                                                    type='text'
                                                    values={this.state.accountBankfields['accountNumber']}
                                                    placeholder={t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}
                                                    onChange={(e) => {
                                                        vm.handleAddBankChange(e, 'accountNumber');
                                                    }}
                                                />
                                            </div>

                                            <div className='input-error'>{t(this.state.errors['accountNumber'])}</div>

                                            <button className='addBankSubmit' disabled={!vm.isValidForm()} onClick={() => vm.handdleAddAccountBankSubmit()}>
                                                {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(this.state.isReload || (this.state.isOnlineTransfer && this.state.depositAllSettings.showRefId)) && (
                                <div className='group-item input-item'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='amount-content'>
                                        <div className='input-box'>
                                            <input
                                                id='reference'
                                                type='text'
                                                autoComplete='off'
                                                placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                                value={this.state.fields['reference']}
                                                onChange={(e) => vm.handleFieldChanged(e, 'reference')}
                                                onBlur={() => {
                                                    vm.handleValidation();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.receiptImg.length > 0 && (
                                <div className='group-item'>
                                    <div className='title'></div>
                                    <div className='amount-content'>
                                        <div className='input-box receipt-image-box'>
                                            <LazyLoadImage src={this.state.receiptImg} alt='' />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!this.state.isCrypto && !this.state.isExpressDeposit && (
                                <>
                                    <div className='group-item submit-container'>
                                        <div className='title'></div>
                                        <div className='amount-content'>
                                            <div className='flexbox m-standard-2-button-container'>
                                                <button
                                                    className='btn btn-submit standard-button'
                                                    onClick={() => {
                                                        vm.submitForm();
                                                    }}
                                                    disabled={!isFormValid || disableDepositButton || (this.state.isOnlineTransfer && this.state.receiptRequired)}
                                                >
                                                    {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                                </button>

                                                {((this.state.depositAllSettings.uploadReceipt && (this.state.isOnlineTransfer || isBankApp)) || this.state.isReload) && (
                                                    <button
                                                        className='btn btn-secondary standard-button'
                                                        onClick={() => {
                                                            vm.handleUploadReceiptButtonOnClick();
                                                        }}
                                                    >
                                                        {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                                        <input
                                                            id='hiddenFileInput'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            accept='image/*'
                                                            onChange={(e) => vm.handleReceiptImageChange(e)}
                                                        ></input>
                                                    </button>
                                                )}

                                                {(this.state.isOnlineTransfer || this.state.isReload) && this.state.receiptRequired && (
                                                    <div className='error-validation pos-absolute'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {!isQRPay && (
                                        <div className='notice-box'>
                                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                                            <ul>
                                                <li>{t('transaction:transaction:rule_1')}</li>
                                                <li>{t('transaction:transaction:rule_2')}</li>
                                                <li>{t('transaction:transaction:rule_3')}</li>
                                                <li>{t('transaction:transaction:rule_4')}</li>
                                                <li>{t('transaction:transaction:rule_5')}</li>
                                                <li>{t('transaction:transaction:rule_6')}</li>
                                                <li>{t('transaction:transaction:rule_7')}</li>
                                            </ul>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'settings', 'transaction', 'register', 'bank'])(withRouter(MDeposit)));
