// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';

// controller/logic
import vm from '../../bank-qr-deposit.controller';
import transactionHelper from 'root/utils/transaction-helper';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class BankQRDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadQRbank();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BQR));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BQR) });
        }
    }

    render() {
        const {
            bankList,
            depositAmountOptions,
            depositMinMaxLimit,
            isFormValid,
            disableDepositButton,
            disableThirdPartyButton,
            selectedMethodIndex,
            depositAllSettings,
            selectedBankIndex,
            fields,
            errors,
            amountErrorValue,
            methodList,
            compSettings,
            thirdPartyMemberBank,
        } = this.state;
        const { paymentGatewaySetting } = this.props;

        let transactionNoteProps = {
            hideDisplayInfo: compSettings && compSettings.hideDisplayInfo,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list bank-selector'>
                                <ul className='bank-list'>
                                    {methodList.map((e, i) => (
                                        <li
                                            key={i}
                                            className='small-bank bank-acc-list'
                                            onClick={() => {
                                                vm.changeQRChannel(e, i);
                                            }}
                                        >
                                            <div className='member-bankacc'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='bank-account'
                                                        value='0'
                                                        checked={selectedMethodIndex === i}
                                                        onChange={() => {
                                                            vm.changeQRChannel(e, i);
                                                        }}
                                                    />
                                                    <span className='checkmark'></span>
                                                    <div className='banklist-group'>
                                                        <div className='group-box bankgroup-box-container'>
                                                            <div className={`bank-img ${e.channel === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                <div
                                                                    className={[
                                                                        'icon-bank',
                                                                        e.channel === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                        selectedMethodIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                    ].join(' ')}
                                                                >
                                                                    <div className={`icon-${e.channel}`}>
                                                                        <PaymentGatewayIcon pgCode={e.channel} />
                                                                    </div>
                                                                </div>
                                                                <div className='bank-active'></div>
                                                            </div>

                                                            <div className='bank-name'>{e.displayName}</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {paymentGatewaySetting &&
                        paymentGatewaySetting.bankQRWithMemberBank &&
                        methodList[selectedMethodIndex] &&
                        methodList[selectedMethodIndex].code &&
                        paymentGatewaySetting.bankQRWithMemberBank.includes(methodList[selectedMethodIndex].code) ? (
                            <>
                                {thirdPartyMemberBank && thirdPartyMemberBank.length > 0 ? (
                                    <div className='group-item bank-options'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='bank-list bank-selector'>
                                            <ul className='bank-list'>
                                                {thirdPartyMemberBank.map((bank, i) => (
                                                    <li
                                                        key={i}
                                                        className='small-bank bank-acc-list'
                                                        onClick={() => {
                                                            vm.onBankChanged(i);
                                                        }}
                                                    >
                                                        <div className='member-bankacc'>
                                                            <label>
                                                                <input
                                                                    type='radio2'
                                                                    name='bank-account'
                                                                    value='0'
                                                                    checked={selectedBankIndex === i}
                                                                    className={selectedBankIndex === i ? 'radio-checkmark' : ''}
                                                                    onChange={() => {
                                                                        vm.onBankChanged(i);
                                                                    }}
                                                                />
                                                                <span className='checkmark2'></span>
                                                                <div className='banklist-group'>
                                                                    <div className='group-box bankgroup-box-container'>
                                                                        <div className={`bank-img ${bank.bankCode === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                            <div
                                                                                className={[
                                                                                    'icon-bank',
                                                                                    bank.bankCode === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                                    selectedBankIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                                ].join(' ')}
                                                                            >
                                                                                <div>
                                                                                    <BankIcon bankCode={bank.bankCode} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='bank-active'></div>
                                                                        </div>

                                                                        <div className='bank-name'>{bank.displayName}</div>
                                                                    </div>

                                                                    {selectedBankIndex === i && (
                                                                        <>
                                                                            <div className='bank-details'>
                                                                                <input disabled='' readOnly defaultValue={thirdPartyMemberBank[i].accountHolder} />
                                                                            </div>
                                                                            <div className='bank-details'>
                                                                                <input disabled='' readOnly defaultValue={thirdPartyMemberBank[i].accountNumber} />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='group-item'>
                                        <span className='reminder-message'>
                                            {t('transaction:transaction.deposit.onlinetransfer.reminderMsg', 'Please add Member Bank Account to proceed. Supported bank:')}
                                            {bankList.map((bank) => (
                                                <span className='bank-name'>{bank.name}</span>
                                            ))}
                                        </span>
                                    </div>
                                )}
                            </>
                        ) : (
                            <MobileSelect
                                itemList={bankList}
                                itemKey={'name'}
                                onChangeIndexType={true}
                                onChange={(e) => vm.onBankChanged(bankList[selectedBankIndex], e)}
                                itemIndex={selectedBankIndex}
                                title={t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}
                                required={true}
                                disablePreselectBank={disablePreselectBank}
                            />
                        )}

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='amount-content special-group'>
                                <div className='input-box'>
                                    <input
                                        id='depositamount'
                                        type='numeric'
                                        autoComplete='off'
                                        placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                            min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                            max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                        })}
                                        min={'0'}
                                        value={fields['amount']}
                                        onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'amount')}
                                        onBlur={() => {
                                            transactionHelper.handleValidation(this);
                                        }}
                                    />
                                    <div className='error-validation'>
                                        <Trans i18nKey={errors['amount']} values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}></Trans>
                                    </div>
                                </div>
                                {compSettings && compSettings.showCurrencyConversion && (
                                    <div className='currency-rate'>
                                        <span className='currency-conversion'>{compSettings.showCurrencyConversion}</span>
                                    </div>
                                )}
                                <ul>
                                    {depositAmountOptions.map((item) => (
                                        <li
                                            key={item}
                                            onClick={() => {
                                                transactionHelper.onDepositAmountClicked(this, item);
                                            }}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                                <div className='input-box'>
                                    <input
                                        className='qr-reminder'
                                        type='text'
                                        readOnly={true}
                                        value={t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox m-standard-2-button-container'>
                                    <button
                                        className='btn btn-submit standard-button'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankQRDeposit)));
