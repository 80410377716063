import { lazy } from 'react';
import Home from 'root/webapp/portal/home/home.selector';

const RedirectError = lazy(() => import('root/webapp/portal/provider/redirect-error/redirect-error.selector'));
export const Register = lazy(() => import('root/webapp/portal/register/register.selector'));
const Download = lazy(() => import('root/webapp/portal/download/download.selector'));
const Casino = lazy(() => import('root/webapp/portal/provider/casino/casino.selector'));
const Slots = lazy(() => import('root/webapp/portal/provider/slots/slots.selector'));
const Sports = lazy(() => import('root/webapp/portal/provider/sports/sports.selector'));
const Esports = lazy(() => import('root/webapp/portal/provider/e-sports/e-sports.selector'));
const Poker = lazy(() => import('root/webapp/portal/provider/poker/poker.selector'));
const Fishing = lazy(() => import('root/webapp/portal/provider/fishing/fishing.selector'));
const Lottery = lazy(() => import('root/webapp/portal/provider/lottery/lottery.selector'));
const Games = lazy(() => import('root/webapp/portal/provider/games-3d/games-3d.selector'));
const FourD = lazy(() => import('root/webapp/portal/provider/4d/4d.selector'));
const FastGames = lazy(() => import('root/webapp/portal/provider/fast-games-1/fast-games.selector'));
const Cockfight = lazy(() => import('root/webapp/portal/provider/cockfight/cockfight.selector'));
const Favourite = lazy(() => import('root/webapp/portal/favourite/favourite.selector'));
const Promos = lazy(() => import('root/webapp/portal/promos/promotion.selector'));
const AboutUs = lazy(() => import('root/webapp/portal/about-us/about-us.selector'));
const Faq = lazy(() => import('root/webapp/portal/faq/faq.selector'));
const ResponsibleGaming = lazy(() => import('root/webapp/portal/responsible-gaming/responsible-gaming.selector'));
const Terms = lazy(() => import('root/webapp/portal/terms/terms.selector'));
const Vip = lazy(() => import('root/webapp/portal/vip/vip.selector'));
const Leaderboard = lazy(() => import('root/webapp/portal/leaderboard/leaderboard.selector'));
const Sponsorship = lazy(() => import('root/webapp/portal/sponsorship/sponsorship.selector'));
const Maintenance = lazy(() => import('root/webapp/portal/provider/maintenance/maintenance.selector'));

// Mobile
const SlotsSubGameMenu = lazy(() => import('root/webapp/portal/provider/slots/mobile/SubGameMenu'));
const GamesSubGameMenu = lazy(() => import('root/webapp/portal/provider/games-3d/mobile/SubGameMenu'));
const FishingSubGameMenu = lazy(() => import('root/webapp/portal/provider/fishing/mobile/SubGameMenu'));
const LotterySubGameMenu = lazy(() => import('root/webapp/portal/provider/lottery/mobile/SubGameMenu'));
const FastGamesSubGameMenu = lazy(() => import('root/webapp/portal/provider/fast-games-1/mobile/SubGameMenu'));
const FourDSubGameMenu = lazy(() => import('root/webapp/portal/provider/4d/mobile/SubGameMenu'));
const ChangeLanguage = lazy(() => import('root/webapp/portal/change-language/change-language.selector'));
const RegisterLanding = lazy(() => import('root/webapp/portal/register/mobile/RegisterLanding'));
const LoginLanding = lazy(() => import('root/webapp/portal/login/mobile/LoginLanding'));

//Private route
const SettingLanding = lazy(() => import('root/webapp/portal/settings/settings.selector'));
const QuickPayRedirect = lazy(() => import('root/webapp/portal/settings/quickpay-redirect/quickpay-redirect'));
const MobileCardRedirect = lazy(() => import('root/webapp/portal/settings/mobilecard-redirect/mobilecard-redirect'));

//Private route mobile
const MTransfer = lazy(() => import('root/webapp/portal/settings/transfer/component/mobile/m-transfer'));
const MProfile = lazy(() => import('root/webapp/portal/settings/profile/component/mobile/m-profile'));
const Password = lazy(() => import('root/webapp/portal/settings/password/password.selector'));
const MBankDetail = lazy(() => import('root/webapp/portal/settings/bank-detail/component/mobile/m-bank-detail'));
const MMessage = lazy(() => import('root/webapp/portal/settings/message/component/mobile/m-message'));
const MReferral = lazy(() => import('root/webapp/portal/settings/referral/component/mobile/m-referral'));
const MWithdraw = lazy(() => import('root/webapp/portal/settings/withdraw/component/mobile/m-withdraw'));
const MRebate = lazy(() => import('root/webapp/portal/settings/instant-rebate/component/mobile/m-instant-rebate'));
const PromotionSpecial = lazy(() => import('../components/organisms/special-for-you/special-for-you'));
const MHistoryLanding = lazy(() => import('root/webapp/portal/settings/history/component/mobile/m-history-landing'));

export const routesConfig = {
    web: [
        { key: 'redirect-error', path: '/redirect-error', component: RedirectError, exact: true },
        { key: 'home', path: '/home', component: Home, exact: true },
        { key: 'register', path: ['/register', '/register/external-quick-register'], component: Register, exact: true },
        { key: 'login', path: '/login', component: Home, exact: true },
        { key: 'resetpassword', path: '/resetpassword', component: Home, exact: true },
        { key: 'download', path: '/download', component: Download, exact: true },
        { key: 'casino', path: ['/casino', '/casino/:provider'], component: Casino, exact: true },
        { key: 'slots', path: ['/slots', '/slots/:provider', '/918kiss', '/mega888', '/psy8'], component: Slots, exact: true },
        { key: 'sports-provider', path: ['/sports', '/sports/:provider', '/cmd368', '/virtualsports-landing'], component: Sports },
        { key: 'e-sports', path: '/esports', component: Esports, exact: true },
        { key: 'poker', path: '/poker', component: Poker, exact: true },
        { key: 'fishing', path: ['/fishing', '/fishing/:provider'], component: Fishing, exact: true },
        { key: 'lottery', path: '/lottery', component: Lottery, exact: true },
        { key: 'games', path: '/games', component: Games, exact: true },
        { key: '4d', path: '/4d', component: FourD, exact: true },
        { key: 'fastgames', path: ['/fastgames', '/fastgames/:provider'], component: FastGames, exact: true },
        { key: 'cockfight', path: '/cockfight', component: Cockfight, exact: true },
        { key: 'favourite', path: '/favourite', component: Favourite, exact: true },
        { key: 'promotion', path: '/promotion', component: Promos, exact: true },
        { key: 'aboutus', path: '/aboutus', component: AboutUs, exact: true },
        { key: 'faq', path: '/faq', component: Faq, exact: true },
        { key: 'responsible-gaming', path: '/responsiblegaming', component: ResponsibleGaming, exact: true },
        { key: 'terms', path: '/terms', component: Terms, exact: true },
        { key: 'vip', path: '/vip', component: Vip, exact: true },
        { key: 'leaderboard', path: '/leaderboard', component: Leaderboard, exact: true },
        { key: 'sponsorship', path: '/sponsorship', component: Sponsorship, exact: true },
        { key: 'maintenance', path: '/maintenance', component: Maintenance, exact: true },
        //private
        { key: 'settingLanding', path: '/myaccount', component: SettingLanding, exact: false, isPrivate: true },
        { key: 'quickpay-redirect', path: '/quickpayredirect', component: QuickPayRedirect, exact: true, isPrivate: true },
        { key: 'mobilecard-redirect', path: '/mobilecardredirect', component: MobileCardRedirect, exact: true, isPrivate: true },
    ],
    mobile: [
        { key: 'redirect-error', path: '/redirect-error', component: RedirectError, exact: true },
        { key: 'home', path: '/home', component: Home, exact: true },
        { key: 'download', path: '/download', component: Download, exact: true },
        { key: 'casino', path: '/casino', component: Casino, exact: true },
        { key: 'slots', path: '/slots', component: Slots, exact: true },
        { key: 'sports', path: '/sports', component: Sports, exact: true },
        { key: 'e-sports', path: '/esports', component: Esports, exact: true },
        { key: 'cockfight', path: '/cockfight', component: Cockfight, exact: true },
        { key: 'poker', path: '/poker', component: Poker, exact: true },
        { key: 'fishing', path: '/fishing', component: Fishing, exact: true },
        { key: 'lottery', path: '/lottery', component: Lottery, exact: true },
        { key: 'games', path: '/games', component: Games, exact: true },
        { key: '4d', path: '/4d', component: FourD, exact: true },
        { key: 'fastgames', path: '/fastgames', component: FastGames, exact: true },
        { key: 'favourite', path: '/favourite', component: Favourite, exact: true },
        { key: 'slots-submenu', path: ['/slots', '/slots/:provider', '/918kiss', '/mega888', '/psy8'], component: SlotsSubGameMenu, exact: true },
        { key: 'games-submenu', path: '/games/:provider', component: GamesSubGameMenu, exact: true },
        { key: 'fishing-submenu', path: '/fishing/:provider', component: FishingSubGameMenu, exact: true },
        { key: 'lottery-submenu', path: '/lottery/:provider', component: LotterySubGameMenu, exact: true },
        { key: 'fastgames-submenu', path: '/fastgames/:provider', component: FastGamesSubGameMenu, exact: true },
        { key: '4d-submenu', path: '/4d/:provider', component: FourDSubGameMenu, exact: true },
        { key: 'promotion', path: '/promotion', component: Promos, exact: true },
        { key: 'vip', path: '/vip', component: Vip, exact: true },
        { key: 'leaderboard', path: '/leaderboard', component: Leaderboard, exact: true },
        { key: 'sponsorship', path: '/sponsorship', component: Sponsorship, exact: true },
        { key: 'change-language', path: '/language', component: ChangeLanguage, exact: true },
        { key: 'terms', path: '/terms', component: Terms, exact: true },
        { key: 'maintenance', path: '/maintenance', component: Maintenance, exact: true },
        { key: 'register', path: ['/register', '/register/external-quick-register'], component: RegisterLanding, exact: true },
        { key: 'login', path: '/login', component: LoginLanding, exact: true },
        //private
        { isPrivate: true, exact: true, key: 'settings-transfer', path: '/myaccount/transfer', component: MTransfer },
        { isPrivate: true, exact: true, key: 'profile', path: '/myaccount/profile', component: MProfile },
        { isPrivate: true, exact: true, key: 'change-password', path: '/myaccount/password', component: Password },
        { isPrivate: true, exact: true, key: 'bank-details', path: '/myaccount/bankingdetails', component: MBankDetail },
        { isPrivate: true, exact: true, key: 'messaging', path: '/myaccount/messaging', component: MMessage },
        { isPrivate: true, exact: true, key: 'referral', path: '/myaccount/referral', component: MReferral },
        { isPrivate: true, exact: true, key: 'withdraw', path: '/myaccount/withdraw', component: MWithdraw },
        { isPrivate: true, exact: true, key: 'rebate', path: '/myaccount/rebate', component: MRebate },
        { isPrivate: true, exact: true, key: 'promotionSpecial', path: '/myaccount/specialforyou', component: PromotionSpecial },
        // { isPrivate: true, exact: true, key: 'deposit', path: '/myaccount/deposit', component: depositPagesSettings.enableJsonApi ? DepositLanding : MDeposit },
        { isPrivate: true, exact: true, key: 'history', path: '/myaccount/history', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'history-transaction', path: '/myaccount/history/transaction', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'history-promotion', path: '/myaccount/history/promotion', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'history-rebate', path: '/myaccount/history/rebate', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'history-rewards', path: '/myaccount/history/rewards', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'history-freeSpin', path: '/myaccount/history/freeSpin', component: MHistoryLanding },
        { isPrivate: true, exact: true, key: 'quickpay-redirect', path: '/quickpayredirect', component: QuickPayRedirect },
        { isPrivate: true, exact: true, key: 'mobilecard-redirect', path: '/mobilecardredirect', component: MobileCardRedirect },
    ],
};
